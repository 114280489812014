<template>
  <b-modal
      v-model="onControl"
      title-tag="h3"
      :title="titleInsideModal"
      modal-class="modal-primary"
      @hidden="close"
      size="xl"
      :hide-footer="cancelDone || isDoneEmployee "
  >
    <cards-payments
        :byHour="byHour"
        :hours="hours"
        :exRate="exRate"
        :salary$="salary$"
        :salaryS="salaryS"
    />
    <fieldset class="border p-1 rounded border-primary-i">
      <legend  class="w-auto text-primary mb-0">&nbsp;Regular pay&nbsp;</legend>
      <validation-observer ref="form">
        <b-row>
        <b-col>
          <validation-provider
              v-slot="{ errors }"
              name="total"
              :rules="typeAgreement === 1? 'required|validate-hours-92' : 'required'"
          >
            <b-input-group>
              <template #prepend>
                <b-input-group-text class="text-white color-gray" >Total &nbsp; <feather-icon
                    icon="ClockIcon"
                />
                </b-input-group-text>
              </template>
              <b-form-input
                  placeholder="0"
                  type="number"
                  min="0"
                  v-model="totalHours"
                  :disabled="cancelDone || isDoneEmployee || typeAgreement == 2 "
                  @input="calculateExtraHours()"
              ></b-form-input>
            </b-input-group>
            <span v-if="errors[0]" class="text-danger">{{ errors[0] }}</span>
          </validation-provider>
        </b-col>
        <b-col>
          <validation-provider
              v-slot="{ errors }"
              name="regular"
              rules="required|validate-amount"
          >
          <b-input-group>
            <template #prepend>
              <b-input-group-text class="text-white color-gray" >Regular &nbsp; <feather-icon
                  class=""
                  icon="ClockIcon"
              /> </b-input-group-text>
            </template>
            <b-form-input
                v-model="workHours"
                placeholder="0"
                type="number"
                min="0"
                disabled
            />
          </b-input-group>
            <span v-if="errors[0]" class="text-danger">{{ errors[0] }}</span>
          </validation-provider>
        </b-col>
        <b-col>
          <b-input-group>
          <template #prepend>
            <b-input-group-text  class="text-white color-gray">Salary $ </b-input-group-text>
          </template>
          <b-form-input :value="regularSalary" type="number" disabled ></b-form-input>
        </b-input-group>
        </b-col>
      </b-row>
      </validation-observer>
    </fieldset>
    <fieldset class="border p-1 mt-1 rounded border-primary-i">
      <legend  class="w-auto text-primary mb-0">&nbsp;Additional pay&nbsp;</legend>
      <div v-if="!isDoneEmployee && !cancelDone" class="d-flex justify-content-between mb-1">
        <b-button variant="primary" @click="addPaymentAd" >Add</b-button>
        <b-button size="sm" variant="outline-primary" @click="openCreateMotiveModal" v-if="aditionalPays.length>0">Create motive  <feather-icon
            icon="PlusIcon"
            size="15"
        /></b-button>
      </div>
      <b-row v-for="(additional, index) in aditionalPays" :key="index" class="mt-1">
        <b-col cols="5">
          <validation-provider
              v-slot="{ errors }"
              name="select_motive"
              rules="required"
          >
          <b-input-group>
            <template #prepend>
              <b-input-group-text  class="text-white color-gray"><tabler-icon
                  icon="NotesIcon"
              /> </b-input-group-text>
            </template>
            <v-select style="width: 25rem"
                      :selectable = "option => option.disabled!==true"
                      v-model="additional.motive"
                      required
                      placeholder="Choose a Motive"
                      @input="changeMotiveAditional(index)"
                      :options="motivesAditional"
                      label="value"
                      :disabled="cancelDone || isDoneEmployee || additional.motive =='Extra hours'"
                      :reduce ="value => value.value"
                      clearable
            ></v-select>
          </b-input-group>
            <span v-if="errors[0]" class="text-danger">{{ errors[0] }}</span>
          </validation-provider>
        </b-col>
        <b-col cols="2">
          <b-input-group>
            <template #prepend>
              <b-input-group-text class="text-white color-gray" >
                <feather-icon
                  class=""
                  icon="ClockIcon"
              /> </b-input-group-text>
            </template>
            <b-form-input
                v-model="additional.hours"
                placeholder="0"
                type="number"
                min="0"
                @input="extraAmount(index)"
                :disabled="additional.amountS > 0 || cancelDone || isDoneEmployee || additional.motive =='Extra hours'"
            ></b-form-input>
          </b-input-group>
        </b-col>
        <b-col cols="2">
            <b-input-group>
              <template #prepend>
                <b-input-group-text class="text-white color-gray" >S/.</b-input-group-text>
              </template>
              <money :class="isDarkSkin? 'text-white' : ''"
                     class="input-money-style form-control"
                     @keyup.native="solesDolar(index)"
                     v-model.lazy="additional.amountS"
                     :disabled="additional.hours > 0 || cancelDone || isDoneEmployee || additional.motive =='Extra hours'"
                     v-bind="{
                      decimal: '.',
                      thousands: ',',
                      precision: 2,
                      masked: false,
                    }"
              />
            </b-input-group>
        </b-col>
        <b-col>
          <validation-provider
              v-slot="{ errors }"
              name="dollars"
              :rules="`${ additional.motive !== ''? 'required|validate-amount' : ''}`"
          >
          <b-input-group :class="{'border border-danger rounded':errors[0]}">
            <template #prepend>
              <b-input-group-text class="text-white color-gray" >$</b-input-group-text>
            </template>
            <money :class="isDarkSkin? 'text-white' : ''"
                   class="input-money-style form-control"
                   v-model="additional.amountD"
                   placeholder="0"
                   v-bind="{
                    decimal: '.',
                    thousands: ',',
                    precision: 2,
                    masked: false,
                  }"
                   :disabled="additional.hours > 0 || additional.amountS >0 || cancelDone || isDoneEmployee || additional.motive =='Extra hours'"
            />
          </b-input-group>
          </validation-provider>
        </b-col>
        <b-col cols="1" v-if="!cancelDone && !isDoneEmployee">
          <tabler-icon
              icon="CircleXIcon"
              size="35"
              class="text-danger cursor-pointer ml-2"
              @click="deleteAditional(index)"
              v-if="index != 0"
          />
        </b-col>
      </b-row>
      <b-row class="mt-2"  v-if="aditionalPays.length>0">
        <b-col cols="9"/>
        <b-col>
          <b-input-group>
            <template #prepend>
              <b-input-group-text class="text-white color-gray" >$</b-input-group-text>
            </template>
            <money :class="isDarkSkin? 'text-white' : ''"
                   class="input-money-style form-control"
                   :value="totalExtra"
                   v-bind="{
                    decimal: '.',
                    thousands: ',',
                    precision: 2,
                    masked: false,
                  }"
                   disabled
            />
          </b-input-group>
        </b-col>
        <b-col cols="1" v-if="!cancelDone && !isDoneEmployee"/>
      </b-row>
    </fieldset>
    <div class="mt-2 d-flex justify-content-center">
      <b-input-group class="d-flex justify-content-center">
        <template #prepend>
          <b-input-group-text class="text-white color-gray">Total Salary $ </b-input-group-text>
        </template>
        <money class="input-money-style"
               :class="isDarkSkin? 'text-white' : ''"
               :value="totalRegularSalary"
               v-bind="{
                    decimal: '.',
                    thousands: ',',
                    precision: 2,
                    masked: false,
                  }"
               disabled
        />
      </b-input-group>
    </div>
    <template #modal-footer="{ cancel }">
      <button-cancel @click="cancel()"></button-cancel>
      <button-save @click="savePayRoll"></button-save>
    </template>
    <create-motive-modal
        v-if="openCreateMotiveModalController"
        @close="closeCreateMotiveModal"
        :mood = "mood"
        :title="title"
        @getMotives="getMotives"
    ></create-motive-modal>
  </b-modal>
</template>

<script>
import vSelect from 'vue-select'
import buttonSave from '@/views/commons/utilities/ButtonSave.vue'
import buttonCancel from '@/views/commons/utilities/ButtonCancel.vue'
import createMotiveModal from '@/views/management/views/payroll/payroll-module/modals/CreateMotiveModal'
import cardsPayments from '@/views/management/views/payroll/payroll-module/modals/CardsPayments'
import PayrollService from '@/views/management/views/payroll/payroll.service'

export default {
  props:{
    titleInsideModal: '',
    cancelDone: '',
    employee:[],
    isDoneEmployee: '',
    byHour: '',
    exRate: '',
    salary$: '',
    salaryS: '',
    hours: '',
    salaryHour: '',
  },
  components: {
    vSelect,
    buttonSave,
    buttonCancel,
    createMotiveModal,
    cardsPayments,
  },
  data(){
    return {
      onControl: false,
      openCreateMotiveModalController: false,
      soles: '',
      dollars: '',
      adPaySoles: '',
      adPayDollars: '',
      mood: 1,
      title: '',
      typeAgreement: '',
      myLocalEmployee: [],
      totalHours: '',
      workHours: '',
      emptyModal: true,
      regularSalary: '',
      aditionalPays: [],
      motivesAditional: [],
    }
  },
  async created(){
    this.onControl = true
    this.myLocalEmployee = {...this.employee}
    this.typeAgreement = this.myLocalEmployee.type_agreement
    this.workHours = this.myLocalEmployee.hours / 2;
    if (this.typeAgreement === 2) {
      this.totalHours = this.workHours;
    }
    if (Number(this.myLocalEmployee.total_aditional)>0){
      this.emptyModal = false
    }
    this.aditionalPays = [
      {
        motive: this.typeAgreement === 1 ? "Extra hours" : "",
        hours: "",
        amountS: "",
        amountD: "",
      },
    ];
    this.addPreloader()
    await this.getMotives()
    await this.getRegularSalary()
    this.removePreloader()
  },
  methods: {
    close() {
      this.$emit("close");
    },
    closeCreateMotiveModal(){
      this.openCreateMotiveModalController = false;
    },
    openCreateMotiveModal(){
      this.openCreateMotiveModalController = true;
      this.title = 'Create Motive Additional'
    },
    calculateExtraHours(){
      if (this.typeAgreement === 1) {
        this.aditionalPays.forEach((element) => {
          if (element.motive == "Extra hours") {
            element.hours = this.totalHours - this.workHours;
          }
        });
      }
      this.extraAmount(0)
    },
    addPaymentAd(){
      let payAdditional = {
        motive: "",
        hours: "",
        amountS: "",
        amountD: "",
      };
      this.aditionalPays.push(payAdditional);
    },
    extraAmount(index){
      this.aditionalPays[index].amountS = 0;
      this.aditionalPays[index].amountD = this.aditionalPays[index].hours * this.salaryHour;
      this.aditionalPays[index].amountD = this.aditionalPays[index].amountD.toFixed(2);
    },
    async getRegularSalary(){
      let regular = this.workHours * this.salaryHour;
      this.regularSalary = regular.toFixed(2);
      if(!this.emptyModal) {
        try {
          const data = await PayrollService.getPayrollById({
            pay_user_id: this.myLocalEmployee.id,
            type_pay: 1,
          })
          if (data.status === 200) {
            this.workHours = data.data[0].regular_hours
            this.regularSalary = data.data[0].regular_amount
            this.aditionalPays = data.data[0].aditional_pay ? data.data[0].aditional_pay : []
            this.totalHours = this.typeAgreement === 1 ? this.aditionalPays[0].hours + this.workHours : this.workHours
            this.disabledMotives()
          }
        } catch (e) {
          this.showErrorSwal(e)
        }
      }
    },
    solesDolar(index){
      this.aditionalPays[index].amountD = this.aditionalPays[index].amountS / this.exRate;
      this.aditionalPays[index].amountD = this.aditionalPays[index].amountD.toFixed(2);
    },
    async getMotives(){
      try {
        const data = await PayrollService.getMotivesPayment({in_type: 1})
        if(data.status === 200){
          this.motivesAditional = data.data
          if (this.typeAgreement === 2) {
            this.motivesAditional = this.motivesAditional.filter(
                (element) => element.value !== "Extra hours"
            );
          }
          this.disabledMotives()
        }
      } catch (e) {
        this.showErrorSwal(e)
      }
    },

    disabledMotives(){
      for (let i = 0; i < this.motivesAditional.length; i++) {
        if (this.motivesAditional[i].disabled == true) continue
        for (let j = 0; j < this.aditionalPays.length; j++) {
          if (this.motivesAditional[i].value == this.aditionalPays[j].motive) {
            this.motivesAditional[i].disabled = true;
          }
        }
      }
    },
    changeMotiveAditional(index) {
      this.aditionalPays[index].hours = "";
      this.aditionalPays[index].amountS = 0;
      this.aditionalPays[index].amountD = 0;
      this.disabledMotives();
    },
    deleteAditional(index) {
      for (let i=0; i<this.motivesAditional.length; i++){
        if(this.motivesAditional[i].value == this.aditionalPays[index].motive){
          this.motivesAditional[i].disabled = false
          break
        }
      }
      this.aditionalPays.splice(index, 1);
    },
    async savePayRoll(){
      try{
        const result = await this.$refs.form.validate()
        if (result) {
          const response = await this.showConfirmSwal();
          if (response.isConfirmed) {
            const params = {
              type: 1,
              payEmployeeId: this.myLocalEmployee.id,
              exchange: this.exRate,
              byHour: this.byHour,
              workHours: this.workHours,
              regularSalary: this.regularSalary,
              aditionalPays: this.aditionalPays,
              totalRegularSalary: this.totalRegularSalary,
            }
            this.addPreloader()
            const data = await PayrollService.savePayroll(params)
            if (data.status === 200) {
              this.$emit('reload')
              this.removePreloader()
              this.showSuccessSwal("Payment saved successfully");
              this.close()
            }
          }
        }
      }catch (e) {
        this.removePreloader()
        this.showErrorSwal(e)
      }
    },
  },
  computed: {
    totalExtra() {
      let total = this.aditionalPays.reduce(
          (sum, additional) => sum + additional.amountD, 0
      );
      total = Number(total);
      return total.toFixed(2);
    },
    totalRegularSalary() {
      let totalSalary = Number(this.regularSalary) + Number(this.totalExtra);
      return totalSalary.toFixed(2);
    },
  }
}
</script>

<style scoped>
.pad{
 padding: 0.8rem !important;
}
.color-gray{
  background-color: #6c757d !important;
}
.input-money-style{
  width: 7rem; border: 1px solid #d8d6de; border-radius: 0.357rem; padding: 0.438rem 1rem; height: 2.714rem;
}
.text-white{
  color: white !important;
}
</style>
