<template>
  <b-modal
    :title="`Projects in ${titleStatus}`"
    v-model="isShow"
    @hidden="$emit('hidden')"
    hide-footer
    size="xmd"
  >
    <filter-slot
      :filter="filters"
      :filter-principal="filterPrincipal"
      :total-rows="totalRows"
      :paginate="paginate"
      :start-page="startPage"
      :to-page="toPage"
      @reload="$refs.refPGRequest.refresh()"
    >
      <template #table>
        <b-table
          slot="table"
          ref="refPGRequest"
          striped
          :items="myProvider"
          :fields="fields"
          :current-page="paginate.currentPage"
          :per-page="paginate.perPage"
          :busy.sync="isBusy"
          show-empty
        >
          <template #table-busy>
            <div class="text-center text-primary my-2">
              <b-spinner class="align-middle mr-1" />
              <strong>Loading ...</strong>
            </div>
          </template>

          <template #cell(team_id)="data">
            {{ data.item.team_name }}
          </template>

          <template #cell(team_design_id)="data">
            {{ data.item.design_name }}
          </template>

          <template #cell(difficulty)="data">
            <b-badge
              :variant="getVariantDifficulty(data.item.difficulty_id)[0]"
            >
              {{ data.item.difficulty }}</b-badge
            >
          </template>

          <template #cell(created_at)="data">
            {{ data.item.created_at | myGlobal }}
          </template>

          <template #cell(options)="data">
            <div class="d-flex justify-content-center align-items-center">
              <feather-icon
                @click="openModalUploadFiles(data.item)"
                v-b-tooltip.hover
                title="View Files"
                icon="FileIcon"
                size="18"
                class="cursor-pointer text-primary mr-1"
              ></feather-icon>

              <feather-icon
                v-b-tooltip.hover
                title="Show detail"
                icon="EyeIcon"
                size="18"
                class="cursor-pointer"
                @click="openModalRequestDetail(data.item)"
              ></feather-icon>

              <b-button
                variant="outline-light"
                size="sm"
                v-b-tooltip.hover
                title="Process Diagram Miro"
                class="ml-1"
                style="background-color: transparent; border: none; padding: 0"
                @click="openModalProcessDiagram(data.item)"
              >
                <miro-icon-svg class="cursor-pointer" :size="18" />
              </b-button>
              <b-button
                variant="outline-light"
                class="ml-1"
                size="sm"
                v-b-tooltip.hover
                title="Prototype Figma"
                style="background-color: transparent; border: none; padding: 0"
                @click="openModalFigma(data.item)"
              >
                <figma-svg class="cursor-pointer" :size="18" />
              </b-button>

              <b-button
                v-if="data.item.binnacle_id"
                @click="openModalBinnacleRequest(data.item.binnacle_id)"
                variant="outline-light"
                size="sm"
                class="ml-1"
                v-b-tooltip.hover
                title="Open Chat"
                style="background-color: transparent; border: none; padding: 0"
              >
                <feather-icon
                  icon="MessageCircleIcon"
                  class="text-primary"
                  size="18"
                />
              </b-button>

              <b-button
                variant="outline-light"
                class="ml-1"
                size="sm"
                v-b-tooltip.hover
                title="Tracking"
                style="background-color: transparent; border: none; padding: 0"
                @click="openModalTracking(data.item)"
              >
                <feather-icon icon="AlignJustifyIcon" size="18" />
              </b-button>
            </div>
          </template>
        </b-table>
      </template>
    </filter-slot>

    <modal-upload-files
      v-if="showModalUploadFiles"
      :code="currentRequestName"
      :pg-request-id="currentPgRequestId"
      :read-only="true"
      @close="showModalUploadFiles = false"
    />

    <modal-request-details
      v-if="showModalRequestDetails"
      :request-id="currentPgRequestId"
      :request-code="currentRequestName"
      @hidden="showModalRequestDetails = false"
    />

    <modal-process-diagram
      v-if="showModalProcessDiagram"
      :request-code="currentRequestName"
      :request-id="currentPgRequestId"
      :miro-code="miroCode"
      @hidden="showModalProcessDiagram = false"
      @refresh="myProvider()"
    />

    <modal-figma-prototype
      v-if="showModalFigma"
      :link="figmaLink"
      :request-code="currentRequestName"
      @close="showModalFigma = false"
    />

    <modal-binnacle-request
      v-if="showModalBinnacleRequest"
      @close="showModalBinnacleRequest = false"
      @hidden="showModalBinnacleRequest = false"
    />

    <modal-tracking-request
      v-if="showModalTrackingRequest"
      :request-code="currentRequestName"
      :request-id="currentPgRequestId"
      @hidden="showModalTrackingRequest = false"
    />
  </b-modal>
</template>

<script>
import { mapGetters, mapMutations } from "vuex";

import Fields from "@/views/commons/components/paragon-soft/data/fields.data.js";
import Filters from "@/views/commons/components/paragon-soft/data/filters.data.js";
import PGService from "@/views/commons/components/paragon-soft/services/PGRequest.services.js";
import ModalUploadFiles from "@/views/commons/components/paragon-soft/components/ModalUploadFiles.vue";
import ModalRequestDetails from "@/views/commons/components/paragon-soft/modals/ModalRequestDetails.vue";
import FigmaSvg from "@/views/commons/components/paragon-soft/components/FigmaSvg.vue";
import MiroIconSvg from "@/views/commons/components/paragon-soft/components/MiroSvg.vue";
import ModalProcessDiagram from "@/views/commons/components/paragon-soft/modals/ModalProcessDiagram.vue";
import ModalFigmaPrototype from "@/views/commons/components/paragon-soft/modals/ModalFigmaPrototype.vue";
import ModalBinnacleRequest from "@/views/commons/components/paragon-soft/modals/ModalBinnacleRequest.vue";
import ModalTrackingRequest from "@/views/commons/components/paragon-soft/modals/ModalTrackingRequest.vue";

export default {
  props: {
    status: {
      type: Number,
      default: 7,
    },
  },

  components: {
    ModalUploadFiles,
    ModalRequestDetails,
    FigmaSvg,
    MiroIconSvg,
    ModalProcessDiagram,
    ModalFigmaPrototype,
    ModalBinnacleRequest,
    ModalTrackingRequest,
  },

  computed: {
    titleStatus() {
      switch (this.status) {
        case 1:
          return "Pending";
        case 2:
          return "In progress";
        case 3:
          return "Analysis";
        case 4:
          return "Design";
        case 5:
          return "Development";
        case 6:
          return "Testing";
        case 7:
          return "Production";
      }
    },

    ...mapGetters({
      currentUser: "auth/currentUser",
    }),
  },
  data() {
    return {
      isShow: true,
      isBusy: false,

      fields: Fields,
      filters: Filters,

      filterPrincipal: {
        type: "input",
        inputType: "text",
        placeholder: "Code, Title...",
        model: "",
      },

      paginate: {
        currentPage: 1,
        perPage: 10,
      },

      startPage: 0,
      toPage: 0,
      totalRows: 0,

      showModalUploadFiles: false,
      showModalRequestDetails: false,
      showModalFigma: false,
      showModalProcessDiagram: false,
      showModalBinnacleRequest: false,
      showModalTrackingRequest: false,

      currentRequestName: "",
      currentPgRequestId: "",
      figmaLink: "",
      miroCode: "",
    };
  },

  methods: {
    ...mapMutations({
      M_SET_CURRENT_CHAT_ID: "SoftProjectsBinnacleStore/M_SET_CURRENT_CHAT_ID",
    }),

    async myProvider(ctx) {
      const params = {
        search_txt: this.filterPrincipal.model,
        status_id: this.status,
        from_date: this.filters[0].model,
        to_date: this.filters[1].model,
        page: this.paginate.currentPage,
        per_page: this.paginate.perPage,
        order: ctx.sortDesc ? "desc" : "asc",
        order_by: ctx.sortBy,
      };

      const { data } = await PGService.getAllRequestByStatus(params);
      this.totalRows = data.total;
      this.startPage = data.from ? data.from : 0;
      this.toPage = data.to ? data.to : 0;

      return data.data || [];
    },

    openModalUploadFiles(item) {
      this.currentRequestName = item.code;
      this.currentPgRequestId = item.id;
      this.showModalUploadFiles = true;
    },

    openModalRequestDetail(item) {
      this.currentRequestName = item.code;
      this.currentPgRequestId = item.id;
      this.showModalRequestDetails = true;
    },

    openModalFigma(item) {
      this.figmaLink = item.figma_link;
      this.currentRequestName = item.code;
      this.showModalFigma = true;
    },

    openModalProcessDiagram(item) {
      this.currentRequestName = item.code;
      this.currentPgRequestId = item.id;
      this.miroCode = item.miro_code;
      this.showModalProcessDiagram = true;
    },

    openModalBinnacleRequest(binnacleId) {
      this.M_SET_CURRENT_CHAT_ID(binnacleId);
      this.showModalBinnacleRequest = true;
    },

    openModalTracking(item) {
      this.currentRequestName = item.code;
      this.currentPgRequestId = item.id;
      this.showModalTrackingRequest = true;
    },

    getVariantDifficulty(difficulty_id) {
      let variant = ["", ""];
      switch (difficulty_id) {
        case 1:
          variant = ["light-primary", ""];
          break;
        case 2:
          variant = ["light-info", ""];
          break;
        case 3:
          variant = ["light-danger", ""];
          break;
      }
      return variant;
    },
  },
};
</script>

<style>
</style>