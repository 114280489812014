<template>
  <div class="chat-container" v-if="G_CHATS.length > 0">
    <perfect-scrollbar class="custom-scrollbar">
      <template v-for="(chat, index) in G_CHATS">
        <!-- Show if just is a message without files -->
        <div
          v-if="
            chat.binnacles[0].last_message != null &&
            chat.binnacles[0].files == 0
          "
          @click="changeCurrentChat(chat.binnacles[0])"
          class="d-flex align-items-center chat-box"
          :class="chatIsInBinnacles(chat.binnacles) ? 'current-chat' : ''"
          :key="index"
        >
          <b-avatar
            button
            @click="changeCurrentChat(chat.binnacles[0])"
            size="4rem"
            :src="`/assets/${chat.binnacles[0].first_module}`"
            class="mr-1"
          ></b-avatar>
          <div class="d-flex align-items-top justify-content-between w-100">
            <div class="d-flex flex-column">
              <span class="chat-name" v-b-tooltip.hover :title="chat.title">{{
                chat.title | limitChars(20)
              }}</span>
              <span class="chat-last-message">{{
                chat.binnacles[0].last_message | limitChars(20)
              }}</span>
            </div>
            <div class="d-flex flex-column align-items-center">
              <span class="chat-date d-flex">{{
                chat.binnacles[0].last_message_send_at | myHourTime
              }}</span>
              <b-badge
                variant="danger"
                pill
                style="width: 1.5rem; height: 1.5rem; font-size: 1rem"
                v-if="chat.binnacles[0].pending_messages > 0"
                >{{ chat.binnacles[0].pending_messages }}</b-badge
              >
            </div>
          </div>
        </div>
        <!-- Show if the message contains files -->
        <div
          v-else
          @click="changeCurrentChat(chat.binnacles[0])"
          class="d-flex align-items-center chat-box"
          :class="chatIsInBinnacles(chat.binnacles) ? 'current-chat' : ''"
          :key="index"
        >
          <b-avatar
            button
            @click="changeCurrentChat(chat.binnacles[0])"
            size="4rem"
            :src="`/assets/${chat.binnacles[0].first_module}`"
            class="mr-1"
          ></b-avatar>

          <div class="d-flex align-items-top justify-content-between w-100">
            <div class="d-flex flex-column">
              <span class="chat-name" v-b-tooltip.hover :title="chat.title">{{
                chat.title | limitChars(20)
              }}</span>
              <span
                v-if="chat.binnacles[0].files > 0"
                class="chat-last-message d-flex align-items-center justify-content-start"
              >
                <feather-icon icon="FileIcon" size="16" class="mr-05" />
                {{ chat.binnacles[0].files }} Files.
              </span>
              <span v-else class="chat-last-message">
                No messages yet, start a conversation.
              </span>
            </div>
            <span
              class="chat-date d-flex"
              v-if="chat.binnacles[0].last_message_send_at"
              >{{ chat.binnacles[0].last_message_send_at | myHourTime }}</span
            >
          </div>
        </div>
      </template>
    </perfect-scrollbar>
  </div>
</template> <script>
import PerfectScrollbar from "vue-perfect-scrollbar";
import { mapGetters, mapActions, mapMutations } from "vuex";
import BinnacleService from "@/views/commons/components/paragon-soft/services/PGBinnacle.service.js";

export default {
  name: "ChatList",
  components: { PerfectScrollbar },
  methods: {
    ...mapActions({
      A_GET_CHATS: "SoftProjectsBinnacleStore/A_GET_CHATS",
      A_GET_CHAT_MEMBERS: "SoftProjectsBinnacleStore/A_GET_CHAT_MEMBERS",
      A_GET_MESSAGES: "SoftProjectsBinnacleStore/A_GET_MESSAGES",
      A_GET_CURRENT_CHAT_HEADER:
        "SoftProjectsBinnacleStore/A_GET_CURRENT_CHAT_HEADER",
    }),
    ...mapMutations({
      M_SET_CURRENT_CHAT_ID: "SoftProjectsBinnacleStore/M_SET_CURRENT_CHAT_ID",
    }),
    chatIsInBinnacles(binnacles) {
      return binnacles.some(
        (binnacle) => binnacle.binnacle_id == this.G_CURRENT_CHAT_ID
      );
    },
    async changeCurrentChat(binnacle) {
      if (this.G_CURRENT_CHAT_ID == binnacle.binnacle_id) return;

      this.M_SET_CURRENT_CHAT_ID(binnacle.binnacle_id);
      this.$emit("changeCurrentChat", binnacle.binnacle_id);
      await this.A_GET_CHAT_MEMBERS({
        binnacle_id: this.G_CURRENT_CHAT_ID,
      });

      this.storeBinnacleMessageSeen(binnacle.binnacle_id);
    },
    async storeBinnacleMessageSeen(binnacleId) {
      try {
        const params = {
          binnacle_id: binnacleId,
          user_id: this.currentUser.user_id,
        };
        await BinnacleService.storeBinnacleMessageSeen(params);
      } catch (error) {
        this.showErrorSwal(error);
      }
    },
    async bindUpdateLastMessage() {
      try {
        window.socket.bind(
          "paragon-binnacle-update-last-message",
          async ({ message, allowUsers }) => {
            if (allowUsers.includes(this.currentUser.user_id)) {
              const index = this.G_CHATS.findIndex(
                (chat) => chat.binnacles[0].binnacle_id == message.binnacle_id
              );
              if (this.G_CHATS[index] != undefined && index != -1) {
                this.G_CHATS[index].binnacles[0].last_message =
                  message.last_message;
                this.G_CHATS[index].binnacles[0].last_message_send_at =
                  message.last_message_send_at;
                this.G_CHATS[index].binnacles[0].files = message.files;
                this.sortChats();
              }
            }
          }
        );
      } catch (error) {
        this.showErrorSwal(error);
      }
    },
    sortChats() {
      // sort by last_message_send_at desc by the most recent item inside binnacles properties that is inside of G_CHATS
      this.G_CHATS.sort((a, b) => {
        if (
          a.binnacles[0].last_message_send_at >
          b.binnacles[0].last_message_send_at
        ) {
          return -1;
        }
        if (
          a.binnacles[0].last_message_send_at <
          b.binnacles[0].last_message_send_at
        ) {
          return 1;
        }
        return 0;
      });
    },
    async getUserChats() {
      try {
        this.addPreloader();
        await this.A_GET_CHATS({
          user_id: this.currentUser.user_id,
        });
        if (this.G_CURRENT_CHAT_ID) {
          await this.A_GET_CHAT_MEMBERS({
            binnacle_id: this.G_CURRENT_CHAT_ID,
          });
        }
      } catch (error) {
        this.showErrorSwal(error);
      } finally {
        this.removePreloader();
      }
    },
  },
  computed: {
    ...mapGetters({
      currentUser: "auth/currentUser",
      G_CHATS: "SoftProjectsBinnacleStore/G_CHATS",
      G_CURRENT_CHAT_ID: "SoftProjectsBinnacleStore/G_CURRENT_CHAT_ID",
    }),
  },
  async created() {
    await this.bindUpdateLastMessage();
    await this.getUserChats();
  },
};
</script>

<style lang="scss" scoped>
.chat-container {
  margin-top: 0.2rem;
  height: 75vh;

  .custom-scrollbar {
    width: 100%;
    height: 100%;
  }
  .current-chat {
    background-color: #eaeaea;
  }
  .chat-box {
    cursor: pointer;
    padding: 1rem 1.3rem;
    &:hover:not(.current-chat) {
      background-color: rgba(233, 236, 239, 0.3);
    }
  }
  .chat-name {
    font-weight: 600;
    font-size: 1.2rem;
    color: #626262;
  }
  .chat-date {
    font-size: 0.9rem;
    color: #9a9a9a;
  }
  .chat-last-message {
    font-size: 1.1rem;
    color: #9a9a9a;
  }
  .mr-05 {
    margin-right: 0.5rem !important;
  }
}
.dark-layout {
  .chat-container {
    .chat-box {
      &:hover:not(.current-chat) {
        background-color: rgba(94, 234, 212, 0.04);
      }
    }
    .current-chat {
      background-color: rgba(94, 234, 212, 0.24);
    }
    .chat-name {
      color: #fff;
    }
    .chat-date {
      color: rgba(255, 255, 255, 0.87);
    }
    .chat-last-message {
      color: rgba(255, 255, 255, 0.67);
    }
  }
}
</style>