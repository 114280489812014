<template>
  <div>
    <b-modal
      ref="modal-update-request"
      size="lg"
      @hidden="$emit('hidden')"
      no-close-on-backdrop
    >
      <template #modal-title>
        <span class="text-uppercase font-weight-bolder">
          Update Request: {{ requestCode }}</span
        >
      </template>
      <template #default>
        <validation-observer ref="form">
          <b-row>
            <b-col cols="12">
              <validation-provider v-slot="{ errors }" rules="required">
                <b-form-group label="Title">
                  <b-form-input
                    v-model="request.title"
                    :state="errors[0] ? false : null"
                  />
                </b-form-group>
              </validation-provider>
            </b-col>
            <b-col cols="12">
              <validation-provider v-slot="{ errors }" rules="required">
                <b-form-group label="Description">
                  <quill-editor
                    id="quil-content-request"
                    :options="editorOption"
                    v-model="request.description"
                    class="quill-content-request"
                    :class="errors[0] ? 'border-danger rounded' : ''"
                  />
                </b-form-group>
              </validation-provider>
            </b-col>
            <b-col cols="12">
              <validation-provider v-slot="{ errors }" rules="required">
                <b-form-group label="Modules">
                  <v-select
                    v-model="request.modules"
                    :style="
                      errors[0]
                        ? 'border: 1px solid red !important; border-radius: 5px !important;'
                        : ''
                    "
                    label="name"
                    multiple
                    :options="allModules"
                    :reduce="(option) => option.id"
                    placeholder="Select modules"
                  />
                </b-form-group>
              </validation-provider>
            </b-col>
          </b-row>
        </validation-observer>
      </template>
      <template #modal-footer>
        <b-row class="d-flex justify-content-end">
          <b-button variant="primary" @click="updateRequest()"> SAVE </b-button>
        </b-row>
      </template>
    </b-modal>
  </div>
</template>
<script>
import helpdeskService from "@/views/commons/components/helpdesk/services/helpdesk.service";
import RequestService from "@/views/commons/components/paragon-soft/services/PGRequest.services.js";
import { quillEditor } from "vue-quill-editor";
export default {
  components: {
    quillEditor,
  },
  props: {
    requestId: {
      type: Number,
      required: true,
    },
    requestCode: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      request: {
        title: "",
        description: "",
        modules: [],
        status_id: null,
      },
      allModules: [],
      editorOption: {
        modules: {
          toolbar: true,
        },
        placeholder: "Description...",
      },
    };
  },
  mounted() {
    this.toggleModal("modal-update-request");
    this.getAllModules();
    this.getRequestById();
  },
  computed: {},
  methods: {
    async getAllModules() {
      try {
        this.addPreloader();
        const { data } = await helpdeskService.getModules();
        this.allModules = data;
      } catch (error) {
        this.showErrorSwal(error);
      } finally {
        this.removePreloader();
      }
    },

    async getRequestById() {
      try {
        this.addPreloader();
        const { data } = await RequestService.getRequestById(this.requestId);
        this.request = {
          title: data.title,
          description: data.description,
          modules: JSON.parse(data.modules),
          status_id: data.status_id,
        };
      } catch (error) {
        this.showErrorSwal(error);
      } finally {
        this.removePreloader();
      }
    },

    async updateRequest() {
      try {
        const isValid = await this.$refs["form"].validate();
        if (!isValid) return;
        this.addPreloader();
        const params = {
          request_id: this.requestId,
          title: this.request.title,
          description: this.request.description,
          status_id: this.request.status_id,
          modules: this.request.modules,
        };

        const { data } = await RequestService.updateRequest(params);
        this.showSuccessSwal(data.message);
        this.$emit("refreshPGRequest");
      } catch (error) {
        this.showErrorSwal(error);
      } finally {
        this.removePreloader();
      }
    },
  },
};
</script>