<template>
  <component
    :is="tagType"
    v-model="modalUp"
    size="sm"
    hide-footer
    :title="`Developers Team ( ${teamName} )`"
    @hidden="hideModal()"
  >
    <div class="card-project-item mb-1">
      <div
        v-if="tagType === 'div'"
        class="card-project-header sticky border-bottom"
      >
        <strong>
          Developers Team
          <span
            v-if="teamName"
            class="text-capitalize"
          >
            ({{ teamName }})
          </span>
        </strong>
      </div>
      <div class="card-project-content p-1">
        <template v-for="(item, index) in team">
          <div
            :key="index"
            class="mb-1 text-truncate"
          >
            <b-avatar
              variant="light-primary"
              size="40"
              :src="item.image"
              class="mr-1"
              rounded="circle"
            >
              <feather-icon
                v-if="!item.image"
                icon="UserIcon"
                size="27"
              />
            </b-avatar>
            <span>{{ item.name_user }}</span>
            <span class="ml-1 text-primary font-italic">[ {{ item.role }} ]</span>
          </div>
        </template>
        <p
          v-if="!team"
          class="text-center"
        >
          Not assigned
        </p>
      </div>
    </div>

  </component>
</template>
<script>
import ParagonService from '@/views/paragon/views/settings/service/paragon.service';

export default {
  props: {
    developersTeam: {
      type: Array,
      default: () => [],
    },
    developersTeamName: {
      type: String,
      default: null,
    },
    showType: {
      type: Number,
      default: 1,
    },
    requestId: {
      type: Number,
      default: null,
    },
  },
  data() {
    return {
      items: [],
      modalUp: false,
      title: null,
    };
  },
  computed: {
    tagType() {
      return this.showType === 2 ? 'b-modal' : 'div';
    },
    team() {
      return this.showType === 2 ? this.items : this.developersTeam;
    },
    teamName() {
      return this.showType === 2 ? this.title : this.developersTeamName;
    },
  },
  async created() {
    this.modalUp = true;
    if (this.showType === 2) {
      await this.getTeamByProject();
    }
  },
  methods: {
    async getTeamByProject() {
      try {
        const { data } = await ParagonService.getTeamByProject(this.requestId);
        this.title = data[0].team_name;
        this.items = data;
      } catch (error) {
        console.error(error);
      }
    },
    hideModal() {
      this.modalUp = false;
      this.$emit('close');
    },
  },
};
</script>
