const filters = [
    {
        key: "from",
        type: "datepicker",
        margin: true,
        showLabel: true,
        label: "From",
        placeholder: "Date",
        class: "font-small-3",
        model: null,
        locale: "en",
        dateFormatOptions: {
            year: "numeric",
            month: "numeric",
            day: "numeric",
        },
        cols: 6,
        nameFilter: 'from'
    },
    {
        key: "to",
        type: "datepicker",
        margin: true,
        showLabel: true,
        label: "To",
        placeholder: "Date",
        class: "font-small-3",
        model: null,
        locale: "en",
        dateFormatOptions: {
            year: "numeric",
            month: "numeric",
            day: "numeric",
        },
        cols: 6,
        nameFilter: 'to'
    },
    // {
    //     key: "type",
    //     type: "select",
    //     margin: true,
    //     showLabel: true,
    //     label: "Status",
    //     model: null,
    //     options: [
    //         { id: 1, name: 'DRAFT' },
    //         { id: 2, name: 'QUEUED' },
    //         { id: 3, name: 'PENDING TEAM ASSIGNMENT' },
    //         { id: 4, name: 'ON HOLD' },
    //         // { id: 5, name: 'IN DEVELOPMENT' },
    //         { id: 6, name: 'PROCESS DIAGRAM' },
    //         { id: 7, name: 'PROTOTYPE' },
    //     ],
    //     reduce: "id",
    //     selectText: "name",
    //     cols: 12
    //   },
];

export default filters;