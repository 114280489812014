<template>
  <div>
    <b-modal
      ref="modal-tracking-request"
      size="lg"
      @hidden="$emit('hidden')"
      hide-footer
      scrollable
    >
      <template #modal-title>
        <span class="text-uppercase font-weight-bolder"
          >Request Tracking: {{ requestCode }}</span
        >
      </template>
      <template #default>
        <b-card no-body class="mb-0">
          <b-table
            small
            :fields="fields"
            :items="items"
            :busy.sync="isBusy"
            show-empty
            class="font-small-3 text-center"
          >
            <template #table-busy>
              <div class="text-center text-primary my-2">
                <b-spinner class="align-middle mr-1" />
                <strong>Loading ...</strong>
              </div>
            </template>
            <template #cell(status)="data">
              <div v-if="data.item.parent_status_id">
                <b-badge
                  :variant="getVariantStatus(data.item.parent_status_id)[0]"
                >
                  <feather-icon
                    style="margin-top: -2px"
                    v-if="getVariantStatus(data.item.parent_status_id)[1]"
                    :icon="getVariantStatus(data.item.parent_status_id)[1]"
                  />
                  {{ data.item.parent_status_name }}
                </b-badge>

                <div style="margin-top: 3px">
                  [
                  <b-badge
                    class="font-italic"
                    style="font-size: 9px"
                    :variant="getVariantStatus(data.item.status_id)[0]"
                  >
                    <feather-icon
                      class="status-icon"
                      v-if="getVariantStatus(data.item.status_id)[1]"
                      :icon="getVariantStatus(data.item.status_id)[1]"
                    />
                    {{ data.item.status }}
                  </b-badge>
                  ]
                </div>
              </div>
              <b-badge
                v-else
                :id="`tooltip-pg-${data.item.id}`"
                :variant="getVariantStatus(data.item.status_id)[0]"
              >
                <feather-icon
                  style="margin-top: -2px"
                  v-if="getVariantStatus(data.item.status_id)[1]"
                  :icon="getVariantStatus(data.item.status_id)[1]"
                />
                {{ data.item.status }}
              </b-badge>
            </template>
            <template #cell(created_at)="data">
              <span> {{ data.item.created_at | myGlobal }}</span>
            </template>
            <template #cell(updated_at)="data">
              <span> {{ data.item.updated_at | myGlobal }}</span>
            </template>
            <template #cell(due_date)="data">
              <span> {{ data.item.due_date | myGlobal }}</span>
            </template>
          </b-table>
        </b-card>
      </template>
    </b-modal>
  </div>
</template>
      <script>
import RequestService from "@/views/commons/components/paragon-soft/services/PGRequest.services.js";
export default {
  props: {
    requestCode: {
      type: String,
      required: true,
    },
    requestId: {
      type: Number,
      required: true,
    },
  },
  data() {
    return {
      isBusy: false,
      items: [],
      fields: [
        { key: "user_name", label: "User", sortable: false },
        { key: "status", label: "Status", sortable: false },
        { key: "created_at", label: "Created At", sortable: false },
        { key: "updated_at", label: "Updated At", sortable: false },
        { key: "due_date", label: "Due Date", sortable: false },
      ],
    };
  },
  mounted() {
    this.toggleModal("modal-tracking-request");
    this.getRequestTrackingById();
  },
  computed: {},
  methods: {
    async getRequestTrackingById() {
      try {
        this.isBusy = true;
        const { data } = await RequestService.getRequestTrackingById(
          this.requestId
        );
        this.items = data;
      } catch (error) {
        this.showErrorSwal(error);
      } finally {
        this.isBusy = false;
      }
    },
    getVariantStatus(status_id) {
      let variant = ["", ""];
      switch (status_id) {
        case 1:
          variant = ["light-warning", "WatchIcon"];
          break;
        case 2:
          variant = ["light-primary", "BoxIcon"];
          break;
        case 3:
          variant = ["light-orange", "FramerIcon"];
          break;
        case 4:
          variant = ["light-prototype", "FigmaIcon"];
          break;
        case 5:
          variant = ["light-black", "CodeIcon"];
          break;
        case 6:
          variant = ["light-testing", "ChevronsUpIcon"];
          break;
        case 7:
          variant = ["light-success", "CheckCircleIcon"];
          break;
      }

      return variant;
    },
  },
};
</script>
<style scoped>
.badge-light-orange {
  color: #ff7005 !important;
}
.badge-light-prototype {
  color: #61a804 !important;
}
.badge-light-black {
  color: #000 !important;
}
.badge-light-testing {
  color: #00d25b !important;
}
svg.status-icon.feather {
  margin-top: -3px !important;
}

.dark-layout .badge-light-prototype {
  color: #91ff00 !important;
}
.dark-layout .badge-light-black {
  color: #fff !important;
}
</style>