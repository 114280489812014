<template>
  <div
    class="d-flex align-items-center justify-content-between chat-header p-2"
    v-if="G_CURRENT_CHAT_HEADER"
  >
    <div class="d-flex align-items-center">
      <b-avatar
        size="4rem"
        :src="`/assets/${G_CURRENT_CHAT_HEADER.first_module}`"
        class="mr-1"
      ></b-avatar>
      <div class="d-flex flex-column">
        <span class="current-chat-name">
          {{ G_CURRENT_CHAT_HEADER.title }}
        </span>
      </div>
    </div>

    <div class="d-flex align-items-center">
      <div class="p-1 chat-header-icon" @click="toggleSidebar">
        <DocumentsSvg />
      </div>
      <div
        class="custom-padding chat-header-icon"
        v-if="G_CURRENT_CHAT_HEADER.types"
      >
        <template>
          <b-dropdown
            v-if="G_CURRENT_CHAT_HEADER.types.length > 1"
            size="xs"
            variant="link"
            toggle-class="text-decoration-none"
            no-caret
          >
            <template #button-content>
              <feather-icon
                :badge="
                  getTotal(G_CURRENT_CHAT_HEADER.types) > 0
                    ? getTotal(G_CURRENT_CHAT_HEADER.types)
                    : ''
                "
                :badge-classes="['bg-danger']"
                icon="RepeatIcon"
                size="20"
                class="p-0"
                :style="isDarkSkin ? 'color: #5EEAD4' : 'color: #09132C'"
              />
            </template>

            <b-dropdown-item
              class="text-uppercase mr-1"
              v-for="(type, index) in G_CURRENT_CHAT_HEADER.types"
              :key="index"
              @click="changeType(type)"
            >
              {{ type.type }}
              <b-badge variant="danger" v-if="type.pending_messages > 0">{{
                type.pending_messages
              }}</b-badge>
            </b-dropdown-item>
          </b-dropdown>
        </template>
      </div>
      <div
        v-if="isChief || isCeo"
        class="p-1 chat-header-icon"
        id="add-participant"
        href="#"
        tabindex="0"
      >
        <UsersSvg />

        <b-popover
          boundary="viewport"
          placement="topright"
          :target="'add-participant'"
          title="Search participants"
          variant="primary"
          triggers="focus"
        >
          <SearchParticipants
            :text="participantName"
            @userSelected="addMemberToChat"
          />
        </b-popover>
      </div>
      <div class="p-1 chat-header-icon" @click="$emit('toggleSearchSidebar')">
        <SearchSvg />
      </div>
    </div>
  </div>
</template>
<script>
import SearchSvg from "@/assets/images/icons/request-chat/search.vue";
import UsersSvg from "@/assets/images/icons/request-chat/users.vue";
import SearchParticipants from "@/layouts/components/navbar/SearchParticipants.vue";
import DocumentsSvg from "@/assets/images/icons/request-chat/documents.vue";
import { mapActions, mapGetters, mapMutations } from "vuex";
import BinnacleService from "@/views/commons/components/paragon-soft/services/PGBinnacle.service.js";

export default {
  name: "ChatNavbar",
  components: {
    SearchSvg,
    UsersSvg,
    SearchParticipants,
    DocumentsSvg,
  },
  computed: {
    ...mapGetters({
      currentUser: "auth/currentUser",
      G_CURRENT_CHAT_ID: "SoftProjectsBinnacleStore/G_CURRENT_CHAT_ID",
      G_CURRENT_CHAT_HEADER: "SoftProjectsBinnacleStore/G_CURRENT_CHAT_HEADER",
      G_CURRENT_CHAT_MEMBERS:
        "SoftProjectsBinnacleStore/G_CURRENT_CHAT_MEMBERS",
    }),
  },
  data() {
    return {
      participantName: "",
    };
  },
  methods: {
    ...mapActions({
      A_GET_CHAT_MEMBERS: "SoftProjectsBinnacleStore/A_GET_CHAT_MEMBERS",
    }),
    ...mapMutations({
      M_SET_CURRENT_CHAT_ID: "SoftProjectsBinnacleStore/M_SET_CURRENT_CHAT_ID",
      M_ADD_MEMBER: "SoftProjectsBinnacleStore/M_ADD_MEMBER",
    }),

    toggleSidebar() {
      this.$emit("toggleSidebar");
    },
    async changeType(type) {
      this.M_SET_CURRENT_CHAT_ID(type.binnacle_id);

      await this.A_GET_CHAT_MEMBERS({
        binnacle_id: type.binnacle_id,
      });
      this.$emit("changeType", type.binnacle_id);
    },

    userIsInChat(user_id) {
      console.log(user_id, "user_id");
      console.log(this.G_CURRENT_CHAT_MEMBERS, "this.G_CURRENT_CHAT_MEMBERS");
      return this.G_CURRENT_CHAT_MEMBERS.some(
        (participant) => participant.user_id === user_id
      );
    },
    getTotal(total_messages) {
      let total = 0;
      total_messages.forEach((element) => {
        total += element.pending_messages;
      });
      return total;
    },
    async addMemberToChat(user) {
      try {
        if (this.userIsInChat(user.id)) {
          this.showGenericToast({
            title: "Error",
            text: "User already added",
            variant: "danger",
          });
        } else {
          this.addPreloader();
          const params = {
            user_id: user.id,
            pg_binnacle_id: this.G_CURRENT_CHAT_ID,
          };
          const { data } = await BinnacleService.addMember(params);

          this.showGenericToast({
            title: "Success",
            text: "User added successfully",
            variant: "success",
          });
          await this.A_GET_CHAT_MEMBERS({
            binnacle_id: this.G_CURRENT_CHAT_ID,
          });
          //this.M_ADD_MEMBER(data);
        }
      } catch (error) {
        this.showErrorSwal(error);
      } finally {
        this.removePreloader();
      }
    },
  },
};
</script>
<style lang="scss" scoped>
.chat-header {
  border-bottom: 2px solid #d7d7d7;
  max-height: 10vh;
  position: relative !important;
  .current-chat-name {
    font-weight: 800;
    font-size: 1.2rem;
    color: #626262;
  }
  .custom-padding {
    padding: 0.5rem 0rem;
  }
  .chat-header-icon {
    background-color: #d7d7d7;
    border-radius: 50%;
    cursor: pointer;
    margin: 0 0.2rem;
  }
}
.dark-layout {
  .chat-header {
    background-color: #040d19;
    border-bottom: 2px solid #0b213f;
    .current-chat-name {
      color: #fff;
    }
    .chat-header-icon {
      background-color: rgba(255, 255, 255, 0.03);
    }
    .popover-target-1 {
      background-color: rgba(94, 234, 212, 0.74) !important;
    }
  }
}
</style>