<template>
  <b-modal
      v-model="onControl"
      title-tag="h3"
      :title="titleInsideModal"
      no-close-on-backdrop
      modal-class="modal-success"
      header-bg-variant="success"
      @hidden="close"
      size="xl"
      :hide-footer="cancelDone ||  isDoneEmployee || payNet"
  >
    <cards-payments
        :byHour="byHour"
        :hours="hours"
        :exRate="exRate"
        :salary$="salary$"
        :salaryS="salaryS"
    />
    <fieldset class="border p-1 rounded border-success">
      <legend  class="w-auto text-success mb-0">&nbsp;Net&nbsp;</legend>
      <validation-observer ref="form">
      <b-row>
        <b-col>
          <b-input-group>
            <template #prepend>
              <b-input-group-text class="text-white color-gray common-width" >Net $ </b-input-group-text>
            </template>
            <money style="width: 70%; border: 1px solid #d8d6de; border-radius: 0.357rem; padding: 0.438rem 1rem; height: 2.714rem;"
                   class="form-control"
                   v-model="net"
                   v-bind="{
                    decimal: '.',
                    thousands: ',',
                    precision: 2,
                    masked: false,
                  }"
                   disabled
            />
          </b-input-group>
        </b-col>
        <b-col>
          <validation-provider
              v-slot="{ errors }"
              name="Date"
              rules="required"
          >
          <b-input-group>
            <template #prepend>
              <b-input-group-text class="text-white color-gray common-width" >Date</b-input-group-text>
            </template>
            <b-form-datepicker
                :date-format-options="{ year: 'numeric', month: 'numeric', day: 'numeric' }"
                :disabled="cancelDone || isDoneEmployee"
                locale="en"
                v-model="netDate"
                :min="minDate"
                :max="maxDate"
            />
          </b-input-group>
            <span v-if="errors[0]" class="text-danger">Date {{ errors[0] }}</span>
          </validation-provider>
        </b-col>
      </b-row>
      <b-row class="mt-2">
        <b-col>

          <b-input-group>
            <template #prepend>
              <b-input-group-text class="text-white color-gray common-width" >Type </b-input-group-text>
            </template>
            <v-select style="width: 36.8rem"
                      v-model="methodPay"
                      :options="methodsPay"
                      :disabled="cancelDone || isDoneEmployee"
                      required
                      :reduce="(option) => option.id"
                      @input="operationNumber=null"
            />
          </b-input-group>
        </b-col>
        <b-col>
          <validation-provider
              v-slot="{ errors }"
              name="operation"
              :rules="`${ methodPay == 1 || methodPay == 2? 'required' : ''}`"
          >
          <b-input-group>
            <template #prepend>
              <b-input-group-text class="text-white color-gray">#Operation</b-input-group-text>
            </template>
            <b-form-input
                v-model="operationNumber"
                type="number"
                placeholder="Enter the operation number"
                min="0"
                :disabled="cancelDone || isDoneEmployee"
            />
          </b-input-group>
            <span v-if="errors[0]" class="text-danger">Operation {{ errors[0] }}</span>
          </validation-provider>
        </b-col>
      </b-row>
      </validation-observer>
    </fieldset>
    <template #modal-footer="{ cancel }">
      <button-cancel @click="cancel()"></button-cancel>
      <b-button variant="success" @click="savePayroll">Save</b-button>
    </template>
  </b-modal>
</template>

<script>
import cardsPayments from '@/views/management/views/payroll/payroll-module/modals/CardsPayments'
import vSelect from 'vue-select'
import PayrollService from '@/views/management/views/payroll/payroll.service'
import buttonCancel from '@/views/commons/utilities/ButtonCancel.vue'

export default {
  props: {
    titleInsideModal: '',
    cancelDone: '',
    employee:[],
    isDoneEmployee: '',
    byHour: '',
    hours: '',
    exRate: '',
    salary$: '',
    salaryS: '',
  },
  components:{
    cardsPayments,
    vSelect,
    buttonCancel,
  },
  data(){
    return {
      onControl: false,
      net: 0,
      payNet: false,
      myLocalEmployee: [],
      emptyModal: true,
      netDate: '',
      minDate: '',
      maxDate: '',
      methodPay: '',
      methodsPay: [{ id: 1, label: "TRANSFER" }, { id: 2, label: "CHECK"}, { id: 3, label: "CASH"}],
      operationNumber: '',
    }
  },
  async created(){
    this.myLocalEmployee = {...this.employee}
    this.methodPay = this.myLocalEmployee.method_pay;
    this.payNet = !!this.myLocalEmployee.pay_net
    this.onControl = true
    if (Number(this.myLocalEmployee.pay_net)>0){
      this.emptyModal = false
    }
    this.net = Number(this.myLocalEmployee.total_aditional) - Number(this.myLocalEmployee.total_disccount);
    await this.getNet()
  },
  methods: {
    close() {
      this.$emit("close");
    },
    async getNet(){
      try {
        if(!this.emptyModal){
          this.addPreloader()
          const data = await PayrollService.getPayrollById({
            pay_user_id: this.myLocalEmployee.id,
            type_pay: 3,
          })
          if(data.status===200){
            this.net = Number(data.data[0].pay_net)
            this.netDate = data.data[0].pay_date;
            this.methodPay = data.data[0].pay_type;
            this.operationNumber = data.data[0].num_operation;
            this.removePreloader()
          }
        }
      } catch (e) {
        this.showErrorSwal(e)
        this.removePreloader()
      }
    },
    async savePayroll(){
      try {
        const result = await this.$refs.form.validate()
        if (result) {
          const response = await this.showConfirmSwal();
          if (response.isConfirmed) {
            const params = {
              type: 3,
              payEmployeeId: this.myLocalEmployee.id,
              exchange: this.exRate,
              byHour: this.byHour,
              net: this.net,
              netDate: this.netDate,
              methodPay: this.methodPay,
              operationNumber: this.operationNumber,
            }
            this.addPreloader()
            const data = await PayrollService.savePayroll(params)
            if (data.status === 200) {
              this.$emit('reload')
              this.removePreloader()
              this.showSuccessSwal("Payment saved successfully");
              this.close()
            }
          }
        }
      } catch (e) {
        this.removePreloader()
        this.showErrorSwal(e)
      }
    },
  },
}
</script>

<style scoped>
.pad{
  padding: 0.8rem !important;
}
.color-gray{
  background-color: #6c757d !important;
}
.common-width{
  width: 7.2rem !important;
}
</style>
