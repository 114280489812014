<template>
  <div>
    <b-modal
      ref="modal-request-details"
      size="lg"
      @hidden="$emit('hidden')"
      hide-footer
    >
      <template #modal-title>
        <span class="text-uppercase font-weight-bolder">
          Show Request Details: {{ requestCode }}</span
        >
      </template>
      <template #default>
        <div class="project">
          <!-- project information -->
          <div class="card-project-item mb-1">
            <div class="card-project-header sticky border-bottom">
              <div
                class="d-flex justify-content-between align-items-center pb-1"
              >
                <span class="text-secondary">
                  <feather-icon icon="CalendarIcon" />
                  {{ request.created_at | formattedDate }}
                </span>
                <span class="text-secondary"> #{{ request.code }} </span>
              </div>
              <!-- project title -->
              <h5
                class="text-primary text-uppercase font-weight-bolder m-0 p-0"
              >
                {{ request.title }}
                <feather-icon
                  v-if="isCeo"
                  icon="EditIcon"
                  class="cursor-pointer text-warning"
                  style="margin-bottom: 0.3rem"
                  @click="openModalUpdateRequest()"
                />
              </h5>
            </div>
            <div class="content-description">
              <strong>Description</strong>
              <div class="mt-1" v-html="request.description"></div>
            </div>
            <div class="content-modules border-top">
              <strong class="mr-1">Modules:</strong>
              <b-badge
                v-for="(module, index) in request.modules_detail"
                :key="index"
                variant="light-primary"
                style="margin-right: 0.5rem"
              >
                {{ module.name }}
              </b-badge>
            </div>
          </div>
          <div class="card-project-item mb-1">
            <div
              class="card-project-header sticky border-bottom d-flex justify-content-between align-items-center"
            >
              <h5
                class="text-primary text-uppercase font-weight-bolder m-0 p-0"
              >
                FILES
              </h5>
              <b-button
                v-if="isCeo || isChief"
                variant="success"
                @click="showModalUploadFiles = true"
                class="text-black"
                size="sm"
              >
                <span class="font-weight-bold">+ ADD FILES </span>
              </b-button>
            </div>
            <div>
              <b-table
                ref="filesTable"
                slot="table"
                table-class="bg-table-files"
                :items="files"
                :fields="fields"
                :busy="isBusy"
                primary-key="id"
                responsive="md"
                sticky-header="20vh"
                show-empty
                small
                no-provider-filtering
              >
                <template #cell(file_name)="{ item }">
                  <div class="d-flex align-items-center">
                    <img
                      :src="getImageFile(item.extension)"
                      width="20"
                      height="20"
                      class="mr-1"
                    />
                    <span>
                      {{ item.file_name }}
                    </span>
                  </div>
                </template>
                <template #cell(created_at)="{ item }">
                  {{ `${item.first_name} ${item.last_name}` }}
                  <div>
                    {{ item.created_at | myGlobalDay }}
                  </div>
                </template>
                <template #cell(actions)="{ item }">
                  <div class="d-flex justify-content-center gap-5">
                    <feather-icon
                      icon="DownloadIcon"
                      size="18"
                      class="cursor-pointer text-primary"
                      @click="forceDownload(item.route, item.file_name)"
                    />
                    <feather-icon
                      v-if="isCeo || isChief"
                      icon="Trash2Icon"
                      size="18"
                      class="cursor-pointer text-danger"
                      @click="deleteFile(item.id)"
                    />
                  </div>
                </template>
              </b-table>
            </div>
          </div>
            <div class="card-project-item mb-1">
            <div class="card-project-header sticky border-bottom"> 
              <!-- project title -->
              <h5
                class="text-primary text-uppercase font-weight-bolder m-0 p-0"
              >
                PARAGON COMMENTARY
                <feather-icon
                  v-if="isChief"
                  icon="EditIcon"
                  class="cursor-pointer text-warning"
                  style="margin-bottom: 0.3rem"
                  @click="openModalUpdateCommentRequest()"
                />
              </h5>
            </div>
            <div class="content-description"> 
              <div class="mt-1" v-html="request.comment_paragon"></div> 
            </div>
          </div>
        </div>
      </template>
    </b-modal>
    <modal-update-request
      v-if="showModalUpdateRequest"
      :request-id="requestId"
      :request-code="requestCode"
      @hidden="showModalUpdateRequest = false"
      @refreshPGRequest="refreshAll()"
    />
    <upload-files
      v-if="showModalUploadFiles"
      :pg-request-id="requestId"
      @close="showModalUploadFiles = false"
      @refresh="
        showModalUploadFiles = false;
        getFilesByRequestId();
      "
    />
    <modal-update-comment-paragon-request
      v-if="showModalUpdateCommentRequest"
      :request-id="requestId"
      :request-code="requestCode"
      @hidden="showModalUpdateCommentRequest = false"
      @refreshPGRequest="refreshAll()"
    />
  </div>
</template>
  <script>
import { quillEditor } from "vue-quill-editor";
import RequestService from "@/views/commons/components/paragon-soft/services/PGRequest.services.js";
import ModalDevelopmentTeam from "@/views/commons/components/paragon-soft/modals/ModalDevelopmentTeam.vue";
import ModalUpdateRequest from "@/views/commons/components/paragon-soft/modals/ModalUpdateRequest.vue";
import UploadFiles from "@/views/commons/components/paragon-soft/components/UploadFiles.vue";
import ModalUpdateCommentParagonRequest from "@/views/specialist-digital/views/updates/components/modal/ModalUpdateCommentParagonRequest.vue"
import moment from "moment";
export default {
  components: {
    quillEditor,
    ModalDevelopmentTeam,
    ModalUpdateRequest,
    UploadFiles,
    ModalUpdateCommentParagonRequest,
  },
  props: {
    requestId: {
      type: Number,
      required: true,
    },
    requestCode: {
      type: String,
      required: true,
    },
  },
  filters: {
    formattedDate: function (value, format = "MMMM DD, YYYY") {
      if (!value) return "-";
      return moment(value).format(format);
    },
  },
  data() {
    return {
      request: {
        code: "",
        title: "",
        description: "",
        created_at: "",
        developers_team_name: "",
        developers_team: [],
        designers_team_name: "",
        designers_team: [],
        modules_detail: [],
        comment_paragon: "",
      },
      isBusy: false,
      showModalUpdateRequest: false,
      showModalUploadFiles: false,
      files: [],
      fields: [
        {
          key: "file_name",
          label: "File Name",
          sortable: true,
          thStyle: { width: "50%" },
        },
        { key: "size", label: "Size", sortable: true, class: "text-center" },
        {
          key: "created_at",
          label: "Created At",
          sortable: true,
          class: "text-center",
        },
        { key: "actions", label: "Actions", class: "text-center" },
      ],
      showModalUpdateCommentRequest:false,
    };
  },
  mounted() {
    this.toggleModal("modal-request-details");
    this.getRequestById();
    this.getFilesByRequestId();
  },
  computed: {},
  methods: {
    async getRequestById() {
      try {
        this.addPreloader();
        const { data } = await RequestService.getRequestById(this.requestId);
        this.request = {
          code: data.code,
          title: data.title,
          description: data.description,
          created_at: data.created_at,
          developers_team: JSON.parse(data.developers_team),
          developers_team_name: data.developers_team
            ? JSON.parse(data.developers_team)[0].team_name
            : "",
          designers_team: JSON.parse(data.designers_team),
          designers_team_name: data.designers_team
            ? JSON.parse(data.designers_team)[0].team_name
            : "",
          modules_detail: JSON.parse(data.modules_detail),
          comment_paragon: data.comment_paragon,
        };
      } catch (error) {
        this.showErrorSwal(error);
      } finally {
        this.removePreloader();
      }
    },
    async getFilesByRequestId() {
      const { data } = await RequestService.getFilesByRequest(this.requestId);
      this.files = data.data;
    },
    async deleteFile(id) {
      const { isConfirmed } = await this.showConfirmSwal();
      if (!isConfirmed) {
        return;
      }
      try {
        this.addPreloader();
        await RequestService.destroyFileById(id);
        this.getFilesByRequestId();
        this.showSuccessSwal();
      } catch (error) {
        this.showErrorSwal(error);
      } finally {
        this.removePreloader();
      }
    },

    forceDownload(url, filename) {
      const link = document.createElement("a");
      link.href = url;
      link.target = "_blank";
      link.setAttribute("download", filename);
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    },
    getImageFile(extension) {
      const extensionMappings = {
        jpg: "jpg.png",
        png: "jpg.png",
        jpeg: "jpg.png",
        gif: "jpg.png",
        xls: "excel.png",
        xlsx: "excel.png",
        html: "html",
        pdf: "pdf.png",
        docx: "doc.png",
        pptx: "ppt.jpg",
        ppt: "ppt.jpg",
        default: "archivodesc.png",
      };

      const getImageFile =
        extensionMappings[extension] || extensionMappings.default;
      return `/assets/images/drag-drop/ext_files/${getImageFile}`;
    },
    openModalUpdateRequest() {
      this.showModalUpdateRequest = true;
    },
    refreshAll() {
      this.showModalUpdateCommentRequest = false;
      this.showModalUpdateRequest = false;
      this.getRequestById();
      this.$emit("refreshPGRequest");
    },
    openModalUpdateCommentRequest() {
      this.showModalUpdateCommentRequest = true;
    },
  },
};
</script>
<style lang="scss">
.project {
  gap: 1rem;
  padding: 0.5rem;

  .card-project-item {
    background-color: #fff;
    border-radius: 0.6rem;
    box-shadow: 0px 3px 8px 0px rgba(0, 0, 0, 0.3);

    .card-project-header {
      padding: 1rem;
    }
    .card-project-header.sticky {
      position: sticky;
      top: 0;
      background-color: #fff;
      z-index: 1;
    }
    .content-description {
      max-height: 20rem;
      min-height: 10rem;
      overflow: hidden;
      overflow-y: auto;
      padding: 1rem;
    }
    .content-modules {
      padding: 1rem;
    }
  }
}
.dark-layout {
  .card-project-item {
    background-color: #22272b;
    box-shadow: none;
    .card-project-header.sticky {
      background-color: #22272b;
    }
  }
}
.bg-table-files {
  background-color: transparent !important;
}
</style>