<template>
  <b-modal
    @hidden="$emit('close')"
    v-model="show"
    :title="`UPLOAD FILES`"
    size="xmd"
  >
    <div @paste.prevent="handlePaste">
      <ValidationObserver ref="observer">
        <ValidationProvider rules="required" v-slot="{ errors }">
          <drag-and-drop
            class="w-100 mb-1"
            v-model="files"
            :filesArray="files"
            :class="errors[0] ? 'border-danger' : null"
            @input="changeFiles"
            ref="dragAndDrop"
            :key="key"
          />
        </ValidationProvider>
      </ValidationObserver>
    </div>

    <template #modal-footer>
      <b-button variant="primary" @click="uploadFiles">Submit</b-button>
    </template>
  </b-modal>
</template>

<script>
import DragAndDrop from "@/views/commons/utilities/DragAndDrop.vue";
import PGRequestServices from "@/views/commons/components/paragon-soft/services/PGRequest.services.js";
import { mapGetters } from "vuex";
export default {
  components: {
    DragAndDrop,
  },
  props: {
    pgRequestId: {
      type: [String, Number],
      required: true,
    },
  },

  computed: {
    ...mapGetters({
      currentUser: "auth/currentUser",
    }),
  },
  data() {
    return {
      show: true,
      files: [],
      key: 0,
    };
  },

  methods: {
    changeFiles(refresh = false) {
      this.files.map((file) => {
        file["file_name"] = file["name"];
        file["file_size"] = file["size"];
        file["created_by"] = this.currentUser.user_id;
      });
      if (refresh) this.key++;
    },

    async uploadFiles() {
      const result = await this.$refs.observer.validate();
      if (!result) return;

      try {
        this.addPreloader();
        const params = {
          pg_request_id: this.pgRequestId,
          files: this.files,
        };
        const { data, status } = await PGRequestServices.insertNewFiles(params);
        if (status == 200) {
          this.$emit("refresh");
          this.showSuccessSwal();
        }
      } catch (error) {
        this.showErrorSwal(error);
      } finally {
        this.removePreloader();
      }
    },

    async handlePaste(event) {
      const items = (event.clipboardData || event.originalEvent.clipboardData)
        .items;
      for (const item of items) {
        if (item.type.indexOf("image") !== -1) {
          const blob = item.getAsFile();
          const newName = `Screenshot-${new Date().getTime()}.${
            blob.type.split("/")[1]
          }`;
          const file = new File([blob], newName, { type: blob.type }); // convert blob to file
          const imageBase64 = await this.blobToBase64(blob); // convert blob to base64
          file.fileapproved = imageBase64;
          this.files.push(file);
          this.changeFiles(true);
          break;
        }
      }
    },
    async blobToBase64(blob) {
      return new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.onload = () => {
          const imageBase64 = reader.result;
          resolve(imageBase64);
        };
        reader.onerror = (error) => {
          reject(error);
        };
        reader.readAsDataURL(blob);
      });
    },
  },
};
</script>

<style>
</style>