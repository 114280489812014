<template>
  <b-modal
    v-model="onControl"
    title-tag="h3"
    :title="titleInsideModal"
    modal-class="modal-danger"
    header-bg-variant="danger"
    size="xl"
    :hide-footer="cancelDone || isDoneEmployee "
    @hidden="close"
  >
    <cards-payments
      :by-hour="byHour"
      :hours="hours"
      :ex-rate="exRate"
      :salary$="salary$"
      :salary-s="salaryS"
    />
    <fieldset class="border p-1 rounded border-danger">
      <legend class="w-auto text-danger mb-0">
        &nbsp;Discounts&nbsp;
      </legend>
      <validation-observer ref="form">
        <div
          v-if="!isDoneEmployee && !cancelDone"
          class="d-flex justify-content-between mb-1"
        >
          <b-button
            variant="danger"
            @click="addDiscount"
          >
            Add
          </b-button>
          <b-button
            v-if="discountPays.length>0"
            size="sm"
            variant="outline-danger"
            @click="openCreateMotiveModal"
          >Create motive  <feather-icon
            icon="PlusIcon"
            size="15"
          /></b-button>
        </div>
        <b-row
          v-for="(discount, index) in discountPays"
          :key="index"
          class="mt-1"
        >
          <b-col cols="5">
            <validation-provider
              v-slot="{ errors }"
              name="notes"
              rules="required"
            >
              <b-input-group>
                <template #prepend>
                  <b-input-group-text class="text-white color-gray"><tabler-icon
                    icon="NotesIcon"
                  /> </b-input-group-text>
                </template>
                <v-select
                  v-model="discount.motive"
                  style="width: 25rem"
                  :selectable="option => option.disabled!==true"
                  required
                  placeholder="Choose a Motive"
                  :options="motivesDiscount"
                  label="value"
                  :disabled="cancelDone || isDoneEmployee"
                  :reduce="value => value.value"
                  @input="changeMotiveDiscount(index, discount.motive)"
                />
              </b-input-group>
              <span
                v-if="errors[0]"
                class="text-danger"
              >{{ errors[0] }}</span>
            </validation-provider>
          </b-col>
          <b-col cols="2">
            <b-input-group>
              <template #prepend>
                <b-input-group-text class="text-white color-gray">
                  <feather-icon
                    class=""
                    icon="ClockIcon"
                  /> </b-input-group-text>
              </template>
              <b-form-input
                v-model="discount.hours"
                type="number"
                min="0"
                placeholder="0"
                :disabled="discount.amountS > 0 || cancelDone || isDoneEmployee || discount.motive == 'Provision'"
                @input="discountAmount(index)"
              />
            </b-input-group>
          </b-col>
          <b-col cols="2">
            <b-input-group>
              <template #prepend>
                <b-input-group-text class="text-white color-gray">
                  S/.
                </b-input-group-text>
              </template>
              <money
                v-model.lazy="discount.amountS"
                class="input-money-style form-control"
                v-bind="{
                  decimal: '.',
                  thousands: ',',
                  precision: 2,
                  masked: false,
                }"
                :disabled="discount.hours > 0 || cancelDone || isDoneEmployee || discount.motive == 'Provision'"
                @keyup.native="solesDollar(index)"
              />
            </b-input-group>
          </b-col>
          <b-col>
            <validation-provider
              v-slot="{ errors }"
              name="dollars"
              rules="required|validate-amount"
            >
              <b-input-group>
                <template #prepend>
                  <b-input-group-text class="text-white color-gray">
                    $
                  </b-input-group-text>
                </template>
                <money
                  v-model="discount.amountD"
                  class="input-money-style form-control"
                  v-bind="{
                    decimal: '.',
                    thousands: ',',
                    precision: 2,
                    masked: false,
                  }"
                  :disabled="discount.hours > 0 || discount.amountS >0 || cancelDone || isDoneEmployee || discount.motive == 'Provision'"
                />
              </b-input-group>
              <span
                v-if="errors[0]"
                class="text-danger"
              >{{ errors[0] }}</span>
            </validation-provider>
          </b-col>
          <b-col
            v-if="!cancelDone && !isDoneEmployee"
            cols="1"
          >
            <tabler-icon
              icon="CircleXIcon"
              size="35"
              class="text-danger cursor-pointer ml-2"
              @click="deleteDiscount(index)"
            />
          </b-col>
        </b-row>
      </validation-observer>
    </fieldset>
    <div class="mt-2">
      <b-input-group class="d-flex justify-content-center">
        <template #prepend>
          <b-input-group-text class="text-white color-gray">
            Total Discounts $
          </b-input-group-text>
        </template>
        <money
          class="input-money-style"
          :value="totalDiscount"
          disabled
          v-bind="{
            decimal: '.',
            thousands: ',',
            precision: 2,
            masked: false,
          }"
        />
      </b-input-group>
    </div>
    <template #modal-footer="{ cancel }">
      <button-cancel @click="cancel()" />
      <button-save
        variant="danger"
        @click="savePayroll()"
      />
    </template>
    <create-motive-modal
      v-if="openCreateMotiveModalController"
      :mood="mood"
      :title="title"
      @close="closeCreateMotiveModal"
    />
  </b-modal>
</template>

<script>
import buttonCancel from '@/views/commons/utilities/ButtonCancel.vue'
import buttonSave from '@/views/commons/utilities/ButtonSave.vue'
import cardsPayments from '@/views/management/views/payroll/payroll-module/modals/CardsPayments'
import vSelect from 'vue-select'
import createMotiveModal from '@/views/management/views/payroll/payroll-module/modals/CreateMotiveModal'
import PayrollService from '@/views/management/views/payroll/payroll.service'

export default {
  components: {
    buttonSave,
    buttonCancel,
    cardsPayments,
    vSelect,
    createMotiveModal,
  },
  props: {
    titleInsideModal: '',
    cancelDone: '',
    employee: [],
    isDoneEmployee: '',
    byHour: '',
    exRate: '',
    salary$: '',
    salaryS: '',
    hours: '',
    salaryHour: '',
  },
  data() {
    return {
      onControl: false,
      totalDiscounts: '',
      openCreateMotiveModalController: false,
      mood: 2,
      title: null,
      soles: '',
      dollars: '',
      adPaySoles: '',
      motivesDiscount: [],
      discountPays: [],
      emptyModal: true,
      dolarProvision: 0,
      solesProvision: 0,
    }
  },
  computed: {
    totalDiscount() {
      let total = this.discountPays.reduce(
        (sum, discount) => sum + discount.amountD,
        0,
      )
      total = Number(total)

      return total.toFixed(2)
    },
  },
  async created() {
    this.onControl = true
    this.myLocalEmployee = { ...this.employee }
    if (Number(this.myLocalEmployee.total_disccount) > 0 || this.myLocalEmployee.disccount_pay) {
      this.emptyModal = false
    }
    this.addPreloader()
    await this.getProvision()
    await this.getDiscounts()
    await this.getMotives()
    this.removePreloader()
  },
  methods: {
    close() {
      this.$emit('close')
    },
    closeCreateMotiveModal() {
      this.openCreateMotiveModalController = false
    },
    openCreateMotiveModal() {
      this.openCreateMotiveModalController = true
      this.title = 'Create Motive Discounts'
    },
    async getMotives() {
      try {
        const data = await PayrollService.getMotivesPayment({ in_type: 2 })
        if (data.status === 200) {
          this.motivesDiscount = data.data
          this.disabledMotives()
        }
      } catch (e) {
        this.showErrorSwal(e)
      }
    },
    async getDiscounts() {
      if (!this.emptyModal) {
        try {
          const data = await PayrollService.getPayrollById({
            pay_user_id: this.myLocalEmployee.id,
            type_pay: 2,
          })
          if (data.status === 200) {
            this.discountPays = data.data[0].disccount_pay == '1' ? [] : data.data[0].disccount_pay

            this.disabledMotives()
          }
        } catch (e) {
          this.showErrorSwal(e)
        }
      }
    },
    disabledMotives() {
      for (let i = 0; i < this.motivesDiscount.length; i++) {
        if (this.motivesDiscount[i].disabled == true) continue
        for (let j = 0; j < this.discountPays.length; j++) {
          if (this.motivesDiscount[i].value == this.discountPays[j].motive) {
            this.motivesDiscount[i].disabled = true
          }
        }
      }
    },
    addDiscount() {
      const payDiscount = {
        motive: '',
        hours: '',
        amountS: 0,
        amountD: 0,
      }
      this.discountPays.push(payDiscount)
    },
    deleteDiscount(index) {
      for (let i = 0; i < this.motivesDiscount.length; i++) {
        if (this.motivesDiscount[i].value == this.discountPays[index].motive) {
          this.motivesDiscount[i].disabled = false
          break
        }
      }
      this.discountPays.splice(index, 1)
    },
    discountAmount(index) {
      this.discountPays[index].amountD = this.discountPays[index].hours * this.salaryHour
      this.discountPays[index].amountD = Number(this.discountPays[index].amountD).toFixed(2)
    },
    changeMotiveDiscount(index, motive) {
      this.discountPays[index].hours = ''
      this.discountPays[index].amountS = motive == 'Provision' ? this.solesProvision : 0
      this.discountPays[index].amountD = motive == 'Provision' ? this.dolarProvision : 0

      this.disabledMotives()
    },
    solesDollar(index) {
      this.discountPays[index].amountD = this.discountPays[index].amountS / this.exRate
      this.discountPays[index].amountD = Number(this.discountPays[index].amountD).toFixed(2)
    },
    getProvision() {
      this.solesProvision = this.myLocalEmployee.currency_provision == 1 ? 0 : Number(this.myLocalEmployee.provision)
        ? Number(this.myLocalEmployee.provision) / 2
        : 0
      this.dolarProvision = this.solesProvision > 0 ? (Number(this.solesProvision) / Number(this.exRate)).toFixed(2)
        : Number(this.myLocalEmployee.provision) ? (Number(this.myLocalEmployee.provision) / 2).toFixed(2) : 0

      this.discountPays = [
        {
          motive: 'Provision',
          hours: '',
          amountS: Number(this.solesProvision),
          amountD: Number(this.dolarProvision),
        },
      ]
    },
    async savePayroll() {
      try {
        // const greater = this.totalDiscount > this.myLocalEmployee.total_aditional
        // if (greater) {
        //   this.showErrorSwal('Total discounts greater than regular additional', 'Total discounts can not be greater than regular additional')
        // }
        const result = await this.$refs.form.validate()
        if (result) {
          const response = await this.showConfirmSwal()
          if (response.isConfirmed) {
            const params = {
              type: 2,
              payEmployeeId: this.myLocalEmployee.id,
              exchange: this.exRate,
              byHour: this.byHour,
              discountPays: this.discountPays.length > 0 ? this.discountPays : '1',
              totalDiscount: this.totalDiscount,
            }
            this.addPreloader()
            const data = await PayrollService.savePayroll(params)
            if (data.status === 200) {
              this.$emit('reload')
              this.removePreloader()
              this.showSuccessSwal('Payment saved successfully')
              this.close()
            }
          }
        }
      } catch (e) {
        this.removePreloader()
        this.showErrorSwal(e)
      }
    },
  },
}
</script>

<style scoped>
.color-gray{
  background-color: #6c757d !important;
}
.input-money-style{
  width: 7rem; border: 1px solid #d8d6de; border-radius: 0.357rem; padding: 0.438rem 1rem; height: 2.714rem;
}
</style>
