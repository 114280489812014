<template>
  <b-modal
    ref="modalLinks"
    modal-class="modal-primary"
    size="lg"
    @hidden="closeModal()"
    centered
    no-close-on-backdrop
    hide-footer
  >
    <template #modal-title>
      <span class="h3 text-white font-weight-bolder"
        >Create Links From: {{ requestCode }}</span
      >
    </template>
    <div class="d-flex flex-column">
      <label for="date-due">Links</label>
      <b-input-group class="mb-2 mr-sm-2 mb-sm-0">
        <b-form-input v-model="link"></b-form-input>
      </b-input-group>

      <label class="mt-1" for="date-due">Description</label>
      <b-input-group class="mb-2 mr-sm-2 mb-sm-0">
        <b-form-input v-model="desc"></b-form-input>
      </b-input-group>
      <div class="d-flex justify-content-end mt-1">
        <b-button variant="primary" @click="saveLink" :disabled="!link">
          Add Link
        </b-button>
      </div>
      <hr />
      <div class="d-flex justify-content-between align-items-center">
        <h5 class="text-primary text-uppercase font-weight-bolder m-0 p-0">
          LINKS
        </h5>
      </div>
      <b-table
        ref="filesTable"
        slot="table"
        table-class="bg-table-files"
        :items="links"
        :fields="fields"
        :busy="isBusy"
        primary-key="id"
        responsive="md"
        sticky-header="20vh"
        show-empty
        small
        no-provider-filtering
      >
        <template #cell(actions)="data">
          <feather-icon
            icon="ExternalLinkIcon"
            class="cursor-pointer text-primary mr-1"
            @click="openLink(data.item)"
          />
          <feather-icon
            icon="TrashIcon"
            class="cursor-pointer text-danger"
            @click="removeLink(data.item)"
          />
        </template>
      </b-table>
    </div>
  </b-modal>
</template>
<script>
import { mapGetters } from "vuex";
import RequestService from "@/views/commons/components/paragon-soft/services/PGRequest.services.js";
export default {
  props: {
    requestCode: {
      type: String,
      required: true,
    },
    requestId: {
      type: Number,
      required: true,
    },
  },
  data() {
    return {
      isBusy: false,
      links: [],
      link: "",
      desc: "",
      fields: [
        { key: "link", label: "Link", thStyle: { width: "50%" } },
        { key: "description", label: "Description" },
        { key: "actions", label: "Actions", thStyle: { width: "10%" } },
      ],
    };
  },
  mounted() {
    this.toggleModal("modalLinks");
  },
  created() {
    this.getLinks();
  },
  computed: {
    ...mapGetters({
      currentUser: "auth/currentUser",
    }),
  },
  methods: {
    async getLinks() {
      try {
        this.addPreloader();
        const links = await RequestService.getLinks({
          pg_request_id: this.requestId,
        });
        this.links = links;
      } catch (error) {
        console.log(error);
      } finally {
        this.removePreloader();
      }
    },

    async saveLink() {
      this.addPreloader();
      if (!this.isLinkValid()) {
        this.showToast(
          "warning",
          "top-right",
          "Warning",
          "AlertTriangleIcon",
          "You can't add an invalid link"
        );
        this.removePreloader();
        return;
      }
      try {
        const link = {
          link: this.link,
          description: this.desc,
          request_id: this.requestId,
          created_by: this.currentUser.user_id,
        };
        await RequestService.saveLink(link);
        this.getLinks();
        this.link = "";
        this.desc = "";
        this.showSuccessSwal("Link added successfully");
      } catch (error) {
        console.log(error);
      } finally {
        this.removePreloader();
      }
    },
    isLinkValid() {
      const urlRegex = /^(ftp|http|https):\/\/[^ "]+$/;
      return urlRegex.test(this.link);
    },
    async removeLink(link) {
      try {
        const { isConfirmed } = await this.showConfirmSwal(
          "Are you sure you want to remove this link?"
        );
        if (!isConfirmed) return;
        this.addPreloader();
        await RequestService.removeLink({
          link_id: link.id,
          request_id: this.requestId,
        });
        const index = this.links.indexOf(link);
        this.links.splice(index, 1);
        this.showSuccessSwal("Link removed successfully");
      } catch (error) {
        console.log(error);
      } finally {
        this.removePreloader();
      }
    },
    async openLink(link) {
      window.open(link.link, "_blank");
    },
    closeModal() {
      this.$emit("close");
    },
  },
};
</script>
<style lang="scss" scoped>
.w-35 {
  width: 35% !important;
}
.w-20 {
  width: 20% !important;
}
</style>