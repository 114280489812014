<template>
      <div class="mx-3 my-1">
        <b-card-group class="d-flex justify-content-around flex-wrap" deck>
          <b-card
              class="p-0 text-center"
              header="Salary S/."
              header-bg-variant="secondary"
              header-text-variant="white"
              align="center"
              body-class="mt-2 text-center"
              header-class="pad text-center"
          >
            <b-card-text>{{ salaryS }}</b-card-text>
          </b-card>
          <b-card
              class="p-0 text-center"
              header="Ex.Rate S/."
              header-bg-variant="secondary"
              header-text-variant="white"
              align="center"
              body-class="mt-2 text-center"
              header-class="m-0 pad text-center"
          >
            <b-card-text> {{ exRate }} </b-card-text>
          </b-card>
          <b-card
              class="p-0 text-center"
              header="Salary $"
              header-tag="header"
              header-bg-variant="secondary"
              header-text-variant="white"
              align="center"
              body-class="mt-2 text-center"
              header-class="m-0 pad text-center"
          >
            <b-card-text>{{ salary$ }}</b-card-text>
          </b-card>
          <b-card
              class="p-0 text-center"
              header="Hours"
              header-tag="header"
              header-bg-variant="secondary"
              header-text-variant="white"
              align="center"
              body-class="mt-2 text-center"
              header-class="m-0 pad text-center"
          >
            <b-card-text>{{ hours }}</b-card-text>
          </b-card>
          <b-card
              header="By Hour $"
              header-tag="header"
              header-bg-variant="secondary"
              header-text-variant="white"
              align="center"
              body-class="mt-2 text-center"
              header-class="m-0 pl-3 pad text-center pl-3"
          >
            <b-card-text>{{ byHour }}</b-card-text>
          </b-card>
        </b-card-group>
      </div>
</template>

<script>
export default {
  props: {
    byHour: '',
    hours: '',
    exRate: '',
    salary$: '',
    salaryS: '',
  },
}
</script>

<style scoped>
.pad{
  padding: 0.8rem !important;
}
</style>
