import { render, staticRenderFns } from "./PayRollRegularModal.vue?vue&type=template&id=94dd24d2&scoped=true"
import script from "./PayRollRegularModal.vue?vue&type=script&lang=js"
export * from "./PayRollRegularModal.vue?vue&type=script&lang=js"
import style0 from "./PayRollRegularModal.vue?vue&type=style&index=0&id=94dd24d2&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "94dd24d2",
  null
  
)

export default component.exports