<template>
  <b-modal
    v-model="showModal"
    ref="chat-modal"
    body-class="px-1 py-0"
    :header-class="modalColor"
    size="xlg"
    hide-footer
    centered
    @hidden="close"
    no-close-on-backdrop
    no-close-on-esc
  >
    <template #modal-header>
      <span class="chat-modal-title">{{ modalTitle }}</span>
      <feather-icon
        v-if="currentUser.user_id != 402"
        icon="XIcon"
        size="20"
        class="ml-auto cursor-pointer text-light"
        @click="showModal = false"
      />
      <div v-else class="ml-auto cursor-pointer" @click="showModal = false">
        <Icon />
      </div>
    </template>

    <b-row class="request-chat-container h-100">
      <!-- <b-col cols="3" class="left-content px-0">
        <ChatSearchbar
          @search="searchMessage"
          @clearSearch="clearSearch"
          :searchInCurrentChat="false"
        />

        <span class="title-messages px-2"> Messages </span>

        <ChatList
          v-show="matchedMessages == null"
          ref="chat-list"
          @changeCurrentChat="changeCurrentChat"
        />

        <MatchedMessagesList
          v-show="matchedMessages != null"
          :matchedMessages="matchedMessages"
          @goToSelectedMessage="goToSelectedMessage"
        />
      </b-col> -->

      <b-col cols="12" class="p-0">
        <ChatNavbar
          @toggleSidebar="toggleSidebar"
          @toggleSearchSidebar="toggleSearchSidebar"
          @changeType="changeType"
        />

        <ChatSidebar
          ref="chat-sidebar"
          v-if="sidebarVisible"
          @toggleSidebar="toggleSidebar"
        />

        <ChatSearchSidebar
          ref="chat-search-sidebar"
          v-if="searchSidebarVisible"
          @toggleSearchSidebar="toggleSearchSidebar"
          @goToSelectedMessage="goToSelectedMessage"
          @closeSearchSidebar="closeSearchSidebar"
        />

        <ChatMessages
          ref="chat-messages"
          :files="send.files"
          @onAdditionalFileChange="onAdditionalFileChange"
          @onClickMessages="onClickMessages"
        />

        <ChatFooter
          ref="chat-footer"
          @onFileChange="onFileChange"
          @onNewMessage="onNewMessage"
        />
      </b-col>
    </b-row>
  </b-modal>
</template>
<script>
import { mapGetters, mapMutations, mapActions } from "vuex";
import ChatList from "@/views/commons/components/paragon-soft/components/binnacle/ChatList.vue";
import ChatNavbar from "@/views/commons/components/paragon-soft/components/binnacle/ChatNavbar.vue";
import ChatSidebar from "@/views/commons/components/paragon-soft/components/binnacle/ChatSidebar.vue";
import ChatSearchbar from "@/views/commons/components/paragon-soft/components/binnacle/ChatSearchBar.vue";
import ChatMessages from "@/views/commons/components/paragon-soft/components/binnacle/ChatMessages.vue";
import ChatFooter from "@/views/commons/components/paragon-soft/components/binnacle/ChatFooter.vue";
import MatchedMessagesList from "@/views/commons/components/paragon-soft/components/binnacle/MatchedMessagesList.vue";
import ChatSearchSidebar from "@/views/commons/components/paragon-soft/components/binnacle/ChatSearchSidebar.vue";
import Icon from "@/assets/images/icons/request-chat/good.vue";

export default {
  components: {
    ChatList,
    ChatNavbar,
    ChatSidebar,
    ChatSearchbar,
    ChatMessages,
    ChatFooter,
    MatchedMessagesList,
    ChatSearchSidebar,
    Icon,
  },
  data() {
    return {
      showModal: true,
      sidebarVisible: false,
      searchSidebarVisible: false,
      currentChatInfo: {},
      send: {
        message: "",
        files: [],
      },
      matchedMessages: null,
      modalColor: "bg-primary",
      modalTitle: "Soft Project Chats",
    };
  },
  async created() {
    this.A_GET_EMOJIS();
    this.initWebsocket();
  },

  computed: {
    ...mapGetters({
      currentUser: "auth/currentUser",
      G_CURRENT_CHAT_HEADER: "SoftProjectsBinnacleStore/G_CURRENT_CHAT_HEADER",
      G_CHATS: "SoftProjectsBinnacleStore/G_CHATS",
      G_CURRENT_CHAT_MEMBERS:
        "SoftProjectsBinnacleStore/G_CURRENT_CHAT_MEMBERS",
      G_CURRENT_CHAT_ID: "SoftProjectsBinnacleStore/G_CURRENT_CHAT_ID",
    }),
  },
  async mounted() {
    this.toggleModal("chat-modal");
    await this.getCurrentChatHeader();
  },

  methods: {
    async changeType() {
      this.getCurrentChatHeader();
    },
    async changeCurrentChat() {
      this.getCurrentChatHeader();
    },
    async getCurrentChatHeader() {
      try {
        await this.A_GET_CURRENT_CHAT_HEADER({
          binnacle_id: this.G_CURRENT_CHAT_ID,
          user_id: this.currentUser.user_id,
        });
        this.getModalColor(this.G_CURRENT_CHAT_HEADER.type);
        this.getModalTitle(this.G_CURRENT_CHAT_HEADER.type);
        await this.A_GET_TOTAL_MESSAGES({
          user_id: this.currentUser.user_id,
        });
      } catch (error) {
        this.showErrorSwal(error);
      }
    },
    ...mapActions({
      A_GET_EMOJIS: "SoftProjectsBinnacleStore/A_GET_EMOJIS",
      A_GET_CURRENT_CHAT_HEADER:
        "SoftProjectsBinnacleStore/A_GET_CURRENT_CHAT_HEADER",
      A_GET_CHAT_MEMBERS: "SoftProjectsBinnacleStore/A_GET_CHAT_MEMBERS",
      A_GET_MESSAGES: "SoftProjectsBinnacleStore/A_GET_MESSAGES",
      A_GET_TOTAL_MESSAGES: "SoftProjectsBinnacleStore/A_GET_TOTAL_MESSAGES",
    }),
    ...mapMutations({
      M_SET_CURRENT_CHAT_ID: "SoftProjectsBinnacleStore/M_SET_CURRENT_CHAT_ID",
    }),
    getModalColor(type) {
      if (type === "ceo") {
        this.modalColor = "bg-primary";
      } else if (type === "design") {
        this.modalColor = "bg-success";
      } else if (type === "development") {
        this.modalColor = "bg-warning";
      }
    },
    getModalTitle(type) {
      if (type === "ceo") {
        this.modalTitle = "Soft Project Chats - CEO";
      } else if (type === "design") {
        this.modalTitle = "Soft Project Chats - Design";
      } else if (type === "development") {
        this.modalTitle = "Soft Project Chats - Development";
      }
    },
    initWebsocket() {
      window.socket.unsubscribe("paragon-binnacle-chat");
      window.socket.subscribe("paragon-binnacle-chat");
    },
    clearSearch() {
      this.matchedMessages = null;
    },
    async goToSelectedMessage(message) {
      this.M_SET_CURRENT_CHAT_ID(message.binnacle_id);
      await this.A_GET_CURRENT_CHAT_HEADER({
        binnacle_id: message.binnacle_id,
        user_id: this.currentUser.user_id,
      });
      await this.A_GET_CHAT_MEMBERS({
        binnacle_id: message.binnacle_id,
      });
      this.getModalColor(message.type);
      this.getModalTitle(message.type);
      this.M_SET_CURRENT_CHAT_ID(message.binnacle_id);
      this.$refs["chat-messages"].goToSelectedMessage(message);
      this.$refs["chat-messages"].goToSelectedMessage(message);
    },
    searchMessage(matchedMessages) {
      this.matchedMessages = matchedMessages;
    },
    onClickMessages() {
      if (this.$refs["chat-footer"]) {
        this.$refs["chat-footer"].hideEmojis();
      }
      this.sidebarVisible = false;
      this.searchSidebarVisible = false;
    },
    onNewMessage() {
      this.send.files = [];
    },
    toggleSidebar() {
      this.sidebarVisible = !this.sidebarVisible;
    },
    closeSearchSidebar() {
      this.searchSidebarVisible = false;
    },
    toggleSearchSidebar() {
      this.searchSidebarVisible = !this.searchSidebarVisible;
    },
    onAdditionalFileChange(files) {
      this.send.files.push(files);
    },
    close() {
      this.$emit("hidden");
    },
    onFileChange(files) {
      this.send.files = files;
    },
  },
};
</script>

<style lang="scss" scoped>
.chat-modal-title {
  font-weight: 800;
  font-size: 1.3rem;
  color: #ffffff;
}
.request-chat-container {
  .title-messages {
    font-weight: 500;
    color: #0b213f;
  }

  .left-content {
    border-right: 2px solid #e5e5e5;
  }
}
.dark-layout {
  .request-chat-container {
    background-color: #040d19;

    .left-content {
      border-right: 2px solid #0b213f;
    }
  }
  .chat-modal-title {
    color: #0b213f;
  }
}
</style>