<template>
  <div>
    <b-modal
      @hidden="$emit('close')"
      v-model="show"
      :title="`${code} FILES`"
      title-class="text-white h3 font-weight-bold"
      hide-footer
      scrollable
    >
      <div
        class="d-flex justify-content-end my-1"
        v-if="[1, 17].includes(currentUser.role_id) && !readOnly"
      >
        <b-button
          variant="success"
          @click="showModalUploadFiles = true"
          class="text-black"
        >
          <span class="font-weight-bold">+ ADD FILES </span>
        </b-button>
      </div>
      <view-files
        :pg-request-id="pgRequestId"
        :key="viewKey"
        :read-only="readOnly"
        @refresh="refreshComponents"
      />
    </b-modal>

    <upload-files
      v-if="showModalUploadFiles"
      :pg-request-id="pgRequestId"
      @close="showModalUploadFiles = false"
      @refresh="refreshComponents"
    />
  </div>
</template>

<script>
import ViewFiles from "@/views/commons/components/paragon-soft/components/ViewFiles.vue";
import UploadFiles from "@/views/commons/components/paragon-soft/components/UploadFiles.vue";
import { mapGetters } from "vuex";
export default {
  components: {
    ViewFiles,
    UploadFiles,
  },
  props: {
    pgRequestId: {
      type: [String, Number],
      required: true,
    },
    code: {
      type: String,
      required: true,
    },

    readOnly:{
      type: Boolean,
      default: false
    }
  },

  computed: {
    ...mapGetters({
      currentUser: "auth/currentUser",
    }),
  },
  data() {
    return {
      show: true,
      showModalUploadFiles: false,
      viewKey: Math.random(),
    };
  },

  methods: {
    refreshComponents() {
      this.viewKey = Math.random();
      this.showModalUploadFiles = false;
    },
  },
};
</script>

<style>
</style>