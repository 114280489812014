<template>
  <vue-autosuggest
    ref="autocomplete"
    id="users"
    v-model="textVal"
    :suggestions="options"
    :get-suggestion-value="getSuggestionValue"
    :input-props="{
      id: 'autosuggest__input',
      class: ['form-control '],
      placeholder: 'Search participants',
    }"
    @input="onInputChange"
    @selected="selectHandler"
  >
    <template slot="before-input">
      <div class="lds-dual-ring" v-show="loadingAutosuggest"></div>
    </template>
    <template slot-scope="{ suggestion }">
      <span class="my-suggestion-item">{{ suggestion.item.name }}</span>
    </template>
  </vue-autosuggest>
</template>

<script>
import { VueAutosuggest } from "vue-autosuggest";
import BinnacleService from "@/views/commons/components/paragon-soft/services/PGBinnacle.service.js";
import { mapGetters } from "vuex";

export default {
  name: "SearchParticipants",
  components: {
    VueAutosuggest,
  },
  props: {
    text: {
      type: String,
      default: "",
    },
  },
  data() {
    return {
      user: "",
      options: [],
      loadingAutosuggest: false,
    };
  },
  computed: {
    textVal: {
      get() {
        return this.text;
      },
      set(val) {
        this.$emit("input", val);
      },
    },
    ...mapGetters({
      G_CURRENT_CHAT_MEMBERS:
        "SoftProjectsBinnacleStore/G_CURRENT_CHAT_MEMBERS",
    }),
  },

  methods: {
    getSuggestionValue(suggestion) {
      return suggestion.item.name;
    },
    async onInputChange(text) {
      this.loadingAutosuggest = true;
      if (text === "" || text === undefined) {
        this.options = [];
        this.loadingAutosuggest = false;
        return;
      }
      this.loadingAutosuggest = false;
      try {
        const response = await BinnacleService.searchMember({
          text,
        });

        const filteredResponse = response.filter(
          (user) =>
            !this.G_CURRENT_CHAT_MEMBERS.some(
              (participant) => participant.user_id === user.id  
            )
        );
        this.options = [{ data: [...filteredResponse] }]; 
      } catch (error) {
        console.log(error);
      }
    },
    selectHandler(user) {
      if (user === undefined || user === null) {
        return;
      }

      let userSelected = {
        name: user.item.name,
        id: user.item.id,
      };
      this.text = "";
      this.$emit("userSelected", userSelected);
    },
  },
};
</script>

  <style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
@import "@core/scss/vue/libs/quill.scss";
@import "@core/scss/vue/libs/vue-autosuggest.scss";
.compose-mail-form-field.subject {
  span {
    display: flex;
    align-items: center;
    justify-content: space-around;
    width: 100%;
    #autosuggest {
      width: 100% !important;
      .autosuggest__results-container {
        span {
          justify-content: start;
        }
      }
    }
  }
}
.compose-mail-form-field.to {
  span {
    display: flex;
    align-items: center;
    justify-content: space-around;
    width: 100%;
    .v-select {
      width: 100% !important;
      .vs__selected-options {
        span {
          justify-content: start;
        }
      }
      .vs__selected {
        width: auto;
      }
      .vs__dropdown-menu {
        li {
          display: flex;
          justify-content: start;
          align-items: center;
        }
        span {
          justify-content: start;
        }
      }
    }
  }
}
</style>

<style lang="scss" scoped>
form ::v-deep {
  // Mail To vue-select style
  .v-select {
    .vs__dropdown-toggle {
      border: 0;
      box-shadow: none;
      background: transparent !important;
    }
    .vs__open-indicator {
      display: none;
    }
  }

  // Quill Editor Style
  .quill-editor {
    .ql-container.ql-snow {
      border-bottom: 0 !important;
    }
  }
}
</style>