<template>
  <b-sidebar
    id="sidebar-payroll"
    v-model="ownControl"
    right
    bg-variant="white"
    header-class="text-primary text-center"
    shadow
    width="100%"
    @hidden="closeModal"
  >
    <template #header="{hide}">
      <div
        class="d-flex justify-content-between mt-2"
        style="width: 100%; height: 100%; border-bottom: 1px solid #007bff; "
      >
        <span class="text-primary k-font-weight-bold">{{ title }}</span>
        <feather-icon
          class="text-primary cursor-pointer"
          icon="XIcon"
          size="18"
          @click="hide"
        />
      </div>
    </template>
    <template #default>
      <b-card
        v-if="!isCancelDone"
        class="my-1 mx-1"
        body-class="card2"
      >
        <div class="d-flex justify-content-between align-items-center">
          <amg-icon
            title="Generate Excel"
            class="cursor-pointer text-success"
            icon="FileExcelIcon"
            size="18"
            @click="generateExcel(payrollItem.id)"
          />
          <div class="d-flex align-items-center">
            <span class="font-medium-3 font-weight-bolder">$ Exchange Rate: &nbsp; &nbsp;  </span>
            <template v-if="initialInput">
              <b-form-group class="mb-0 d-inline">
                <b-form-radio
                  v-model="exchangeUsed"
                  class="d-inline mr-1"
                  :value="exchangeRateApi"
                  @change="backExchangeRate"
                ><h4 class="d-inline">
                  <b-badge class="py-1">
                    S/. {{ exchangeRateApi ? Number(exchangeRateApi).toFixed(3) : '0.000' }}
                  </b-badge>
                </h4>
                </b-form-radio>
                <b-form-radio
                  v-model="exchangeUsed"
                  class="d-inline"
                  :value="exchangeRateManual"
                  @change="backExchangeRate"
                >
                  <h4 class="d-inline">
                    <b-badge
                      class="py-1"
                      variant="primary"
                    >
                      S/. {{ exchangeRateManual }}
                    </b-badge>
                  </h4>
                </b-form-radio>
              </b-form-group>
            </template>
            <h4
              v-else
              class="d-inline"
            >
              <b-badge class="py-1">
                S/. {{ exchangeRateApi }}
              </b-badge>
            </h4>
            <money
              v-if="exManual"
              v-model="exchangeRateProv"
              style="padding-top: 0.2rem; padding-bottom: 0.30rem; padding-left: 0.5rem; width: 75px"
              class="ml-1 form-control"
              v-bind="{
                decimal: '.',
                thousands: ',',
                prefix: 'S/.',
                precision: 3,
                masked: false,
              }"
            />
            <b-button
              v-if="statusPayroll === 1 && !exManual"
              size="sm"
              variant="outline-primary"
              class="cursor-pointer ml-1 pad"
              @click="editExchangeDolar"
            >
              <tabler-icon
                icon="EditIcon"
              />
            </b-button>
            <template v-if="exManual">
              <b-button
                size="sm"
                variant="success"
                class="ml-1 pad"
                @click="saveExchangeDolar"
              >
                <tabler-icon
                  icon="ChecksIcon"
                />
              </b-button>
              <b-button
                size="sm"
                variant="danger"
                class="ml-1 pad cursor-pointer"
                @click="exManual = false"
              >
                <tabler-icon
                  icon="PowerIcon"
                />
              </b-button>
            </template>
          </div>
        </div>
      </b-card>

      <div
        class="accordion px-1 mt-1"
        role="tablist"
      >
        <b-card
          v-for="(department, index) in departments"
          :key="department.id"
          no-body
          class="m-0 card2"
          header-class="card2"
        >
          <b-card-header
            :ref="index"
            header-tag="div"
            class="pad-b-t "
            role="tab"
          >
            <b-button
              v-b-toggle="department.id"
              block
              :variant="getVariant(department)"
              class="text-left"
              :class="getVariant(department)"
              @click="getEmployeesByDepartment(department.id)"
            >
              {{ department.name }}
            </b-button>
          </b-card-header>
          <b-collapse
            :id="department.id"
            accordion="my-accordion"
            role="tabpanel"
          >
            <b-card-body class="pt-0">
              <b-card-text class="pb-1 px-1 pt-0">
                <b-table
                  ref="employeesTable"
                  small
                  responsive
                  thead-tr-class="fs"
                  :busy="isBusy"
                  :items="employees"
                  :fields="employeesFields"
                  class="font-small-2"
                  show-empty
                >
                  <template #table-busy>
                    <div class="text-center text-primary my-2">
                      <b-spinner class="align-middle mr-1" />
                      <strong>Loading ...</strong>
                    </div>
                  </template>
                  <template #cell(currency)="data">
                    {{ data.item.currency ===1? '$':'S/.' }}
                  </template>
                  <template #cell(exchange)="data">
                    {{ Number(data.item.exchange).toFixed(3) }}
                  </template>
                  <template #cell(type_agreement)="data">
                    <span v-if="data.item.type_agreement===1">
                      <feather-icon
                        class="text-primary"
                        icon="ClockIcon"
                      /> Hour
                    </span>
                    <span v-else>
                      <feather-icon
                        class="text-primary"
                        icon="CheckSquareIcon"
                      /> Full
                    </span>
                  </template>
                  <template #cell(total_aditional)="data">
                    <tabler-icon
                      class="cursor-pointer calculator-icon"
                      :class="{'text-primary': Number(data.item.total_aditional) > 0}"
                      icon="CalculatorIcon"
                      @click="openRegularPayRollModal(data.item)"
                    /> <span class="k-font-weight-bold">$ {{ Number(data.item.total_aditional)? Number(data.item.total_aditional).toFixed(2) : 0.00 }}</span>
                  </template>
                  <template #cell(total_disccount)="data">
                    <tabler-icon
                      class="cursor-pointer calculator-icon"
                      :class="{'text-danger': Number(data.item.total_disccount) > 0 || data.item.disccount_pay}"
                      icon="CalculatorIcon"
                      @click="openDiscountsPayRollModal(data.item)"
                    /> <span class="k-font-weight-bold">$ {{ Number(data.item.total_disccount)? Number(data.item.total_disccount).toFixed(2) : 0.00 }}</span>
                  </template>
                  <template #cell(pay_net)="data">
                    <tabler-icon
                      class="cursor-pointer calculator-icon"
                      :class="{'text-success': Number(data.item.pay_net) > 0, 'not-cursor' : (!Number(data.item.total_disccount) && !data.item.disccount_pay )|| (!Number(data.item.total_aditional) )}"
                      icon="CreditCardIcon"
                      @click="openNetPaymentsModalModal(data.item)"
                    /> <span class="k-font-weight-bold">
                      $ {{ Number(data.item.pay_net) > 0? Number(data.item.pay_net) : (Number(data.item.total_aditional).toFixed(2) - Number(data.item.total_disccount)).toFixed(2) }}</span>
                  </template>
                  <template #cell(status)="data">
                    <b-badge
                      v-if="data.item.status === 1"
                      variant="danger"
                    >
                      <span class="font-small-2">PENDING</span>
                    </b-badge>
                    <b-badge
                      v-else
                      variant="success"
                    >
                      <span class="font-small-2">DONE</span>
                    </b-badge>
                  </template>
                  <template #cell(actions)="data">
                    <b-button
                      v-if="data.item.status === 1"
                      size="sm"
                      :variant="Number(data.item.pay_net) > 0? 'success' : 'secondary' "
                      :class="Number(data.item.pay_net) > 0? 'cursor-pointer':'not-cursor'"
                      :disabled="Number(data.item.pay_net) === 0"
                      class="pad-done-button"
                      @click="doneEmployee(data.item.id, department.id)"
                    >
                      <tabler-icon
                        icon="ChecksIcon"
                      /> <span class="font-small-1">DONE</span>
                    </b-button>
                    <b-button
                      v-else
                      size="sm"
                      variant="success"
                      title="The employee is done"
                      class="btn-icon"
                      disabled
                    >
                      <tabler-icon
                        icon="ChecksIcon"
                      />
                    </b-button>
                    <!--                    </div>-->
                  </template>
                  <template #cell(pdf_amg)="data">
                    <a
                      v-if="data.item.pdf"
                      :href="data.item.pdf"
                      target="_blank"
                      class="cursor-pointer text-danger"
                    >
                      <amg-icon
                        icon="FilePdfIcon"
                        class="calculator-icon"
                      />
                    </a>
                    <span v-else>
                      -
                    </span>
                  </template>
                  <template #custom-foot>
                    <td :class="isDarkSkin? 'bg-category-modal' : 'bg-secondary'" />
                    <td :class="isDarkSkin? 'bg-category-modal' : 'bg-secondary'" />
                    <td :class="isDarkSkin? 'bg-category-modal' : 'bg-secondary'" />
                    <td :class="isDarkSkin? 'bg-category-modal' : 'bg-secondary'" />
                    <td :class="isDarkSkin? 'bg-category-modal' : 'bg-secondary'" />
                    <td
                      colspan="1"
                      :class="isDarkSkin? 'bg-category-modal' : 'bg-secondary'"
                      class="font-weight-bolder text-white align-middle vertical-align"
                    >
                      Total:
                    </td>
                    <td
                      colspan="1"
                      :class="isDarkSkin? 'bg-category-modal' : 'bg-secondary'"
                      class="text-white align-middle vertical-align"
                    >
                      {{ totalHoursByDepartment }}
                    </td>
                    <td
                      colspan="1"
                      :class="isDarkSkin? 'bg-category-modal' : 'bg-secondary'"
                      class="text-white vertical-align"
                    >
                      <b-badge
                        style="margin-left: 0.3rem"
                        variant="light"
                        :class="isDarkSkin? 'text-black' : 'text-dark'"
                        class="align-middle vertical-align"
                      >
                        $
                      </b-badge> &nbsp; &nbsp; {{ totalAditionalByDepartment }}</td>
                    <td
                      colspan="1"
                      :class="isDarkSkin? 'bg-category-modal' : 'bg-secondary'"
                      class="text-white align-middle vertical-align"
                    >
                      <b-badge
                        style="margin-left: 0.3rem"
                        variant="light"
                        :class="isDarkSkin? 'text-black' : 'text-dark'"
                      >
                        $
                      </b-badge> &nbsp; &nbsp; {{ totalDiscountsByDepartment }}
                    </td>
                    <td
                      :class="isDarkSkin? 'bg-category-modal' : 'bg-secondary'"
                      colspan="1"
                      class="text-white align-middle vertical-align"
                    >
                      <b-badge
                        style="margin-left: 0.3rem"
                        variant="light"
                        :class="isDarkSkin? 'text-black' : 'text-dark'"
                      >
                        $
                      </b-badge> &nbsp; &nbsp; {{ totalNetByDepartment }}
                    </td>
                    <td :class="isDarkSkin? 'bg-category-modal' : 'bg-secondary'" />
                    <td :class="isDarkSkin? 'bg-category-modal' : 'bg-secondary'">
                      <b-button
                        v-if="department.status_department >0"
                        size="sm"
                        :class="department.cc === 0? 'cursor-pointer':'not-cursor'"
                        :variant="department.cc === 0? 'gradient-success' : 'light'"
                        :disabled="department.cc > 0"
                        @click="doneDepartment(department.id)"
                      >
                        <tabler-icon icon="ChecksIcon" /> DONE
                      </b-button>
                      <b-button
                        v-else
                        size="sm"
                        variant="success"
                        title="The employee is done"
                        disabled
                      >
                        <tabler-icon icon="ChecksIcon" />
                      </b-button>
                    </td>
                    <td :class="isDarkSkin? 'bg-category-modal' : 'bg-secondary'" />
                  </template>
                </b-table>
              </b-card-text>
            </b-card-body>
          </b-collapse>
        </b-card>
        <pay-roll-regular-modal
          v-if="openRegularPayRollController"
          :title-inside-modal="titleInsideModal"
          :cancel-done="isCancelDone"
          :employee="employeeToSend"
          :is-done-employee="isDoneEmployee"
          :by-hour="byHour"
          :hours="hours"
          :ex-rate="exRate"
          :salary$="salary$"
          :salary-s="salaryS"
          @close="closeRegularPayRollModal"
          :salary-hour="salaryHour"
          @reload="getEmployeesByDepartment(currentDepartment)"
        />
        <payroll-discounts-modal
          v-if="openDiscountsPayRollController"
          :title-inside-modal="titleInsideModal"
          :cancel-done="isCancelDone"
          :employee="employeeToSend"
          :is-done-employee="isDoneEmployee"
          :by-hour="byHour"
          :hours="hours"
          :ex-rate="exRate"
          :salary$="salary$"
          :salary-s="salaryS"
          @close="closeDiscountsPayRollModal"
          :salary-hour="salaryHour"
          @reload="getEmployeesByDepartment(currentDepartment)"
        />
        <net-payments-modal
          v-if="openNetPaymentsModalController"
          :title-inside-modal="titleInsideModal"
          :cancel-done="isCancelDone"
          :employee="employeeToSend"
          :is-done-employee="isDoneEmployee"
          :by-hour="byHour"
          :hours="hours"
          :ex-rate="exRate"
          :salary$="salary$"
          :salary-s="salaryS"
          @close="closeNetPaymentsModalModal"
          :salary-hour="salaryHour"
          @reload="getEmployeesByDepartment(currentDepartment)"
        />
      </div>
    </template>
  </b-sidebar>
</template>

<script>
import PayrollService from '@/views/management/views/payroll/payroll.service'
import PayRollRegularModal from '@/views/management/views/payroll/payroll-module/modals/PayRollRegularModal'
import payrollDiscountsModal from '@/views/management/views/payroll/payroll-module/modals/PayrollDiscountsModal'
import NetPaymentsModal from '@/views/management/views/payroll/payroll-module/modals/NetPaymentsModal'
import PayrollSidebarTableFields from '@/views/management/views/payroll/payroll-module/sidebars/payroll.sidebar.table.fields'
import { mapGetters } from 'vuex'

export default {
  components: {
    PayRollRegularModal,
    payrollDiscountsModal,
    NetPaymentsModal,
  },
  props: {
    title: '',
    payrollItem: null,
  },
  data() {
    return {
      ownControl: false,
      statusPayroll: '',
      openNetPaymentsModalController: false,
      departments: [],
      exchangeUsed: null,
      exchangeRateApi: 0,
      initialInput: false,
      isBusy: false,
      items: [],
      exManual: false,
      exchangeRateProv: 0,
      exchangeRateManual: 0,
      employeesFields: PayrollSidebarTableFields,
      employees: [],
      openRegularPayRollController: false,
      openDiscountsPayRollController: false,
      titleInsideModal: '',
      employeeToSend: [],
      isDoneEmployee: false,
      byHour: '',
      exRate: '',
      salary$: '',
      salaryS: '',
      hours: '',
      salaryHour: '',
      currentDepartment: '',
      replace: 90,
    }
  },
  async mounted() {
    this.ownControl = true
    this.statusPayroll = this.payrollItem.status
  },
  async created() {
    await this.getDepartmentsPayrolls()
  },
  methods: {
    closeModal() {
      this.$emit('reload')
      this.$emit('close')
    },
    propsProcess(employee) {
      this.hours = employee.hours
      this.exRate = Number(employee.exchange).toFixed(3)

      if (employee.currency === 2) {
        this.salaryS = employee.salary
        this.salary$ = employee.salary / this.exRate
        this.salary$ = Number(this.salary$).toFixed(2)
      } else {
        this.salary$ = employee.salary
        this.salaryS = '-'
      }
      this.byHour = this.salary$ / employee.hours
      this.byHour = Number(this.byHour).toFixed(2)
      this.salaryHour = this.byHour
    },
    async doneEmployee(employeeId, departmentId) {
      try {
        const response = await this.showConfirmSwal()
        if (response.isConfirmed) {
          this.addPreloader()
          const data = await PayrollService.doneEmployee({
            id: employeeId,
            user: this.currentUser.user_id,
          })
          if (data.status === 200) {
            this.showSuccessSwal('Employee was paid successfully')
            this.departments.forEach(department => {
              if (department.id === departmentId) {
                department.cc -= 1
              }
            })

            this.employees.forEach(employee => {
              if (employee.id === employeeId) {
                employee.status = 2
                employee.pdf = response.data
              }
            })
            this.getEmployeesByDepartment(this.currentDepartment)
          }
          this.removePreloader()
        }
      } catch (e) {
        this.removePreloader()
        this.showErrorSwal(e)
      }
    },
    async doneDepartment(id) {
      try {
        const response = await this.showConfirmSwal()
        if (response.isConfirmed) {
          this.addPreloader()
          const data = await PayrollService.doneDepartment({
            id_department: id,
            id_payroll: this.payrollItem.id,
            user: this.currentUser.user_id,
          })
          if (data.status === 200) {
            this.showSuccessSwal('The department was paid successfully')
            this.departments.forEach(department => {
              if (department.id === id) {
                department.status_department = 0
              }
            })
            this.removePreloader()
          }
        }
      } catch (e) {
        this.removePreloader()
        this.showErrorSwal(e)
      }
    },
    openNetPaymentsModalModal(employee) {
      if (!((!Number(employee.total_disccount) && !employee.disccount_pay) || (!Number(employee.total_aditional)))) {
        if (employee.status == 2) {
          this.isDoneEmployee = true
        }
        this.propsProcess(employee)
        this.openNetPaymentsModalController = true
        const type = employee.type_agreement === 1 ? 'By Hour' : 'Full'
        this.employeeToSend = employee
        this.titleInsideModal = `NET PAYMENTS (${employee.full_name}) - ${type}`
      }
    },
    closeNetPaymentsModalModal() {
      this.isDoneEmployee = false
      this.openNetPaymentsModalController = false
    },
    openRegularPayRollModal(employee) {
      if (employee.exchange == 0) {
        this.showWarningSwal("The Exchange Rate can't be 0", 'Please edit the exchange rate')
      } else {
        if (employee.status == 2) {
          this.isDoneEmployee = true
        }
        this.propsProcess(employee)
        this.openRegularPayRollController = true
        this.employeeToSend = employee
        const type = employee.type_agreement === 1 ? 'By Hour' : 'Full'
        this.titleInsideModal = `REGULAR-ADDITIONAL PAYMENTS (${employee.full_name}) - ${type}`
      }
    },
    closeRegularPayRollModal() {
      this.isDoneEmployee = false
      this.openRegularPayRollController = false
    },
    openDiscountsPayRollModal(employee) {
      if (employee.exchange == 0) {
        this.showWarningSwal("The Exchange Rate can't be 0", 'Please edit the exchange rate')
      } else {
        this.propsProcess(employee)
        this.openDiscountsPayRollController = true
        this.employeeToSend = employee
        const type = employee.type_agreement === 1 ? 'By Hour' : 'Full'
        this.titleInsideModal = `PAYMENTS DISCOUNTS (${employee.full_name}) - ${type}`
        if (employee.status == 2) {
          this.isDoneEmployee = true
        }
      }
    },
    closeDiscountsPayRollModal() {
      this.isDoneEmployee = false
      this.openDiscountsPayRollController = false
    },
    setExchangeRate() {
      this.employees.forEach(employee => {
        if (
          Number(employee.total_aditional) == 0
            && Number(employee.total_disccount) == 0
        ) {
          employee.exchange = this.exchangeUsed
        }
      })
    },
    async editExchangeDolar() {
      const response = await this.showConfirmSwal('Are you sure? ', 'You are going to change $')
      if (response.isConfirmed) {
        this.exManual = true
      }
    },
    async saveExchangeDolar() {
      const response = await this.showConfirmSwal('Are you sure? ', 'You are going to change $')
      if (response.isConfirmed) {
        this.exchangeRateManual = this.exchangeRateProv
        this.exchangeUsed = this.exchangeRateManual
        const data = await PayrollService.updateDollarExchangePayroll({
          dolar_external: this.exchangeRateManual,
          id_payroll: this.payrollItem.id,
        })
        if (data.status === 200) {
          this.showToast('success', 'top-right', 'Congratulations', 'CheckIcon', 'Exchange rate updated successfully')
        }
        this.initialInput = true
        this.exManual = false
        this.setExchangeRate()
      }
    },
    async backExchangeRate() {
      this.setExchangeRate()
    },
    async getDepartmentsPayrolls() {
      try {
        this.addPreloader()
        const data = await PayrollService.getDepartmentsPayrolls({
          paryroll_id: this.payrollItem.id,
        })
        if (data.status === 200) {
          this.removePreloader()
          this.exchangeUsed = data.data[0].apiDolar
            ? data.data[0].apiDolar
            : 0
          this.exchangeRateApi = data.data[0].apiDolar
            ? Number(data.data[0].apiDolar)
            : 0
          if (data.data[0].dolar_exchange != 0) {
            this.exchangeRateManual = data.data[0].dolar_exchange ? Number(data.data[0].dolar_exchange).toFixed(3) : 0
            this.exchangeRateProv = this.exchangeRateManual
            this.initialInput = true
            this.exchangeUsed = this.exchangeRateManual
          }
          this.departments = data.data
        }
      } catch (e) {
        this.removePreloader()
        this.showErrorSwal(e)
      }
    },
    async getEmployeesByDepartment(departmentId) {
      try {
        this.employees = []
        this.currentDepartment = departmentId
        this.isBusy = true
        const data = await PayrollService.getEmployeesDepartmentsPayrolls({
          paryroll_id: this.payrollItem.id,
          department_id: departmentId,
        })
        if (data.status === 200) {
          this.isBusy = false
          this.employees = data.data
          this.setExchangeRate()
        }
      } catch (e) {
        this.showErrorSwal(e)
      }
    },
    async generateExcel(id) {
      try {
        const response = await this.showConfirmSwal('Are you sure?', 'Excel will be generated!')
        if (response.isConfirmed) {
          const data = await PayrollService.generateExcel(id)
          await this.forceFileDownload(data, 'PayRoll.xlsx')
        }
      } catch (error) {
        this.showErrorSwal(error)
      }
    },
    getVariant(department) {
      // eslint-disable-next-line no-nested-ternary
      if (this.isDarkSkin) return department.status_department === 0 ? 'dark-success' : department.cc === 0 ? 'dark-outline-success' : 'outline-danger'
      return department.status_department === 0 ? 'success' : department.cc === 0 ? 'outline-success' : 'outline-danger'
    },
  },
  computed: {
    isCancelDone() {
      return this.statusPayroll === 2 || this.statusPayroll === 3
    },
    totalHoursByDepartment() {
      return this.employees.reduce(
        (sum, employee) => sum + Number(employee.hours),
        0,
      )
    },
    totalAditionalByDepartment() {
      const total = this.employees.reduce(
        (sum, employee) => sum + Number(employee.total_aditional),
        0,
      )
      return total.toFixed(2)
    },
    ...mapGetters({
      currentUser: 'auth/currentUser',
    }),
    totalDiscountsByDepartment() {
      const total = this.employees.reduce(
        (sum, employee) => sum + Number(employee.total_disccount),
        0,
      )
      return total.toFixed(2)
    },
    totalNetByDepartment() {
      const total = this.employees.reduce(
        (sum, employee) => sum + Number(employee.pay_net), 0,
      )
      return total.toFixed(2)
    },
  },
}
</script>

<style scoped>
.bigger{
  width: 2rem;
  height: 2rem;
}
.pad{
  padding: 0.5rem !important;
}
.pad-b-t{
  padding-bottom: 1rem !important;
  padding-top: 1rem !important;
}
.calculator-icon{
  width: 2rem;
  height: 2rem;
}
.fs{
  font-size: 5px;
}
.not-cursor {
  cursor: not-allowed !important;
}
.pad-done-button{
  padding: 0.3rem !important;
}
.vertical-align{
  vertical-align: middle !important;
}
.text-black {
  color: black !important;
}
</style>
<style>
.card2{
  box-shadow: 4px 4px 24px 4px rgb(34,41,47, 0.1) !important;
}
.dark-layout .card2 {
  box-shadow: 0 1px 3px 0 rgb(255 255 255 / 0.1), 0 1px 2px -1px rgb(255 255 255 / 0.1) !important;
}
.dark-success{
  background-color: #314c3d !important;
  border-color: #314c3d  !important;
  color: white;
}
.dark-outline-success{
  border-color: #314c3d !important;
  color: #189f4e;
}
.text-dark-success{
  color: #314c3d  !important;
}
</style>
