import { amgApi } from "@/service/axios"

class TeamsService {



  async getAll(type) {
    try {
      const data = await amgApi.get(
        `/paragon/settings/teams/index/${type}`
      )
      return data
    } catch (error) {
      throw error
    }
  }

  async assignTeamToRequest(params) {
    try {
      const data = await amgApi.post(
        "/paragon/settings/request-team/store"
        , params)
      return data
    } catch (error) {
      throw error
    }
  }


  async getTracking(id,type) {
    try {
      const data = await amgApi.get(
        `/paragon/settings/request-team/show/${id}/${type}`)
      return data
    } catch (error) {
      throw error
    }
  }

  async assignDesignToRequest(params) {
    try {
      const data = await amgApi.post(
        "/paragon/settings/request-design/update"
        , params)
      return data
    } catch (error) {
      throw error
    }
  }
}
export default new TeamsService()
