<template>
  <div>
    <b-modal
      ref="modal-assign-difficulty-request"
      size="sm"
      @hidden="$emit('hidden')"
      no-close-on-backdrop
    >
      <template #modal-title>
        <span class="text-uppercase font-weight-bolder"
          >Assign Difficulty: {{ requestCode }}</span
        >
      </template>
      <template #default>
        <validation-observer ref="form">
          <b-row>
            <b-col cols="12">
              <validation-provider v-slot="{ errors }" rules="required">
                <b-form-group label="Difficulty">
                  <v-select
                    id="difficultySelect"
                    v-model="difficultyId"
                    :style="
                      errors[0]
                        ? 'border: 1px solid red !important; border-radius: 5px !important;'
                        : ''
                    "
                    label="name"
                    :options="allDifficulty"
                    :reduce="(option) => option.id"
                    placeholder="Select difficulty"
                  />
                </b-form-group>
              </validation-provider>
            </b-col>
          </b-row>
        </validation-observer>
      </template>
      <template #modal-footer>
        <b-row class="d-flex justify-content-end">
          <b-button
            id="buttonAsignDiff"
            variant="primary"
            @click="AssignDifficulty()"
          >
            SAVE
          </b-button>
        </b-row>
      </template>
    </b-modal>
  </div>
</template>
    <script>
import RequestService from "@/views/commons/components/paragon-soft/services/PGRequest.services.js";
export default {
  props: {
    requestCode: {
      type: String,
      required: true,
    },
    requestId: {
      type: Number,
      required: true,
    },
  },
  data() {
    return {
      allDifficulty: [
        {
          id: 1,
          name: "LOW",
        },
        {
          id: 2,
          name: "MEDIUM",
        },
        {
          id: 3,
          name: "HIGH",
        },
      ],
      difficultyId: null,
    };
  },
  mounted() {
    this.toggleModal("modal-assign-difficulty-request");
    this.getRequestById();
  },
  computed: {},
  methods: {
    async getRequestById() {
      try {
        this.addPreloader();
        const { data } = await RequestService.getRequestById(this.requestId);
        this.difficultyId = data.difficulty_id;
      } catch (error) {
        this.showErrorSwal(error);
      } finally {
        this.removePreloader();
      }
    },
    async AssignDifficulty() {
      try {
        const isValid = await this.$refs.form.validate();
        if (!isValid) return;
        this.addPreloader();
        const params = {
          id: this.requestId,
          difficulty: this.difficultyId,
        };
        const { data } = await RequestService.assignDifficultyRequestById(
          params
        );
        this.showSuccessSwal(data.message);
        this.$emit("refresh");
      } catch (error) {
        this.showErrorSwal(error);
      } finally {
        this.removePreloader();
      }
    },
  },
};
</script>