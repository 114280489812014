<template>
  <b-modal
    v-model="isShow"
    ref="modalFigmaPrototype"
    modal-class="modal-danger" 
    title-class="h3 text-white" 
    :title="`Figma Prototype: ${requestCode}`"
    size="xl"
    hide-footer
    @hidden="closeModal()"
    centered
    no-close-on-backdrop
  >
    <div style="height:80vh">
              <iframe style="border: 1px solid rgba(0, 0, 0, 0.1);" 
                width="100%"
                height="100%" 
                :src="link" 
                allowfullscreen
        >
        </iframe>
    </div> 
  </b-modal>
</template>
<script>
import { mapGetters } from "vuex";
import ParagonSettingsService from "@/views/paragon/views/settings/service/paragon.service.js";
export default { 
  props:{
    link:{
      type: String,
      required: true,
    },
    requestCode: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      isShow:false,     
    };
  },
  computed: {
    ...mapGetters({
      currentUser: "auth/currentUser",
    }),
  },
  mounted() {
    
  },
  async created() { 
    this.isShow = true; 
  },
  methods: {
    closeModal() {
      this.$emit("close");
    }, 
    rejectedPrototype(){

    }
  },
};
</script>