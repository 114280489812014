<template>
  <div class="chat-sidebar">
    <b-row class="p-0 m-0">
      <b-col cols="12">
        <div class="d-flex align-items-center justify-content-between py-2">
          <span class="sidebar-title"> Photos and Files </span>
          <b-button variant="transparent" @click="toggleSidebar" class="p-0">
            <feather-icon icon="XIcon" size="20" class="text-primary" />
          </b-button>
        </div>
      </b-col> 

      <b-row class="w-100">
        <perfect-scrollbar class="custom-scrollbar-files">
          <b-col cols="12">
            <div class="d-flex align-items-center justify-content-between px-2">
              <span class="sidebar-left-text"
                >Photos <span> ({{ photos.length }})</span></span
              >
              <span
                class="sidebar-right-text text-primary cursor-pointer"
                v-if="photos.length > 0"
                @click="openChatDetail('photos')"
                >See all</span
              >
            </div>

            <b-container>
              <b-row>
                <template v-for="(image, index) in photos">
                  <b-col
                    v-if="index <= 3"
                    :key="index"
                    md="6"
                    lg="6"
                    class="p-2"
                  >
                    <b-img
                      @click="previewImage(image.route)"
                      class="sidebar-image"
                      left
                      :src="image.route"
                      alt="Image"
                      width="250"
                      height="250"
                    ></b-img>
                  </b-col>
                </template>
              </b-row>
            </b-container>
          </b-col>

          <b-col cols="12" :class="photos.length == 0 ? 'mt-1' : ''">
            <div
              class="d-flex align-items-center justify-content-between px-2 mb-1"
            >
              <span class="sidebar-left-text"
                >Files <span> ({{ files.length }})</span></span
              >
              <span
                class="sidebar-right-text text-primary cursor-pointer"
                v-if="files.length > 0"
                @click="openChatDetail('files')"
                >See all</span
              >
            </div>

            <b-container>
              <div v-for="(file, index) in files" :key="index">
                <div
                  @click="forceDownload(file.route, file.filename)"
                  v-if="index <= 5"
                  class="d-flex align-items-center justify-content-start px-2 py-1 bg-file"
                >
                  <div class="mr-2">
                    <feather-icon
                      v-if="file.extension == 'mp3'"
                      icon="MusicIcon"
                      size="30"
                    />
                    <feather-icon v-else icon="FileIcon" size="30" />
                  </div>
                  <div class="d-flex flex-column">
                    <span class="sidebar-left-text">{{ file.filename }}</span>
                    <span class="sidebar-right-text"
                      >{{ file.size }} -
                      {{ file.created_at | myGlobalWithHour }}</span
                    >
                  </div>
                </div>
              </div>
            </b-container>
          </b-col>

          <b-col cols="12">
            <div
              class="d-flex align-items-center justify-content-between px-2 mb-1"
            >
              <span class="sidebar-left-text"
                >Members
                <span> ({{ G_CURRENT_CHAT_MEMBERS.length }})</span></span
              >
              <span
                class="sidebar-right-text text-primary cursor-pointer"
                v-if="files.length > 0"
                @click="openChatDetail('members')"
                >See all</span
              >
            </div>

            <b-container>
              <div
                v-for="(participant, index) in G_CURRENT_CHAT_MEMBERS"
                :key="index"
              >
                <div
                  class="d-flex align-items-center justify-content-between px-2 py-1 bg-file"
                >
                  <div>
                    <b-avatar
                      :key="index"
                      :src="`${participant.user_photo}`"
                      size="3.5rem"
                      class="mr-2"
                      @img-error="handleImageError($event, index)"
                    ></b-avatar>
                    <span class="sidebar-left-text">{{
                      participant.user
                    }}</span>
                  </div>
                  <div v-if="isChief || isCeo">
                    <feather-icon
                      @click="removeMember(participant)"
                      icon="TrashIcon"
                      size="20"
                      class="cursor-pointer text-danger"
                    />
                  </div>
                </div>
              </div>
            </b-container>
          </b-col>
        </perfect-scrollbar>
      </b-row>
    </b-row>

    <ImageViewer
      v-if="showImageViewer"
      :images="currentFileRoute"
      @hidden="showImageViewer = false"
    />
    <ChatAllFiles
      v-if="showAllFiles"
      @close="showAllFiles = false"
      :type="showAllFilesType"
      :binnacleId="G_CURRENT_CHAT_ID"
    />
  </div>
</template>
<script>
import PerfectScrollbar from "vue-perfect-scrollbar";
import BinnacleService from "@/views/commons/components/paragon-soft/services/PGBinnacle.service.js";
import ImageViewer from "@/commons/ImagePreview.vue";
import ChatAllFiles from "@/views/commons/components/paragon-soft/components/binnacle/ChatAllfiles.vue";
import { mapGetters } from "vuex";

export default {
  name: "ChatSidebar",
  components: {
    PerfectScrollbar,
    ImageViewer,
    ChatAllFiles,
  },
  data() {
    return {
      photos: [],
      files: [],
      showImageViewer: false,
      showAllFiles: false,
      showAllFilesType: "",
      currentFileRoute: "",
    };
  },
  created() {
    this.getBinnacleFiles();
  },
  computed: {
    ...mapGetters({
      currentUser: "auth/currentUser",
      G_CURRENT_CHAT_ID: "SoftProjectsBinnacleStore/G_CURRENT_CHAT_ID",
      G_CURRENT_CHAT_MEMBERS:
        "SoftProjectsBinnacleStore/G_CURRENT_CHAT_MEMBERS",
    }),
  },

  methods: {
    handleImageError(event, index) {
      this.G_CURRENT_CHAT_MEMBERS[
        index
      ].user_photo = require("@/assets/images/logo/amg_new_logo.svg");
    },
    openChatDetail(type) {
      this.showAllFiles = true;
      this.showAllFilesType = type;
    },
    forceDownload(url, filename) {
      const link = document.createElement("a");
      link.href = url;
      link.target = "_blank";
      link.setAttribute("download", filename);
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    },
    toggleSidebar() {
      this.$emit("toggleSidebar");
    },
    async getBinnacleFiles() {
      try {
        this.addPreloader();
        const params = {
          binnacle_id: this.G_CURRENT_CHAT_ID,
          limit: 4,
        };
        const response = await BinnacleService.getBinnacleFiles(params);
        this.photos = response.photos || [];
        this.files = response.files || [];
      } catch (error) {
        this.showErrorSwal(error);
      } finally {
        this.removePreloader();
      }
    },
    previewImage(route) {
      this.showImageViewer = true;
      this.currentFileRoute = [route];
    },
    async removeMember(member) {
      try {
        const result = await this.showConfirmSwal();
        if (!result.isConfirmed) {
          return;
        }
        this.addPreloader();
        const params = {
          binnacle_id: this.G_CURRENT_CHAT_ID,
          user_id: member.user_id,
        };
        await BinnacleService.removeMember(params);
        const index = this.G_CURRENT_CHAT_MEMBERS.findIndex(
          (item) => item.user_id === member.user_id
        );
        this.G_CURRENT_CHAT_MEMBERS.splice(index, 1);
      } catch (error) {
        this.showErrorSwal(error);
      } finally {
        this.removePreloader();
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.chat-sidebar {
  width: 30vw;
  height: 100%;
  position: absolute;
  right: 0;
  top: 0;
  background: #ffffff !important;
  border: 2px solid #d7d7d7;
  z-index: 999999999999; // 12
  transition: left 0.3s ease;
  overflow: scroll;
  overflow-x: hidden;

  .sidebar-title {
    font-weight: 600;
    font-size: 1.5rem;
    color: #4a4a4a;
  }

  .sidebar-left-text {
    font-weight: 700;
    font-size: 1.2rem;
    color: #4a4a4a;
  }

  .sidebar-right-text {
    font-weight: 600;
    font-size: 1.2rem;
  }
  .custom-scrollbar-files {
    width: 100%;
    height: 100%;
  }

  .sidebar-image {
    border-radius: 3%;
    cursor: pointer;
    &:hover {
      // box-shadow: 0 0 10px rgba(0, 0, 0, 0.5);
      // blue box-shadow
      box-shadow: 0 0 15px rgba(0, 0, 255, 0.5);
    }
  }

  .bg-file {
    cursor: pointer;
    &:hover {
      background-color: rgba(233, 236, 239, 0.3);
    }
  }
}
.dark-layout {
  .chat-sidebar {
    background: #040d19 !important;
    border: 2px solid #0b213f;

    .sidebar-title {
      color: #fff;
    }

    .sidebar-left-text {
      color: rgba(255, 255, 255, 0.67);
    }
  }
}
</style>