<template>
  <b-modal
      v-model="onControl"
      @hidden="close"
      centered
      size="sm"
      :title="title"
      :modal-class="modalClass"
      :header-bg-variant="headerBg"
  >
    <validation-observer ref="form">
      <validation-provider
          v-slot="{ errors, valid }"
          name="motive"
          rules="required"
      >
      <b-form-input
          placeholder="Enter motive"
          v-model="newMotive"
      ></b-form-input>
        <span v-if="errors[0]" class="text-danger">
          Motive {{ errors[0] }}</span>
      </validation-provider>
    </validation-observer>

    <template #modal-footer="{ cancel }">
      <button-cancel @click="cancel()"></button-cancel>
      <button-save @click="saveMotive"></button-save>
    </template>
  </b-modal>
</template>

<script>
import buttonSave from '@/views/commons/utilities/ButtonSave.vue'
import buttonCancel from '@/views/commons/utilities/ButtonCancel.vue'
import PayrollService from '@/views/management/views/payroll/payroll.service'
import { mapGetters } from 'vuex'

export default {
  props: {
    mood: null,
    title: null,
  },
  components: {
    buttonSave,
    buttonCancel,
  },
  async created() {
    this.onControl = true
    if (this.mood === 1){
      this.modalClass = 'modal-primary'
      this.headerBg = 'primary'
    } else{
      this.modalClass = 'modal-danger'
      this.headerBg = 'danger'
    }
  },
  data() {
    return {
      onControl: false,
      modalClass: '',
      headerBg: '',
      newMotive: '',
    }
  },
  methods: {
    close() {
      this.$emit("close");
    },
    async saveMotive(){
      try {
        const result = await this.$refs.form.validate();
        if (result) {
          const response = await this.showConfirmSwal();
          if (response.isConfirmed) {
            this.addPreloader()
            const data = await PayrollService.createMotivePayment({
              in_motive: this.newMotive,
              in_type: this.mood,
              in_user: this.currentUser.user_id,
            })
            if (data.status === 200) {
              this.$emit('getMotives')
              this.removePreloader()
              this.showSuccessSwal("Motive created successfully");
              this.close()
            }
          }
        }
      } catch (e) {
        this.removePreloader()
        this.showErrorSwal(e)
      }
    }
  },
  computed: {
    ...mapGetters({
      currentUser: "auth/currentUser",
    }),
  }
}
</script>

<style scoped>

</style>
