export default [
  {
    key: 'full_name',
    label: 'Full Name',
  },
  {
    key: 'name_role',
    label: 'Rol',
  },
  {
    key: 'currency',
    label: 'Currency',
  },
  {
    key: 'exchange',
    label: 'Exchange Rate',
  },
  {
    key: 'salary',
    label: 'Salary',
  },
  {
    key: 'type_agreement',
    label: 'Type Agreement',
  },
  {
    key: 'hours',
    label: 'Hours',
  },
  {
    key: 'total_aditional',
    label: 'Regular/Aditional',
  },
  {
    key: 'total_disccount',
    label: 'Discounts',
    thStyle: {
      width: '120px',
    },
  },
  {
    key: 'pay_net',
    label: 'Net',
    thStyle: {
      width: '120px',
    },
  },
  {
    key: 'status',
    label: 'Status',
    thStyle: {
      width: '110px',
    }
  },
  {
    key: 'actions',
    label: 'Actions',
    thStyle: {
      width: '110px',
    }
  },
  {
    key: 'pdf_amg',
    label: 'PDF',
  },
]
