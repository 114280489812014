<template>
  <div>
    <header-slot>
      <template #actions>
        <div>
          <b-button
            v-if="payRollPending"
            variant="success"
            @click="openCreateEditPayrollModal"
          >
            <feather-icon
              icon="PlusIcon"
              size="15"
              class="mr-50 text-white"
            />New Payroll
          </b-button>
        </div>
      </template>
    </header-slot>
    <pay-roll-sidebar
      v-if="payRollSidebarOn"
      :title="title"
      :payroll-item="payrollItem"
      @close="closePayRollSidebar"
      @reload="$refs['refPayRolls'].refresh()"
    />
    <filter-slot
      :filter="filter"
      :filter-principal="{}"
      :no-visible-principal-filter="true"
      :total-rows="totalRows"
      :paginate="paginate"
      :start-page="startPage"
      :to-page="toPage"
      @reset-all-filters="resetAllFilters"
      @reload="$refs['refPayRolls'].refresh()"
    >
      <template #table>
        <b-table
          ref="refPayRolls"
          no-border-collapse
          class="position-relative"
          :fields="fields"
          show-empty
          no-provider-filtering
          sticky-header="50vh"
          primary-key="id"
          table-class="text-nowrap"
          responsive="sm"
          :items="payRollProvider"
          :sort-by.sync="sortBy"
          :sort-desc.sync="sortDesc"
          :per-page="paginate.perPage"
          :current-page="paginate.currentPage"
          :filter="searchInput"
          :busy.sync="isBusy"
        >
          <template #table-busy>
            <div class="text-center text-primary my-2">
              <b-spinner class="align-middle mr-1" />
              <strong>Loading ...</strong>
            </div>
          </template>
          <template #cell(code)="data">
            <a
              :class="textLink"
              @click="openPayRoll(data.item)"
            >
              {{ data.item.code }}
            </a>
          </template>
          <template #cell(start_date)="data">
            {{ data.item.start_date | myGlobal }}
          </template>
          <template #cell(end_date)="data">
            {{ data.item.end_date | myGlobal }}
          </template>
          <template #cell(created_by)="data">
            <p class="text-center">
              {{ data.item.created_by }} <br> {{ data.item.created_at | myGlobalWithHour }}
            </p>
          </template>
          <template #cell(status)="data">
            <div class="d-flex justify-content-center">
              <b-badge
                v-if="data.item.status === 2"
                variant="success"
                class="badge-width"
              >
                DONE
              </b-badge>
              <b-badge
                v-else-if="data.item.status === 1"
                variant="danger"
                class="p-03 badge-width"
              >
                PENDING
              </b-badge>
              <b-badge
                v-else
                variant="light-danger"
                class="badge-width"
              >
                CANCEL
              </b-badge>
            </div>
          </template>
          <template #cell(updated_by)="data">
            <p class="text-center">
              {{ data.item.updated_by }} <br> {{ data.item.updated_at | myGlobalWithHour }}
            </p>
          </template>
          <template #cell(actions)="data">
            <div class="d-flex justify-content-center">
              <template v-if="data.item.status === 1">
                <b-button
                  size="sm"
                  :variant="data.item.ccd === null? 'success' : 'secondary' "
                  :class="data.item.ccd === null? 'cursor-pointer' : 'not-cursor' "
                  :disabled="data.item.ccd !== null"
                  title="Done Payroll"
                  class="ml-1 pad"
                  @click="donePayroll(data.item.id)"
                >
                  <tabler-icon
                    icon="ChecksIcon"
                  /> &nbsp DONE
                </b-button>
                <b-button
                  size="sm"
                  title="Edit Payroll"
                  variant="outline-primary"
                  class="cursor-pointer ml-1 pad"
                  @click="editPayroll(data.item)"
                >
                  <tabler-icon
                    icon="EditIcon"
                  />
                </b-button>
                <b-button
                  size="sm"
                  title="Cancel Payroll"
                  variant="danger"
                  class="cursor-pointer ml-1 pad"
                  @click="cancelPayroll(data.item.id)"
                >
                  <tabler-icon
                    icon="PowerIcon"
                  />
                </b-button>
              </template>
              <b-button
                v-else-if="data.item.status === 2"
                size="sm"
                variant="success"
                title="The payroll is done"
                class="ml-1 pad"
                disabled
              >
                <tabler-icon
                  icon="ChecksIcon"
                />
              </b-button>
              <b-button
                v-else
                size="sm"
                variant="danger"
                class="ml-1 pad"
                title="The payroll was canceled"
                disabled
              >
                <tabler-icon
                  icon="PowerIcon"
                />
              </b-button>
              <span
                title="Generate Excel"
                class="ml-1 cursor-pointer "
                @click="generateExcel(data.item.id)"
              >
                <amg-icon
                  size="18"
                  class="cursor-pointer text-success"
                  icon="FileExcelIcon"
                />
              </span>

            </div>
          </template>
        </b-table>
        <create-edit-payroll-modal
          v-if="openCreateEditPayrollController"
          :item="selectedItem"
          :mood="mood"
          @close="closeCreateEditPayrollModal"
          @reload="$refs['refPayRolls'].refresh()"
        />
      </template>
    </filter-slot>
  </div>
</template>

<script>
import PayrollService from '@/views/management/views/payroll/payroll.service'
import PayrollTableFilters from '@/views/management/views/payroll/payroll-module/payroll-table-filters'
import CreateEditPayrollModal from '@/views/management/views/payroll/payroll-module/modals/CreateEditPayrollModal'
import PayRollSidebar from '@/views/management/views/payroll/payroll-module/sidebars/PayRollSidebar'
import { mapGetters } from 'vuex'

export default {
  components: {
    CreateEditPayrollModal,
    PayRollSidebar,
  },
  computed: {
    ...mapGetters({
      currentUser: 'auth/currentUser',
    }),
  },
  data() {
    return {
      payRollSidebarOn: false,
      filterPrincipal: {
        type: 'input',
        inputType: 'text',
        placeholder: 'Search...',
        model: '',
      },
      mood: '',
      filter: PayrollTableFilters,
      fields: [
        {
          key: 'code',
          label: 'Code',
        },
        {
          key: 'start_date',
          label: 'Start Date',
        },
        {
          key: 'end_date',
          label: 'End Date',
        },
        {
          key: 'created_by',
          label: 'Created By',
          thStyle: {
            textAlign: 'center',
          },
        },
        {
          key: 'status',
          label: 'Status',
          thStyle: {
            textAlign: 'center',
          },
        },
        {
          key: 'updated_by',
          label: 'Done / Cancel by',
          thStyle: {
            textAlign: 'center',
          },
        },
        {
          key: 'actions',
          label: 'Actions',
          thStyle: {
            textAlign: 'center',
          },
        },
      ],
      totalRows: 0,
      paginate: {
        perPage: 10,
        currentPage: 1,
      },
      openCreateEditPayrollController: false,
      startPage: 0,
      toPage: 0,
      isBusy: false,
      sortBy: 'name',
      sortDesc: true,
      searchInput: '',
      selectedItem: [],
      items: [],
      payRollPending: false,
      payRollPendingArray: [],
      title: '',
      payrollItem: '',
    }
  },
  methods: {
    async payRollProvider(ctx) {

      const params = {
        per_page: ctx.perPage,
        orderby: 1,
        page: ctx.currentPage,
        order: 'desc',
        campo: this.filterPrincipal.model,
        from: this.filter[0].model,
        to: this.filter[1].model,
      }
      const data = await PayrollService.getPayRolls(params)
      this.items = data.data
      this.startPage = data.from
      this.paginate.currentPage = data.current_page
      this.paginate.perPage = data.per_page
      this.nextPage = this.startPage + 1
      this.endPage = data.last_page
      this.totalRows = data.total
      this.toPage = data.to
      this.payRollPending = this.items.some(item => item.status === 1)
      if (this.payRollPending) {
        this.payRollPendingArray.push(1)
      }
      this.payRollPending = !(this.payRollPendingArray.some(item => item == 1))

      return this.items || []
    },
    resetAllFilters() {
      this.filter.forEach(filter => {
        filter.model = null
      })
      this.filterPrincipal.model = null
      this.$refs.refPayRolls.refresh()
    },
    async donePayroll(id) {
      try {
        const response = await this.showConfirmSwal()
        if (response.isConfirmed) {
          const data = await PayrollService.updateStatusPayRollById({ payroll_id: id, user: this.currentUser.user_id })
          if (data.status === 200) {
            this.payRollPendingArray = []
            this.payRollPending = true
            this.showSuccessSwal('Payroll has been saved successfully')
          }
          this.$refs.refPayRolls.refresh()
        }
      } catch (e) {
        this.showErrorSwal(e)
      }
    },
    editPayroll(item) {
      this.mood = 2
      this.selectedItem = item
      this.openCreateEditPayrollController = true
    },
    openPayRoll(item) {
      this.title = `PAYROLL: # ${item.code} (${this.$options.filters.myGlobal(item.start_date)} - ${this.$options.filters.myGlobal(item.end_date)})`
      this.payrollItem = item
      this.payRollSidebarOn = true
    },
    closePayRollSidebar() {
      this.payRollSidebarOn = false
    },
    async cancelPayroll(id) {
      try {
        const response = await this.showConfirmSwal()
        if (response.isConfirmed) {
          const data = await PayrollService.cancelPayroll({
            id,
            user: this.currentUser.user_id,
          })
          if (data.status === 200) {
            this.payRollPendingArray = []
            this.payRollPending = true
            this.showSuccessSwal('Payroll has been deleted successfully')
          }
          this.$refs.refPayRolls.refresh()
        }
      } catch (e) {
        this.showErrorSwal(e)
      }
    },
    openCreateEditPayrollModal() {
      this.mood = 1
      this.openCreateEditPayrollController = true
    },
    closeCreateEditPayrollModal() {
      this.openCreateEditPayrollController = false
    },
    async generateExcel(id) {
      try {
        const response = await this.showConfirmSwal('Are you sure?', 'Excel will be generated!')
        if (response.isConfirmed) {
          this.addPreloader()
          const data = await PayrollService.generateExcel(id)
          await this.forceFileDownload(data, 'PayRoll.xlsx')
          this.removePreloader()
        }
      } catch (error) {
        this.showErrorSwal(error)
        this.removePreloader()
      }
    },
  },
}
</script>
<style>

</style>
<style scoped>
.bigger{
  width: 2rem;
  height: 2rem;
}
.pad{
  padding: 0.5rem !important;
}
.not-cursor {
  cursor: not-allowed !important;
}
.p-03{
  padding: 0.3rem !important;
}
.badge-width{
}
</style>
