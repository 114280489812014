<template>
  <b-modal
    v-model="onControl"
    :title="title"
    title-tag="h3"
    modal-class="modal-primary"
    no-close-on-backdrop
    @hidden="close"
  >
    <validation-observer ref="form">
      <b-row>
        <b-col cols="6">
          <validation-provider
            v-slot="{ errors }"
            name="start_date"
            rules="required"
          >
            <b-form-group
              id="input-group-1"
              label="Start Date:"
              label-for="input-1"
            >
              <b-form-datepicker
                id="input-1"
                v-model="startDate"
                :max="endDate"
                :date-format-options="{ year: 'numeric', month: 'numeric', day: 'numeric' }"
                locale="en"
                placeholder="Date"
              />
              <span
                v-if="errors[0]"
                class="text-danger"
              >
                Start date {{ errors[0] }}</span>
            </b-form-group>
          </validation-provider>
        </b-col>
        <b-col cols="6">
          <validation-provider
            v-slot="{ errors }"
            name="end_date"
            rules="required"
          >
            <b-form-group
              id="input-group-2"
              label="End Date:"
              label-for="input-2"
            >
              <b-form-datepicker
                id="input-2"
                v-model="endDate"
                :min="startDate"
                :date-format-options="{ year: 'numeric', month: 'numeric', day: 'numeric' }"
                locale="en"
                placeholder="Date"
              />
              <span
                v-if="errors[0]"
                class="text-danger"
              >
                End date {{ errors[0] }}</span>
            </b-form-group>
          </validation-provider>

        </b-col>
      </b-row>
    </validation-observer>
    <template #modal-footer="{ cancel }">
      <button-cancel @click="cancel()" />
      <button-save @click="savePayroll" />
    </template>
  </b-modal>
</template>

<script>
import buttonSave from '@/views/commons/utilities/ButtonSave.vue'
import buttonCancel from '@/views/commons/utilities/ButtonCancel.vue'
import PayrollService from '@/views/management/views/payroll/payroll.service'
import { mapGetters } from 'vuex'

export default {
  components: {
    buttonSave,
    buttonCancel,
  },
  props: {
    item: null,
    mood: null,
  },
  computed: {
    ...mapGetters({
      currentUser: 'auth/currentUser',
      token: 'auth/token',
    }),
  },
  data() {
    return {
      onControl: false,
      startDate: '',
      endDate: '',
      title: '',
      dateFormat: { year: 'numeric', month: 'numeric', day: 'numeric' },
    }
  },
  async created() {
    this.title = 'Create Payroll'
    if (this.mood === 2) {
      this.title = 'Edit Payroll'
      this.startDate = this.item.start_date
      this.endDate = this.item.end_date
    }
    this.onControl = true
  },
  methods: {
    close() {
      this.$emit('close')
    },
    async savePayroll() {
      try {
        const result = await this.$refs.form.validate()
        if (result) {
          const response = await this.showConfirmSwal()
          if (response.isConfirmed) {
            const params = {
              idPayroll: this.item.id, startDate: this.startDate, endDate: this.endDate, user_id: this.currentUser.user_id,
            }
            let data = ''
            this.addPreloader()
            if (this.mood === 1) {
              data = await PayrollService.insertPayroll(params)
            } else if (this.mood === 2) {
              data = await PayrollService.updatePayroll(params)
            }
            if (data.status === 200) {
              this.showSuccessSwal('Payroll has been created successfully')
              this.removePreloader()
              this.close()
              this.$emit('reload')
            }
          }
        }
      } catch (e) {
        this.removePreloader()
        this.showErrorSwal(e)
      }
    },
  },
}
</script>

<style scoped>

</style>
