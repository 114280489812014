<template>
  <div class="view-files-pg">
    <b-row>
      <b-col lg="4" md="6" cols="6" v-for="item in items" :key="item.id">
        <div class="custom-card-container">
          <div class="actions">
            <feather-icon
              :id="`popover-${item.id}`"
              icon="MoreVerticalIcon"
              class="text-dark cursor-pointer"
              size="20"
            ></feather-icon>
            <b-popover
              :target="`popover-${item.id}`"
              placement="rightbottom"
              triggers="hover focus"
            >
              <div
                class="cursor-pointer"
                @click="forceDownload(item.route, item.file_name)"
              >
                <feather-icon
                  icon="DownloadIcon"
                  class="text-primary"
                ></feather-icon>
                Download
              </div>
              <div
                v-if="[1, 17].includes(currentUser.role_id) && !readOnly"
                class="cursor-pointer"
                style="margin-top: 5px"
                @click="deleteFile(item.id)"
              >
                <feather-icon
                  icon="Trash2Icon"
                  class="text-danger"
                ></feather-icon>
                Delete
              </div>
            </b-popover>
          </div>
          <div class="icon-container">
            <img :src="getImageFile(item.extension)" width="100" />
            <div class="mt-1 title" v-b-tooltip.hover :title="item.file_name">
              {{ item.file_name | limitChars(40) }}
            </div>
          </div>
          <div class="footer">
            <b-row>
              <b-col cols="6">
                <div class="font-weight-bolder">Filesize</div>
                <div>{{ item.size }}</div>
              </b-col>
              <b-col cols="6">
                <div class="font-weight-bolder">Created by</div>
                <div
                  v-b-tooltip.hover
                  :title="`${item.first_name + ' ' + item.last_name}`"
                  class="text-truncate"
                >
                  {{ item.first_name + " " + item.last_name }}
                </div>
              </b-col>
            </b-row>
          </div>
        </div>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import PGRequestServices from "@/views/commons/components/paragon-soft/services/PGRequest.services.js";
import { mapGetters } from "vuex";
export default {
  props: {
    pgRequestId: {
      type: [String, Number],
      required: true,
    },

    readOnly: {
      type: Boolean,
      default: false,
    },
  },

  computed: {
    ...mapGetters({
      currentUser: "auth/currentUser",
    }),
  },

  data() {
    return {
      items: [],
    };
  },

  methods: {
    getImageFile(extension) {
      const extensionMappings = {
        jpg: "jpg.png",
        png: "jpg.png",
        jpeg: "jpg.png",
        gif: "jpg.png",
        xls: "excel.png",
        xlsx: "excel.png",
        html: "html",
        pdf: "pdf.png",
        docx: "doc.png",
        pptx: "ppt.jpg",
        ppt: "ppt.jpg",
        default: "archivodesc.png",
      };

      const getImageFile =
        extensionMappings[extension] || extensionMappings.default;
      return `/assets/images/drag-drop/ext_files/${getImageFile}`;
    },

    async deleteFile(id) {
      const { isConfirmed } = await this.showConfirmSwal();
      if (!isConfirmed) {
        return;
      }
      try {
        this.addPreloader();

        const { data, status } = await PGRequestServices.destroyFileById(id);
        if (status == 200) {
          this.$emit("refresh");
          this.showSuccessSwal();
        }
      } catch (error) {
        this.showErrorSwal(error);
      } finally {
        this.removePreloader();
      }
    },

    forceDownload(url, filename) {
      const link = document.createElement("a");
      link.href = url;
      link.target = "_blank";
      link.setAttribute("download", filename);
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    },

    async getFilesByRequestId() {
      const { data } = await PGRequestServices.getFilesByRequest(
        this.pgRequestId
      );
      this.items = data.data;
    },
  },

  mounted() {
    this.getFilesByRequestId();
  },
};
</script>

<style lang="scss" scoped>
.view-files-pg {
  max-height: 56vh;
  .custom-card-container {
    widows: 100%;
    height: 260px;
    background-color: #fafafc;
    box-shadow: 0 4px 24px 0 rgba(34, 41, 47, 0.1);
    margin: 10px 0;
    padding: 20px;
    border-radius: 10px;

    .actions {
      display: flex;
      justify-content: end;
    }

    .icon-container {
      display: flex;
      flex-direction: column;
      align-items: center;
      text-align: center;

      .title {
        height: 30px;
        word-break: break-all;
      }
    }

    .footer {
      margin-top: 10px;
    }
  }
}

.dark-layout {
  .custom-card-container {
    background-color: #26262e;
  }
}
</style>