<template>
  <b-modal
    ref="modalDueDates"
    modal-class="modal-primary"
    size="timeline"
    @hidden="closeModal()"
    centered
    no-close-on-backdrop
  >
    <template #modal-title>
      <span class="h3 text-white font-weight-bolder"
        >Assign Due Date From: {{ requestCode }}</span
      >
    </template>
    <div class="d-flex flex-column gap-10">
      <div v-for="(due, index) in dues" :key="index">
        <label class="sr-only" for="date-due">Username</label>
        <b-input-group class="mb-2 mr-sm-2 mb-sm-0">
          <b-input-group-prepend class="w-30">
            <b-input-group-text
              class="w-100 text-black font-strong"
              :style="{ backgroundColor: colorsDues(due.status_id) }"
            >
              <span>{{ due.name }}</span>
            </b-input-group-text>
          </b-input-group-prepend>
          <b-form-datepicker
            v-model="due.due_date"
            :date-format-options="{
              year: 'numeric',
              month: 'numeric',
              day: 'numeric',
            }"
            locale="en-US"
            :format="'MM/dd/yyyy'"
          ></b-form-datepicker>
        </b-input-group>
      </div>
    </div>
    <template #modal-footer>
      <b-button
        variant="primary"
        @click="closeModal()"
        class="font-weight-bold"
      >
        Close
      </b-button>
      <b-button
        variant="success"
        class="text-black font-weight-bold"
        @click="saveDueDates()"
        >Save
      </b-button>
    </template>
  </b-modal>
</template>
<script>
import { mapGetters } from "vuex";
import RequestService from "@/views/commons/components/paragon-soft/services/PGRequest.services.js";
export default {
  props: {
    requestCode: {
      type: String,
      required: true,
    },
    requestId: {
      type: Number,
      required: true,
    },
  },
  data() {
    return {
      dues: [],
    };
  },
  mounted() {
    this.toggleModal("modalDueDates");
  },
  created() {
    this.getDueDates();
  },
  computed: {
    ...mapGetters({
      currentUser: "auth/currentUser",
    }),
  },
  methods: {
    async getDueDates() {
      try {
        const response = await RequestService.getDueDates({
          id: this.requestId,
        });
        this.dues = response.data;
      } catch (error) {
        console.log(error);
      }
    },
    colorsDues(status) {
      const colors = {
        3: "#fada90",
        4: "rgb(235, 197, 248)",
        5: "#a8d0f9",
      };
      return colors[status];
    },
    async saveDueDates() {
      this.addPreloader();
      try {
        await RequestService.saveDueDates({
          id: this.requestId,
          dues: this.dues,
          user_id: this.currentUser.user_id,
        });
        this.showSuccessSwal("Due Dates", "Due Dates saved successfully");
        this.$emit("updateDueDates");
      } catch (error) {
        console.log(error);
      } finally {
        this.removePreloader();
      }
    },
    closeModal() {
      this.$emit("close");
    },
  },
};
</script>
<style lang="scss" scoped>
.w-35 {
  width: 35% !important;
}
.w-30 {
  width: 30% !important;
}
</style>