<template>
  <div
    class="align-items-center justify-content-center mt-1 cursor-pointer reaction-icon"
  >
    <feather-icon
      v-show="G_MESSAGES[index].show"
      @click="G_MESSAGES[index].show_emojis = !G_MESSAGES[index].show_emojis"
      icon="SmileIcon"
      size="16"
      variant="info"
    />
    <div class="emoji-list" v-if="G_MESSAGES[index].show_emojis">
      <template v-for="(emoji, index) in G_EMOJIS">
        <b-img
          v-if="index < G_EMOJIS.length - 1"
          @click="reactMessage(message.message_id, emoji.id)"
          :key="emoji.id"
          class="cursor-pointer mr-1 reaction"
          height="20"
          width="20"
          :src="require(`@/assets/images/icons/soft-projects/${emoji.name}`)"
        ></b-img>
        <b-img
          v-if="
            index == G_EMOJIS.length - 1 &&
            isFelipillos.includes(currentUser.user_id)
          "
          @click="reactMessage(message.message_id, emoji.id)"
          :key="emoji.id"
          class="cursor-pointer mr-1 reaction"
          height="20"
          width="20"
          :src="require(`@/assets/images/icons/soft-projects/${emoji.name}`)"
        ></b-img>
      </template>
    </div>
  </div>
</template>

<script>
import BinnacleService from "@/views/commons/components/paragon-soft/services/PGBinnacle.service.js";
import { mapGetters } from "vuex";

export default {
  name: "ChatMessageReactionSelector",
  props: {
    message: {
      type: Object,
      required: true,
    },
    index: {
      type: Number,
      required: true,
    },
  },
  data() {
    return {
      isFelipillos: [357, 383, 402, 410, 292, 124],
    };
  },
  computed: {
    ...mapGetters({
      currentUser: "auth/currentUser",
      G_MESSAGES: "SoftProjectsBinnacleStore/G_MESSAGES",
      G_EMOJIS: "SoftProjectsBinnacleStore/G_EMOJIS",
    }),
    lastEmoji() {
      return this.G_MESSAGES[this.index].reactions[
        this.G_MESSAGES[this.index].reactions.length - 1
      ];
    },
  },

  methods: {
    async reactMessage(message_id, emoji_id) {
      // search the message with id = message id and get index
      const index = this.G_MESSAGES.findIndex(
        (message) => message.message_id === message_id
      );
      if (index === -1) return;
      // console.log(this.G_MESSAGES[index]);
      this.G_MESSAGES[index].show = false;
      this.G_MESSAGES[index].show_emojis = false;
      await this.storeBinnacleMessageReaction(message_id, emoji_id);
    },
    async storeBinnacleMessageReaction(message_id, emoji_id) {
      try {
        const params = {
          reaction_id: emoji_id,
          user_id: this.currentUser.user_id,
          message_id: message_id,
        };
        await BinnacleService.storeBinnacleMessageReaction(params);
      } catch (error) {
        this.showErrorSwal(error);
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.reaction-icon {
  position: relative !important;
}
.reaction {
  &:hover {
    transform: scale(1.8);
  }
}
.emoji-list {
  position: absolute !important;
  background-color: #fff;
  border-radius: 0.5rem;
  padding: 0.5rem;
  box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.1);
  z-index: 1;
  border-width: 0 20px 20px 0;
  width: max-content !important;
}
.dark-layout {
  .emoji-list {
    background-color: #071426 !important;
  }
}
</style>