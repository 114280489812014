<template>
  <div>
    <b-modal
      ref="process-diagram-modal"
      size="xlg"
      @hidden="$emit('hidden')"
      no-close-on-backdrop
      hide-footer
    >
      <template #modal-title>
        <span class="text-uppercase font-weight-bolder">
          Process diagram: {{ requestCode }}
        </span>
      </template>
      <template #default>
        <div class="pg-process-diagram-modal">
          <div v-if="showGif" class="gif-container">
            <img
              v-show="showGif"
              id="miroGif"
              ref="gifElement"
              :src="gifSrc"
              alt="MIROGIF"
              class="centered"
            />
          </div>
          <iframe
            v-show="iframe"
            id="miro"
            width="98%"
            height="98%"
            :src="miroUrl"
            scrolling="no"
            allowFullScreen
            style="z-index: 1; position: absolute"
            title="iframeModal"
            @load="loadBanner"
          />
        </div>
      </template>
    </b-modal>
  </div>
</template>
<script>
import RequestService from "@/views/commons/components/paragon-soft/services/PGRequest.services.js";
import { mapGetters } from "vuex";
export default {
  props: {
    requestId: {
      type: Number,
      required: true,
    },
    requestCode: {
      type: String,
      required: true,
    },
    miroCode: {
      type: String,
      default: null,
    },
  },
  data() {
    return {
      contentLoaded: false,
      iframe: false,
      gifSrc: null,
      miroUrl: "",
      showGif: false,
    };
  },
  created() {
    this.createMiroTemplates();
  },
  mounted() {
    this.toggleModal("process-diagram-modal");
  },
  computed: {
    ...mapGetters({
      currentUser: "auth/currentUser",
    }),
  },
  methods: {
    async createMiroTemplates() {
      try {
        this.addPreloader();
        const params = {
          id: this.requestId,
          code: this.requestCode,
          miro_code: this.miroCode,
          user_id: this.currentUser.user_id,
        };
        const { data } = await RequestService.createMiroTemplates(params);
        this.miroCode = data.miro_code;
        this.$emit("refresh");
        await this.showMiroTemplates();
      } catch (error) {
        this.showErrorSwal(error);
      } finally {
        this.removePreloader();
      }
    },
    async showMiroTemplates() {
      this.miroUrl = `https://miro.com/app/live-embed/${this.miroCode}/?autoplay=true&moveToViewport=-23165,-5837,13803,7546"`;
      this.gifSrc = this.isDarkSkin
        ? `${process.env.BASE_URL}assets/gifs/miro-dark.gif`
        : `${process.env.BASE_URL}assets/gifs/miro.gif`;
      this.playGif();
    },
    loadBanner() {
      this.contentLoaded = true;
    },
    playGif() {
      this.showGif = true;
      setTimeout(() => {
        this.showGif = false;
        this.iframe = true;
      }, 4000);
    },
  },
};
</script>
<style lang="scss">
.pg-process-diagram-modal {
  width: 100% !important;
  height: 80vh !important;
  .gif-container {
    position: absolute;
    width: 500px;
    height: 500px;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 3;
    img {
      width: 100%;
      height: 100%;
    }
  }
}
</style>