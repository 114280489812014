var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"chat-body",on:{"click":_vm.clickBody}},[(_vm.showMediaUploader)?_c('ChatMessagesFileUploader',{attrs:{"files":_vm.files},on:{"onAdditionalFileChange":_vm.onAdditionalFileChange,"closeMediaUploader":function($event){_vm.showMediaUploader = false}}}):_c('perfect-scrollbar',{staticClass:"custom-messages-scrollbar"},[_c('div',{staticClass:"scroll-kanban max-height-content h-100"},[(_vm.G_MESSAGES.length > 0)?_c('div',{ref:"scrollList",staticClass:"scrollList",on:{"scroll":_vm.handleScroll}},[_vm._l((_vm.G_MESSAGES),function(message,index){return [_c('div',{key:index,class:{
              me: message.created_by == _vm.currentUser.user_id,
              other: message.created_by != _vm.currentUser.user_id,
              center: message.created_by == 0,
            },staticStyle:{"padding":"1rem 1rem","border-radius":"10px"},on:{"mouseover":function($event){return _vm.showReactionIcon(index, true)},"mouseout":function($event){return _vm.showReactionIcon(index, false)}}},[_c('b-container',{staticClass:"d-flex align-items-center justify-content-between"},[(message.created_by != _vm.currentUser.user_id)?_c('b-avatar',{attrs:{"src":message.user_photo,"size":"3.5rem"}}):_vm._e(),_c('div',{ref:'div' + message.message_id,refInFor:true,staticClass:"d-flex flex-column",class:{
                  'justify-content-end align-items-end mr-1':
                    message.created_by == _vm.currentUser.user_id,
                  'justify-content-start align-items-start ml-1':
                    message.created_by != _vm.currentUser.user_id,
                }},[_c('span',{staticStyle:{"font-size":"1em","font-weiht":"bolder"}},[_vm._v(" "+_vm._s(message.created_by == _vm.currentUser.user_id ? "You" : message.sent_by)+" "),_c('span',{staticStyle:{"font-size":"1em","font-weiht":"bolder","margin-left":"1.5rem"}},[_vm._v(_vm._s(_vm._f("myHourTime")(message.sent_at)))])]),_c('div',{staticClass:"d-flex",class:message.files && message.files != null
                      ? 'flex-column-reverse'
                      : 'flex-column'},[(message.content != null && message.files == null)?_c('span',{ref:'span' + message.message_id,refInFor:true,class:{
                      'message-content-me mr-1':
                        message.created_by == _vm.currentUser.user_id,
                      'message-content-other ':
                        message.created_by != _vm.currentUser.user_id,
                    },staticStyle:{"margin-top":"0.5rem"},domProps:{"innerHTML":_vm._s(
                      _vm.highlightMessages(
                        message.content,
                        message.message_id == _vm.searchMessageId
                          ? _vm.searchMessage
                          : null,
                        10
                      )
                    )}}):_vm._e(),_c('div',{staticClass:"d-flex align-items-center",class:{
                      'justify-content-between':
                        _vm.seens.length > 0 &&
                        _vm.G_MESSAGES[0].message_id == message.message_id,
                    }},[(_vm.seens.length > 0)?[(
                          _vm.G_MESSAGES[0].message_id == message.message_id &&
                          _vm.seens.length <= 5
                        )?_c('div',{staticClass:"avatar-seen-container"},[_vm._l((_vm.seens),function(seen,index){return [_c('b-avatar',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.hover.top",modifiers:{"hover":true,"top":true}}],key:index,staticClass:"avatar-seen cursor-pointer",attrs:{"size":"1.4rem","title":seen.user,"src":seen.user_photo}})]})],2):_vm._e()]:_vm._e(),(message.reactions != null)?_c('ChatMessagesReaction',{attrs:{"message":message,"index":index}}):_vm._e()],2),(message.files && message.files != null)?_c('div',{staticClass:"image-grid"},[_vm._l((message.files),function(file,index){return [(_vm.validImageExtensions.includes(file.extension))?[(index <= 3)?_c('div',{key:index,staticClass:"image-container cursor-pointer",class:{
                            'img-overlay':
                              index === 3 && message.files.length > 4,
                          },style:(message.files.length <= 4
                              ? 'padding: 0.3rem 0.5rem'
                              : ''),on:{"click":function($event){return _vm.previewImage(message.files, file.route)}}},[_c('b-img-lazy',_vm._b({staticClass:"image-container-image",attrs:{"src":file.route,"alt":file.name}},'b-img-lazy',_vm.imageProps,false)),(index === 3 && message.files.length > 4)?_c('div',{staticClass:"overlay-text"},[_vm._v(" +"+_vm._s(message.files.length - 4)+" ")]):_vm._e()],1):_vm._e()]:(file.extension == 'mp3')?[_c('audio',{key:file.filename,staticClass:"mt-1",attrs:{"controls":""}},[_c('source',{attrs:{"src":file.route,"type":"audio/mp3"}}),_vm._v(" /> Your browser does not support the audio element. ")])]:(file.extension == 'mp4')?[_c('span',{key:index,class:{
                            'message-content-me mr-1':
                              message.created_by == _vm.currentUser.user_id,
                            'message-content-other ':
                              message.created_by != _vm.currentUser.user_id,
                          },staticStyle:{"margin-top":"0.5rem"}},[_c('video',{staticStyle:{"width":"100%","height":"100%","border-radius":"5%"},attrs:{"controls":"","src":file.route}})])]:[_c('span',{key:index,staticClass:"cursor-pointer",class:{
                            'message-content-me mr-1':
                              message.created_by == _vm.currentUser.user_id,
                            'message-content-other ':
                              message.created_by != _vm.currentUser.user_id,
                          },staticStyle:{"margin-top":"0.5rem"},on:{"click":function($event){return _vm.forceDownload(file.route, file.name)}}},[(file.extension === 'pdf')?_c('PDFSvg',{staticStyle:{"width":"2rem","height":"2rem"}}):_c('feather-icon',{attrs:{"icon":"FileIcon","size":"16"}}),_c('span',[_vm._v(" "+_vm._s(_vm._f("limitChars")(file.filename,15))+" ")]),_c('span',[_vm._v(" "+_vm._s(file.size)+" ")]),_c('feather-icon',{attrs:{"icon":"DownloadIcon","size":"16"}})],1)]]})],2):_vm._e()])]),(message.created_by == _vm.currentUser.user_id)?_c('b-avatar',{attrs:{"src":message.user_photo,"size":"3.5rem"}}):_vm._e(),(message.created_by != _vm.currentUser.user_id)?_c('ChatMessageReactionSelector',{attrs:{"index":index,"message":message}}):_vm._e()],1)],1)]}),_c('transition',{attrs:{"name":"fade"}},[(_vm.showScrollDown)?_c('span',{staticClass:"scroll-down"},[_c('b-button',{on:{"click":function($event){return _vm.scrollCommentsBottom()}}},[_c('feather-icon',{attrs:{"icon":"ArrowDownIcon","size":"20"}})],1)],1):_vm._e()])],2):_c('div',{staticClass:"d-flex justify-content-center align-items-center h-100"},[_vm._v(" No messages yet ")])])]),(_vm.showImageViewer)?_c('ImageViewer',{attrs:{"images":_vm.currentFiles},on:{"hidden":function($event){_vm.showImageViewer = false}}}):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }