<template>
  <div>
    <header-slot />
    <b-card>
      <div
        class="w-full d-flex justify-content-between align-items-center mb-1"
      >
        <b-button @click="myProvider(true)" variant="primary">
          Refresh <feather-icon icon="RefreshCwIcon" />
        </b-button>
        <template v-if="moduleId == 16 && isCeo">
          <b-button
            variant="success"
            @click="showModalCreateRequest = true"
            class="text-black"
            style="margin-right: 5px"
          >
            NEW PROJECT <feather-icon icon="PlusIcon" />
          </b-button>
        </template>
      </div>
      <!--board-->
      <div class="pg-board-assign-priority">
        <!-- board container-->
        <div class="board-container d-flex flex-row" v-if="!loading">
          <template v-for="(item, index) in requests">
            <div class="board d-flex flex-column" :key="index">
              <div
                class="board-header d-flex align-items-center justify-content-between"
                :class="`bg-board-header-${isCeo ? index : index + 1}`"
              >
                <div>{{ item.name }} ({{ item.total_projects }})</div>
                <div
                  v-if="
                    item.total_projects != item.projects.length && item.id == 7
                  "
                  class="cursor-pointer text-black"
                  @click="showModalAllProjects = true"
                >
                  show more
                </div>
              </div>
              <div class="board-content">
                <draggable
                  class="list-group"
                  tag="span"
                  v-model="item.projects"
                  v-bind="dragOptions"
                  :scroll-sensitivity="300"
                  :force-fallback="true"
                  @start="handleStartListChange(item, $event)"
                  @add="handleAddListChange($event, item)"
                  @change="handleChangeListChange(item, index, $event)"
                >
                  <transition-group
                    type="transition"
                    name="flip-list"
                    class="transition-group"
                    tag="div"
                  >
                    <div
                      class="list-group-item"
                      v-for="(project, index) in item.projects"
                      :key="project + index"
                    >
                      <div class="content-header pt-1">
                        <div
                          class="d-flex justify-content-between align-items-center pb-1"
                        >
                          <span class="text-secondary">
                            <feather-icon icon="CalendarIcon" />
                            {{ project.created_at | formattedDate }}
                          </span>
                          <!-- actions-->
                          <div
                            class="custom-padding chat-header-icon"
                            v-if="hasPermission(item.id)"
                          >
                            <b-dropdown
                              size="xs"
                              variant="link"
                              toggle-class="text-decoration-none"
                              no-caret
                            >
                              <template #button-content>
                                <div class="container-actions">
                                  <feather-icon
                                    class="cursor-pointer"
                                    v-b-tooltip.hover
                                    title="More options"
                                    icon="MoreVerticalIcon"
                                  />
                                  <div
                                    v-if="
                                      getCountPendingAction(project, item.id) >
                                      0
                                    "
                                    class="count-actions-pending"
                                    v-b-tooltip.hover
                                    title="Actions pending"
                                  >
                                    {{
                                      getCountPendingAction(project, item.id)
                                    }}
                                  </div>
                                </div>
                              </template>
                              <!-- Edit -->
                              <b-dropdown-item
                                v-if="isCeo && item.id == 1"
                                @click="openModalUpdateRequest(project)"
                              >
                                <feather-icon icon="EditIcon"></feather-icon>
                                <span style="margin-top: 7px; margin-left: 5px"
                                  >Edit</span
                                >
                              </b-dropdown-item>
                              <!-- Difficulty -->
                              <b-dropdown-item
                                v-if="isChief && moduleId == 12 && item.id == 3"
                                @click="openModalAssingDifficulty(project)"
                              >
                                <div
                                  :class="
                                    !project.difficulty_id
                                      ? 'action-pending'
                                      : ''
                                  "
                                >
                                  <feather-icon
                                    icon="ChevronsUpIcon"
                                  ></feather-icon>
                                  <span
                                    style="margin-top: 7px; margin-left: 5px"
                                    >{{
                                      !project.difficulty ? "Assign" : "Change"
                                    }}
                                    Difficulty</span
                                  >
                                </div>
                              </b-dropdown-item>
                              <!-- Due Dates -->
                              <b-dropdown-item
                                v-if="
                                  isChief &&
                                  moduleId == 12 &&
                                  [3, 4, 5].includes(item.id)
                                "
                                @click="openModalDueDate(project)"
                              >
                                <div
                                  :class="
                                    !project.due_date ? 'action-pending' : ''
                                  "
                                >
                                  <feather-icon icon="ClockIcon"></feather-icon>
                                  <span
                                    style="margin-top: 7px; margin-left: 5px"
                                    >{{
                                      !project.due_date ? "Assign" : "Change"
                                    }}
                                    Due Date</span
                                  >
                                </div>
                              </b-dropdown-item>
                              <!-- Developer Team -->
                              <b-dropdown-item
                                v-if="isChief && [2, 3].includes(item.id)"
                                @click="openModalAssingTeam(project)"
                              >
                                <div
                                  :class="
                                    !project.team_id ? 'action-pending' : ''
                                  "
                                >
                                  <feather-icon icon="CodeIcon"></feather-icon>
                                  <span
                                    style="margin-top: 7px; margin-left: 5px"
                                    >{{
                                      project.team_id ? "Change" : "Assign"
                                    }}
                                    Developer Team</span
                                  >
                                </div>
                              </b-dropdown-item>
                              <!-- Trial Period -->
                              <b-dropdown-item
                                v-if="
                                  isChief &&
                                  [3, 4, 5].includes(item.id) &&
                                  project.probationary_period == 0
                                "
                                @click="probationaryPeriod(project)"
                              >
                                <feather-icon icon="FlagIcon"></feather-icon>
                                <span style="margin-top: 7px; margin-left: 5px"
                                  >{{
                                    project.probationary_period == 0
                                      ? "Activate"
                                      : "Deactivate"
                                  }}
                                  Trial Period</span
                                >
                              </b-dropdown-item>
                              <!-- Links -->
                              <b-dropdown-item
                                v-if="
                                  (isSupervisorDigital || isChief) &&
                                  item.id == 4
                                "
                                @click="openLinks(project)"
                              >
                                <div>
                                  <feather-icon icon="LinkIcon"></feather-icon>
                                  <span
                                    style="margin-top: 7px; margin-left: 5px"
                                  >
                                    Links
                                  </span>
                                </div>
                              </b-dropdown-item>
                              <!-- Delete -->
                              <b-dropdown-item
                                v-if="isCeo && item.id == 1"
                                @click="deleteRequest(project)"
                              >
                                <feather-icon
                                  icon="TrashIcon"
                                  class="text-danger"
                                />
                                <span
                                  style="margin-top: 7px; margin-left: 5px"
                                  class="text-danger"
                                >
                                  Delete
                                </span>
                              </b-dropdown-item>
                            </b-dropdown>
                          </div>
                        </div>
                        <!-- project title -->
                        <div class="d-flex align-items-center">
                          <div
                            class="text-truncate cursor-pointer"
                            v-b-tooltip.hover
                            :title="project.title"
                            @click="openModalShowDetail(project)"
                          >
                            <strong class="text-uppercase">
                              {{ project.title }}
                            </strong>
                            <div class="d-flex align-items-center gap-10">
                              <p class="text-secondary">{{ project.code }}</p>
                              <b-badge
                                v-if="project.probationary_period == 1"
                                size="sm"
                                variant="light-warning"
                                class="lh-0 mb-1"
                                style="padding: 7px"
                              >
                                Trial Period
                              </b-badge>
                            </div>
                          </div>
                        </div>
                        <!-- principal buttons -->
                        <div
                          class="d-flex align-items-center justify-content-center"
                        >
                          <b-button
                            :class="project.pending_messages > 0 ? 'mr-1' : ''"
                            @click="openModalBinnacleRequest(project)"
                            variant="outline-light"
                            size="sm"
                            v-b-tooltip.hover
                            title="Open Chat"
                            style="
                              background-color: transparent;
                              border: none;
                              padding: 0;
                            "
                          >
                            <feather-icon
                              :badge="
                                project.pending_messages > 0
                                  ? project.pending_messages
                                  : ''
                              "
                              :badgeClasses="
                                project.pending_messages > 0
                                  ? 'badge badge-danger badge-pill'
                                  : ''
                              "
                              icon="MessageCircleIcon"
                              :class="
                                project.pending_messages > 0
                                  ? 'text-danger'
                                  : 'text-primary'
                              "
                              size="25"
                            />
                          </b-button>
                          <b-button
                            v-if="
                              item.id != 1 &&
                              [1, 2, 35, 17].includes(currentUser.role_id)
                            "
                            variant="outline-light"
                            size="sm"
                            v-b-tooltip.hover
                            :title="
                              project.miro_code
                                ? 'Show Miro Process Diagram'
                                : 'Generate Miro Process Diagram'
                            "
                            class="ml-1"
                            style="
                              background-color: transparent;
                              border: none;
                              padding: 0;
                            "
                            @click="openModalProcessDiagram(project)"
                          >
                            <miro-icon-svg
                              :size="25"
                              :miro-code="project.miro_code"
                            />
                          </b-button>
                          <b-button
                            variant="outline-light"
                            class="ml-1"
                            size="sm"
                            v-b-tooltip.hover
                            title="Links"
                            style="
                              background-color: transparent;
                              border: none;
                              padding: 0;
                            "
                            @click="openLinks(project)"
                          >
                            <feather-icon icon="LinkIcon" size="25" />
                          </b-button>
                          <b-button
                            variant="outline-light"
                            class="ml-1"
                            size="sm"
                            v-b-tooltip.hover
                            title="Files"
                            style="
                              background-color: transparent;
                              border: none;
                              padding: 0;
                            "
                            @click="openModalUploadFiles(item.id, project)"
                          >
                            <feather-icon icon="FileIcon" size="25" />
                          </b-button>
                          <b-button
                            variant="outline-light"
                            class="ml-1"
                            size="sm"
                            v-b-tooltip.hover
                            title="Tracking"
                            style="
                              background-color: transparent;
                              border: none;
                              padding: 0;
                            "
                            @click="openModalTracking(project)"
                          >
                            <feather-icon icon="AlignJustifyIcon" size="25" />
                          </b-button>
                        </div>
                      </div>
                      <!-- other information by project -->
                      <div class="content-body pt-1">
                        <b-row>
                          <b-col>
                            <b-row>
                              <b-col>
                                <strong class="mr-2">Difficulty</strong>
                              </b-col>
                              <b-col>
                                <b-badge
                                  v-if="project.difficulty_id"
                                  v-b-tooltip.hover.auto="
                                    tooltipDifficulty(project.difficulty_id)
                                  "
                                  :variant="
                                    getVariantDifficulty(
                                      project.difficulty_id
                                    )[0]
                                  "
                                  size="sm"
                                >
                                  {{ project.difficulty }}
                                </b-badge>
                                <span v-else> - </span>
                              </b-col>
                            </b-row>
                            <b-row>
                              <b-col>
                                <strong class="mr-2">Created By</strong>
                              </b-col>
                              <b-col>
                                <span>
                                  {{ project.created_by }}
                                </span>
                              </b-col>
                            </b-row>
                            <b-row>
                              <b-col>
                                <strong class="mr-2">Team</strong>
                              </b-col>
                              <b-col>
                                <span
                                  class="cursor-pointer"
                                  v-if="project.team_name"
                                  @click="openModalDevelopmentTeam(project)"
                                >
                                  {{ project.team_name }}
                                </span>
                                <span v-else> - </span>
                              </b-col>
                            </b-row>
                            <b-row>
                              <b-col>
                                <strong class="mr-2">Due Date</strong>
                              </b-col>
                              <b-col>
                                <span v-if="item.id == 1"> - </span>
                                <span
                                  class="action-pending"
                                  v-else-if="
                                    [3, 4, 5].includes(item.id) &&
                                    !project.due_date
                                  "
                                >
                                  Undefined
                                </span>
                                <span v-else class="cursor-pointer">
                                  {{ project.due_date | myGlobal }}
                                </span>
                              </b-col>
                            </b-row>
                          </b-col>
                        </b-row>
                      </div>
                    </div>
                  </transition-group>
                </draggable>
              </div>
            </div>
          </template>
        </div>
        <!-- spiner-->
        <div
          v-if="loading"
          class="d-flex align-items-center justify-content-center h-100"
        >
          Loading...
          <b-spinner
            class="align-middle ml-1 font-weight-bolder"
            label="Loading..."
            variant="primary"
          ></b-spinner>
        </div>
      </div>
    </b-card>

    <modal-create-request
      v-if="showModalCreateRequest"
      @hidden="showModalCreateRequest = false"
      @refreshPGRequest="
        myProvider();
        showModalCreateRequest = false;
      "
    />

    <modal-tracking-request
      v-if="showModalTrackingRequest"
      :request-code="requestCode"
      :request-id="requestId"
      @hidden="showModalTrackingRequest = false"
    />

    <modal-update-request
      v-if="showModalUpdateRequest"
      :request-id="requestId"
      :request-code="requestCode"
      @hidden="showModalUpdateRequest = false"
      @refreshPGRequest="
        myProvider();
        showModalUpdateRequest = false;
      "
    />

    <modal-process-diagram
      v-if="showModalProcessDiagram"
      :request-code="requestCode"
      :request-id="requestId"
      :miro-code="miroCode"
      @hidden="showModalProcessDiagram = false"
      @refresh="myProvider()"
    />

    <modal-assign-team
      v-if="showModalAssignTeam"
      :code="currentRequestName"
      :pg-request-id="currentPgRequestId"
      :type="typeModalAssignTeam"
      :teamId="currentTeamId"
      @close="showModalAssignTeam = false"
      @refresh="refreshModalAsignTeam"
    />

    <modal-assign-design
      v-if="showModalAssignDesign"
      :code="currentRequestName"
      :pg-request-id="currentPgRequestId"
      :type="typeModalAssignDesign"
      :teamId="currentDesignId"
      @close="showModalAssignDesign = false"
      @refresh="refreshModalAsignTeam"
    />

    <modal-upload-files
      v-if="showModalUploadFiles"
      :code="currentRequestName"
      :pg-request-id="currentPgRequestId"
      :read-only="readOnlyFiles"
      @close="showModalUploadFiles = false"
    />

    <modal-assign-difficulty-request
      v-if="showModalAssignDifficultyRequest"
      :request-id="requestId"
      :request-code="requestCode"
      @hidden="showModalAssignDifficultyRequest = false"
      @refresh="
        myProvider();
        showModalAssignDifficultyRequest = false;
      "
    />

    <modal-request-details
      v-if="showModalRequestDetails"
      :request-id="requestId"
      :request-code="requestCode"
      @hidden="showModalRequestDetails = false"
      @refreshPGRequest="myProvider()"
    />

    <modal-binnacle-request
      v-if="showModalBinnacleRequest"
      @close="showModalBinnacleRequest = false"
      @hidden="showModalBinnacleRequest = false"
    />

    <modal-development-team
      v-if="showModalDevelopmentTeam"
      :show-type="2"
      :request-id="requestId"
      @close="showModalDevelopmentTeam = false"
      @hidden="showModalDevelopmentTeam = false"
    />

    <modal-all-projects
      v-if="showModalAllProjects"
      @hidden="showModalAllProjects = false"
    />

    <modal-assign-due-date
      v-if="showModalDueDate"
      :request-id="requestId"
      :request-code="requestCode"
      @hidden="showModalDueDate = false"
      @close="showModalDueDate = false"
      @updateDueDates="
        myProvider();
        showModalDueDate = false;
      "
    />

    <modal-add-links
      v-if="showModalAddLinks"
      :request-id="requestId"
      :request-code="requestCode"
      @hidden="showModalAddLinks = false"
      @close="showModalAddLinks = false"
    />
  </div>
</template>

<script>
import draggable from "vuedraggable";
import moment from "moment";
import ModalCreateRequest from "@/views/commons/components/paragon-soft/modals/ModalCreateRequest.vue";
import ModalTrackingRequest from "@/views/commons/components/paragon-soft/modals/ModalTrackingRequest.vue";
import ModalUpdateRequest from "@/views/commons/components/paragon-soft/modals/ModalUpdateRequest.vue";
import RequestService from "@/views/commons/components/paragon-soft/services/PGRequest.services.js";
import FeatherIcon from "@/@core/components/feather-icon/FeatherIcon.vue";
import MiroIconSvg from "@/views/commons/components/paragon-soft/components/MiroSvg.vue";
import ModalProcessDiagram from "@/views/commons/components/paragon-soft/modals/ModalProcessDiagram.vue";
import { mapGetters, mapMutations } from "vuex";
import ModalAssignTeam from "@/views/commons/components/paragon-soft/modals/ModalAssignTeam.vue";
import ModalUploadFiles from "@/views/commons/components/paragon-soft/components/ModalUploadFiles.vue";
import ModalAssignDesign from "@/views/commons/components/paragon-soft/modals/ModalAssignDesign.vue";
import ModalAssignDifficultyRequest from "@/views/commons/components/paragon-soft/modals/ModalAssignDifficultyRequest.vue";
import ModalRequestDetails from "@/views/commons/components/paragon-soft/modals/ModalRequestDetails.vue";
import PGRequestCounterService from "@/views/commons/components/paragon-soft/services/PGRequestActions.service.js";
import ModalBinnacleRequest from "@/views/commons/components/paragon-soft/modals/ModalBinnacleRequest.vue";
import ModalDevelopmentTeam from "@/views/commons/components/paragon-soft/modals/ModalDevelopmentTeam.vue";
import ModalAllProjects from "@/views/commons/components/paragon-soft/modals/ModalAllProjects.vue";
import ModalAssignDueDate from "@/views/commons/components/paragon-soft/modals/ModalAssignDueDate.vue";
import ModalAddLinks from "@/views/commons/components/paragon-soft/modals/ModalAddLinks.vue";

export default {
  name: "ParagonSoft",
  components: {
    ModalCreateRequest,
    draggable,
    ModalTrackingRequest,
    ModalUpdateRequest,
    FeatherIcon,
    MiroIconSvg,
    ModalProcessDiagram,
    ModalAssignTeam,
    ModalUploadFiles,
    ModalAssignDesign,
    ModalAssignDifficultyRequest,
    ModalRequestDetails,
    ModalBinnacleRequest,
    ModalDevelopmentTeam,
    ModalAllProjects,
    ModalAssignDueDate,
    ModalAddLinks,
  },
  filters: {
    formattedDate: function (value, format = "MMMM DD, YYYY") {
      if (!value) return "-";
      return moment(value).format(format);
    },
  },
  data() {
    return {
      showModalCreateRequest: false,
      showModalTrackingRequest: false,
      showModalUpdateRequest: false,
      showModalProcessDiagram: false,
      currentDesignId: 0,
      showModalAssignTeam: false,
      showModalUploadFiles: false,
      showModalAssignDesign: false,
      showModalDevelopmentTeam: false,
      showModalAllProjects: false,
      showModalDueDate: false,
      showModalAddLinks: false,
      requests: [],

      currentRequestName: "",
      currentPgRequestId: null,
      currentTeamId: 0,
      typeModalAssignTeam: 1,
      typeModalAssignDesign: 1,

      objectCaptureItemData: {},
      oldStatusId: null,
      showModalAssignDifficultyRequest: false,
      showModalRequestDetails: false,
      showModalBinnacleRequest: false,
      readOnlyFiles: false,
      loading: false,
    };
  },
  computed: {
    moduleId() {
      return this.$route.matched[0].meta.module;
    },
    dragOptions() {
      return {
        animation: 200,
        group: "cards",
        disabled: false,
        ghostClass: "ghost",
      };
    },

    ...mapGetters({
      currentUser: "auth/currentUser",
      G_CURRENT_CHAT_ID: "SoftProjectsBinnacleStore/G_CURRENT_CHAT_ID",
    }),

    isSupervisorDigital() {
      return (
        this.currentUser.role_id == 2 &&
        this.currentUser.main_module == 12 &&
        this.currentUser.eng_soft != 1
      );
    },

    isSupervisorSoft() {
      return this.currentUser.role_id == 2 && this.currentUser.eng_soft == 1;
    },
  },
  mounted() {
    this.myProvider(true);
  },
  methods: {
    ...mapMutations({
      M_SET_CURRENT_CHAT_ID: "SoftProjectsBinnacleStore/M_SET_CURRENT_CHAT_ID",
    }),
    async refreshCounter() {
      const { data } = await PGRequestCounterService.show(
        this.currentUser.user_id
      );
      const payload = {
        routeName: "paragon-soft",
        tag: data[0].total,
      };
      this.$store.commit("SidebarStore/UPDATE_SIDEBAR_ITEM_PROPERTY", payload);
    },
    async myProvider(isRefresh = false) {
      try {
        this.loading = isRefresh;
        const params = {
          role_id: this.currentUser.role_id,
          user_id: this.currentUser.user_id,
        };
        const { data } = await RequestService.getAllRequest(params);
        this.requests = data;
        this.refreshCounter();
      } catch (error) {
        this.showErrorSwal(error);
      } finally {
        this.loading = false;
      }
    },
    async sendToParagon(requestId) {
      try {
        const { isConfirmed } = await this.showConfirmSwal(
          "Are you sure?",
          "Are you sure to send the request to Paragon?"
        );
        if (!isConfirmed) {
          this.myProvider();
          return;
        }
        this.addPreloader();
        const params = {
          status_id: 3,
          created_by: this.currentUser.user_id,
        };
        await this.updatePriorityRequest(params.status_id, 0);
        const { data } = await RequestService.changeStatusRequest(
          requestId,
          params
        );
        this.showToast(
          "success",
          "top-right",
          "Request Sent",
          "CheckIcon",
          `${data.message}`
        );
        this.myProvider();
      } catch (error) {
        this.showErrorSwal(error);
      } finally {
        this.removePreloader();
      }
    },
    async changeStatusRequest(requestId, statusId) {
      try {
        const { isConfirmed } = await this.showConfirmSwal();
        if (!isConfirmed) {
          this.myProvider();
          return;
        }
        this.addPreloader();
        const params = {
          status_id: statusId,
          created_by: this.currentUser.user_id,
        };
        const { data } = await RequestService.changeStatusRequest(
          requestId,
          params
        );
        this.showToast(
          "success",
          "top-right",
          "Request Sent",
          "CheckIcon",
          `${data.message}`
        );
        this.myProvider();
      } catch (error) {
        this.showErrorSwal(error);
      } finally {
        this.removePreloader();
      }
    },
    handleStartListChange(item, event) { 
      this.objectCaptureItemData = {
        old_priority: event.item._underlying_vm_.priority,
        old_status: item.id,
        request_id: event.item._underlying_vm_.id,
      };
      // change status request
      this.oldStatusId = item.id;
    },
    async handleAddListChange(event, item) {
      await this.validationsToChangeStates(event, item);
    },
    async validationsToChangeStates(event, item) {
      const newStatusId = item.id;
      const requestId = event.item._underlying_vm_.id;

      //validation to not be able to change the status to a lower one
      if (this.oldStatusId > newStatusId) { 
        if(newStatusId == 1 && this.oldStatusId == 3 && event.item._underlying_vm_.due_date == null ){  
          await this.updatePriorityRequest(1, event.newIndex);
          this.myProvider();
          return ;
        }
        this.showWarningToast("You can't change the status to a lower one");
        this.myProvider();
        return;
      }

      // send to paragon only if the status is 1 and the new status is 3 and the user is ceo
      if (this.oldStatusId == 1 && newStatusId == 3 && this.isCeo) {
        await this.sendToParagon(requestId);
        return;
      }
      // other status and is chief by paragon
      if (
        (this.oldStatusId != 1 && this.isChief && this.moduleId == 12) ||
        (this.oldStatusId == 4 && this.isCeo)
      ) {
        if (this.oldStatusId + 1 != newStatusId) {
          this.showWarningToast("You cannot skip a state");
          this.myProvider();
          return;
        }
        //validations to change state
        if (this.oldStatusId == 3) {
          const requiredFields = {
            team_id: "developer team",
            team_design_id: "design team",
            difficulty_id: "difficulty",
            due_date: "due date",
          };
          const missingFields = Object.keys(requiredFields).filter(
            (field) => !event.item._underlying_vm_[field]
          );

          if (missingFields.length > 0) {
            const missingFieldNames = missingFields.map(
              (field) => requiredFields[field]
            );
            const missingFieldsMessage = missingFieldNames.join(", ");
            const warningMessage = `You can't change the status to ${item.name} because the request doesn't have a ${missingFieldsMessage}`;
            this.showWarningToast(warningMessage);
            this.myProvider();
            return;
          }
        }
        if (this.oldStatusId == 4) {
          const requiredFields = {
            miro_link: "miro link",
          };
          const missingFields = Object.keys(requiredFields).filter(
            (field) => !event.item._underlying_vm_[field]
          );

          if (missingFields.length > 0) {
            const missingFieldNames = missingFields.map(
              (field) => requiredFields[field]
            );
            const missingFieldsMessage = missingFieldNames.join(", ");
            const warningMessage = `You can't change the status to ${item.name} because the request doesn't have a ${missingFieldsMessage}`;
            this.showWarningToast(warningMessage);
            this.myProvider();
            return;
          }
        }
        await this.changeStatusRequest(requestId, newStatusId);
        return;
      }

      this.showWarningToast("Role not allowed to perform this action");
      this.myProvider();
    },
    showWarningToast(message) {
      this.showToast(
        "warning",
        "top-right",
        "Warning",
        "AlertTriangleIcon",
        message
      );
    },
    async handleChangeListChange(item, index, event) {
      if (event.moved && item.id == 1 && this.isCeo) {
        if (event.moved.newIndex != event.moved.oldIndex) {
          await this.updatePriorityRequest(item.id, event.moved.newIndex);
          this.myProvider();
        }
      }
    },
    async updatePriorityRequest(status_id, index) {
      try {
        this.addPreloader();
        const params = {
          old_priority: this.objectCaptureItemData.old_priority,
          new_priority: index + 1,
          old_status: this.objectCaptureItemData.old_status,
          new_status: status_id, 
          request_id: this.objectCaptureItemData.request_id, 
        };
        const { data } = await RequestService.updatePriorityRequest(params);
      } catch (error) {
        this.showErrorSwal(error);
      } finally {
        this.removePreloader();
      }
    },
    getVariantDifficulty(difficulty_id) {
      let variant = ["", ""];
      switch (difficulty_id) {
        case 1:
          variant = ["light-primary", ""];
          break;
        case 2:
          variant = ["light-info", ""];
          break;
        case 3:
          variant = ["light-danger", ""];
          break;
      }
      return variant;
    },
    openModalTracking(item) {
      this.requestCode = item.code;
      this.requestId = item.id;
      this.showModalTrackingRequest = true;
    },
    openModalUpdateRequest(item) {
      this.requestCode = item.code;
      this.requestId = item.id;
      this.showModalUpdateRequest = true;
    },
    openModalShowDetail(item) {
      this.requestCode = item.code;
      this.requestId = item.id;
      this.showModalRequestDetails = true;
    },
    openModalProcessDiagram(item) {
      this.requestCode = item.code;
      this.requestId = item.id;
      this.miroCode = item.miro_code;
      this.showModalProcessDiagram = true;
    },
    openModalBinnacleRequest(project) {
      const binnacles = project.binnacle_id;
      project.pending_messages = 0;
      let binnacleTypeMapping = {
        30: "design", // designer
        1: "ceo", // ceo
        17: "ceo", //chief
        35: "development", //developer
      };

      let userRoleId = this.currentUser.role_id;
      let defaultBinnacleType = "default";
      let binnacle_id =
        binnacles.find(
          (b) =>
            b.binnacle_type === binnacleTypeMapping[userRoleId] ||
            b.binnacle_type === defaultBinnacleType
        )?.binnacle_id || binnacles[0].binnacle_id;

      this.M_SET_CURRENT_CHAT_ID(binnacle_id);
      this.showModalBinnacleRequest = true;
    },

    hasPermission(statusId) {
      if (statusId == 1 && this.isCeo) {
        return true;
      }
      if (statusId != 1 && !this.isCeo) {
        return true;
      }

      if (this.isSupervisor || this.currentUser.role_id == 35) {
        return true;
      }

      return false;
    },

    openModalAssingTeam(item) {
      this.currentRequestName = item.code;
      this.currentPgRequestId = item.id;
      this.typeModalAssignTeam = item.team_name ? 2 : 1;
      this.currentTeamId = item.team_id;
      this.showModalAssignTeam = true;
    },

    openModalAssingDesign(item) {
      this.currentRequestName = item.code;
      this.currentPgRequestId = item.id;
      this.typeModalAssignDesign = item.team_design_name ? 2 : 1;
      this.currentDesignId = item.team_design_id;
      this.showModalAssignDesign = true;
    },

    refreshModalAsignTeam() {
      this.myProvider();
      this.showModalAssignTeam = false;
      this.showModalAssignDesign = false;
    },

    openModalUploadFiles(status, item) {
      this.currentRequestName = item.code;
      this.currentPgRequestId = item.id;
      this.readOnlyFiles = status == 7;
      this.showModalUploadFiles = true;
    },

    openModalAssingDifficulty(item) {
      this.requestId = item.id;
      this.requestCode = item.code;
      this.showModalAssignDifficultyRequest = true;
    },

    openLinks(item) {
      this.requestId = item.id;
      this.requestCode = item.code;
      this.showModalAddLinks = true;
    },
    openModalDevelopmentTeam(item) {
      this.requestId = item.id;
      this.showModalDevelopmentTeam = true;
    },
    openModalDueDate(item) {
      this.requestId = item.id;
      this.requestCode = item.code;
      this.showModalDueDate = true;
    },

    async probationaryPeriod(item) {
      const { isConfirmed } = await this.showConfirmSwal(
        `Are you sure to ${
          item.probationary_period == 0 ? "Active" : "Deactivate"
        }?`,
        "You won't be able to revert this!"
      );
      if (!isConfirmed) return;

      const params = {
        request_id: item.id,
        probationary_period: item.probationary_period == 0 ? 1 : 0,
      };

      const { data, status } =
        await RequestService.updateProbationaryPeriodRequest(params);

      if (status == 200) {
        this.myProvider();
        this.showToast(
          "success",
          "top-right",
          "Request Sent",
          "CheckIcon",
          `${data.message}`
        );
      }
    },

    getCountPendingAction(item, status) {
      let count = 0;

      if (this.isChief) {
        if (item.team_id == null && [3].includes(status)) {
          count++;
        }

        if (item.team_design_id == null && [3].includes(status)) {
          count++;
        }

        if (item.difficulty_id == null && [1, 3].includes(status)) {
          count++;
        }
        if (item.due_date == null && [3, 4, 5].includes(status)) {
          count++;
        }
      }

      if (
        item.figma_link == null &&
        this.isSupervisorDigital &&
        [4].includes(status)
      ) {
        count++;
      }

      return count;
    },

    async deleteRequest(project) {
      const { id, title } = project;
      try {
        const { isConfirmed } = await this.showConfirmSwal(
          "Are you sure?",
          "",
          {
            html: `You won't be able to revert this! <br> <strong>${title}</strong>`,
          }
        );
        if (!isConfirmed) return;

        this.addPreloader();
        const params = {
          user_id: this.currentUser.user_id,
        };
        const { data } = await RequestService.destroyRequestById(id, params);
        this.showSuccessSwal(data.message);
        this.myProvider();
      } catch (error) {
        this.showErrorSwal(error);
      } finally {
        this.removePreloader();
      }
    },
    tooltipDifficulty(difficulty_id) {
      let tooltip = {
        1: "1 to 10 days",
        2: "11 to 21 days",
        3: "22 days or more",
      };
      return tooltip[difficulty_id];
    },
  },
};
</script>

<style lang="scss">
.pg-board-assign-priority {
  width: 100%;
  height: 75vh;
  overflow-y: scroll;
  .board-container {
    gap: 1rem;
    width: 100%;
    flex: 1 1 auto;
    .board {
      flex: 1;
      min-height: 75vh;
      min-width: 20.5rem;
      background-color: #e8e8e980;
      border-radius: 0.6rem;
      .board-header {
        margin-bottom: 0.5rem;
        position: sticky;
        top: 0;
        border-radius: 0.6rem 0.6rem 0 0;
        width: 100%;
        user-select: none;
        padding: 1rem;
        font-size: 1rem;
        font-weight: bolder;
        z-index: 1;
        background-color: #e8e8e980;
      }
      .board-content {
        width: 100%;
        padding: 0 0.5rem 0.5rem 0.5rem;
        height: 100%;
        .list-group {
          height: 100%;
          .transition-group {
            height: 100%;
            .list-group-item {
              width: 100%;
              background-color: #fff;
              border: none;
              cursor: grab;
              border-radius: 0.6rem;
              margin-bottom: 1rem;
              padding-left: 1.5rem;
              padding-right: 1.5rem;
              box-shadow: 0px 3px 8px 0px rgba(219, 219, 219, 1);
              .content-header {
                .dropdown {
                  button {
                    padding: 0 !important;
                  }
                }
                .btn {
                  color: #000;
                }
              }
            }
          }
        }
      }
      .sortable-chosen {
        border-radius: unset !important;
      }

      .ghost {
        border: 2px solid #0090e7 !important;
        border-radius: 0.6rem !important;
      }
    }
  }

  .container-actions {
    position: relative;
    display: flex;

    .count-actions-pending {
      position: absolute;
      top: -16px;
      right: -10px;
      border-radius: 50%;
      font-size: 11px;
      width: 16px;
      height: 16px;
      padding-right: 1px;
      color: #ffffff;
      display: flex;
      align-items: center;
      justify-content: center;
      background-color: #fc424a;
      font-weight: 600;
    }
  }

  .action-pending {
    animation: growup 1.5s ease-in-out infinite;
    top: calc(50% - 10px);
  }
  @keyframes growup {
    0% {
      transform: scale(1);
      color: #ff9f43;
    }
    50% {
      transform: scale(0.99);
      color: #fc3a31;
    }
    100% {
      transform: scale(1);
      color: #ff9f43;
    }
  }
  &::-webkit-scrollbar {
    width: 0.5rem !important;
    height: 0.5rem !important;
  }
}
.dark-layout {
  .pg-board-assign-priority {
    .board-container {
      .board {
        background-color: #161a1d;
        .board-header {
          background-color: #161a1d;
        }
        .board-content {
          .list-group {
            .transition-group {
              .list-group-item {
                background-color: #22272b;
                box-shadow: none;
                .content-header {
                  .btn {
                    color: unset;
                    &:hover {
                      color: #000;
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}

.bg-board-header-0 {
  color: #000 !important;
  background-color: #c2c2c3 !important;
}
.bg-board-header-1 {
  background-color: #fada90 !important;
  color: #000 !important;
}
.bg-board-header-2 {
  background-color: rgb(235, 197, 248) !important;
  color: #000 !important;
}
.bg-board-header-3 {
  background-color: #a8d0f9 !important;
  color: #000 !important;
}
.bg-board-header-4 {
  background-color: #f9f5a8 !important;
  color: #000 !important;
}
.bg-board-header-5 {
  background-color: #c6f9a8 !important;
  color: #000 !important;
}
</style>