<template>
  <div class="matched-container" v-if="matchedMessages != null">
    <span class="matched-messages ml-2"
      >Matched Messages ({{ matchedMessages.length }})</span
    >
    <perfect-scrollbar
      class="custom-scrollbar"
      v-if="matchedMessages.length > 0"
    >
      <template v-for="(chat, index) in matchedMessages">
        <div
          @click="goToSelectedMessage(chat)"
          class="d-flex align-items-center chat-box"
          :key="index"
        >
          <div class="d-flex align-items-top justify-content-between w-100">
            <div class="d-flex flex-column">
              <span class="chat-name">{{ chat.title }}</span>
              <span class="chat-last-message" v-html="highlightMessages(chat.message,chat.search_message,10)"  ></span>
            </div>
            <span class="chat-date d-flex">{{
              chat.message_at | myGlobalWithHour
            }}</span>
          </div>
        </div>
      </template>
    </perfect-scrollbar>
    <div v-else class="d-flex justify-content-center align-items-center h-100">
      <span class="text-center">No messages found</span>
    </div>
  </div>
</template>
<script>
import PerfectScrollbar from "vue-perfect-scrollbar";
import { mapGetters } from "vuex";
import { highlightLetters } from "@/helpers/highlightText.js";

export default {
  name: "ChatList",
  components: {
    PerfectScrollbar,
  },
  props: {
    matchedMessages: {
      type: Array | null,
      required: true,
    },
  },
  data() {
    return {
      currentBinnacleId: null,
    };
  },
  methods: {
    goToSelectedMessage(message) {
      this.$emit("goToSelectedMessage", message);
    },
    highlightMessages(text,searchMessage,lengthText){
      return highlightLetters(text,searchMessage,lengthText);
    }
  },
  computed: {
    ...mapGetters({
      currentUser: "auth/currentUser",
    }),
  },
};
</script>

<style lang="scss" scoped>
.matched-container {
  margin-top: 0.2rem;
  height: 75vh !important;
  min-height: 100%;
  max-height: 100%;
  overflow: hidden !important;

  .matched-messages {
    color: #9a9a9a;
    font-weight: 600;
  }
  .custom-scrollbar {
    width: 100%;
    height: 100%;
  }
  .current-chat {
    background-color: #eaeaea;
  }
  .chat-box {
    cursor: pointer;
    padding: 1rem 1.3rem;
    &:hover:not(.current-chat) {
      background-color: rgba(233, 236, 239, 0.3);
    }
  }
  .chat-name {
    font-weight: 600;
    font-size: 1.2rem;
    color: #626262;
  }
  .chat-date {
    font-size: 0.9rem;
    color: #9a9a9a;
  }
  .chat-last-message {
    font-size: 1.1rem;
    color: #9a9a9a;
  }
  .mr-05 {
    margin-right: 0.5rem !important;
  }
}
.dark-layout {
  .chat-container {
    .chat-box {
      &:hover:not(.current-chat) {
        background-color: rgba(94, 234, 212, 0.04);
      }
    }
    .current-chat {
      background-color: rgba(94, 234, 212, 0.24);
    }
    .chat-name {
      color: #fff;
    }
    .chat-date {
      color: rgba(255, 255, 255, 0.87);
    }
    .chat-last-message {
      color: rgba(255, 255, 255, 0.67);
    }
  }
}
</style>