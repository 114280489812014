export default [
  {
    key: "code",
    label: "Code",
    visible: true,
    thStyle: { width: "15%" },
  },
  {
    key: "title",
    label: "Title",
    visible: true,
  },
  {
    key: "team_id",
    label: "Team",
    class: "text-center",
    visible: false,
  },
  {
    key: "team_design_id",
    label: "designer",
    class: "text-center",
    visible: false,
  },
  {
    key: "difficulty",
    label: "Diff",
    sortable: true,
    thClass: "text-center",
    tdClass: "text-center",
    visible: true,
  },
  {
    key: "created_at",
    label: "Created At",
    sortable: true,
    thClass: "text-center",
    tdClass: "text-center",
    visible: true,
  },
  {
    key: "options",
    label: "Options",
    thClass: "text-center",
    tdClass: "text-center",
    visible: true,
  },
];
