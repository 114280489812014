var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('b-modal',{ref:"modal-create-request",attrs:{"size":"lg","no-close-on-backdrop":""},on:{"hidden":function($event){return _vm.$emit('hidden')}},scopedSlots:_vm._u([{key:"modal-title",fn:function(){return [_c('span',{staticClass:"text-uppercase font-weight-bolder"},[_vm._v("Create New Request")])]},proxy:true},{key:"default",fn:function(){return [_c('validation-observer',{ref:"formRequest"},[_c('b-row',[_c('b-col',{attrs:{"cols":"12"}},[_c('validation-provider',{attrs:{"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":"Title"}},[_c('b-form-input',{attrs:{"id":"title","state":errors[0] ? false : null},model:{value:(_vm.request.title),callback:function ($$v) {_vm.$set(_vm.request, "title", $$v)},expression:"request.title"}})],1)]}}])})],1),_c('b-col',{attrs:{"cols":"12"}},[_c('validation-provider',{attrs:{"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":"Description"}},[_c('quill-editor',{staticClass:"quill-content-request",class:errors[0] ? 'border-danger rounded' : '',attrs:{"id":"quil-content-request","options":_vm.editorOption},model:{value:(_vm.request.description),callback:function ($$v) {_vm.$set(_vm.request, "description", $$v)},expression:"request.description"}})],1)]}}])})],1),_c('b-col',{attrs:{"cols":"12"}},[_c('validation-provider',{attrs:{"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":"Modules"}},[_c('v-select',{style:(errors[0]
                      ? 'border: 1px solid red !important; border-radius: 5px !important;'
                      : ''),attrs:{"id":"modules","label":"name","multiple":"","options":_vm.allModules,"reduce":function (option) { return option.id; },"placeholder":"Select modules"},model:{value:(_vm.request.modules),callback:function ($$v) {_vm.$set(_vm.request, "modules", $$v)},expression:"request.modules"}})],1)]}}])})],1),_c('b-col',{attrs:{"cols":"12"}},[_c('b-form-group',{attrs:{"label":"Files"}},[_c('drag-and-drop',{key:_vm.key,ref:"request-files",staticClass:"w-100 mb-1",attrs:{"id":"files","filesArray":_vm.request.files},on:{"input":_vm.changeFiles},model:{value:(_vm.request.files),callback:function ($$v) {_vm.$set(_vm.request, "files", $$v)},expression:"request.files"}})],1)],1)],1)],1)]},proxy:true},{key:"modal-footer",fn:function(){return [_c('b-row',{staticClass:"d-flex justify-content-end"},[_c('b-button',{attrs:{"id":"saveRequest","variant":"primary"},on:{"click":function($event){return _vm.createNewRequest()}}},[_vm._v(" SAVE ")])],1)]},proxy:true}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }