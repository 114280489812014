<template>
  <div>
    <b-modal
      ref="modal-create-request"
      size="lg"
      @hidden="$emit('hidden')"
      no-close-on-backdrop
    >
      <template #modal-title>
        <span class="text-uppercase font-weight-bolder"
          >Create New Request</span
        >
      </template>
      <template #default>
        <validation-observer ref="formRequest">
          <b-row>
            <b-col cols="12">
              <validation-provider v-slot="{ errors }" rules="required">
                <b-form-group label="Title">
                  <b-form-input
                    id="title"
                    v-model="request.title"
                    :state="errors[0] ? false : null"
                  />
                </b-form-group>
              </validation-provider>
            </b-col>
            <b-col cols="12">
              <validation-provider v-slot="{ errors }" rules="required">
                <b-form-group label="Description">
                  <quill-editor
                    id="quil-content-request"
                    :options="editorOption"
                    v-model="request.description"
                    class="quill-content-request"
                    :class="errors[0] ? 'border-danger rounded' : ''"
                  />
                </b-form-group>
              </validation-provider>
            </b-col>
            <b-col cols="12">
              <validation-provider v-slot="{ errors }" rules="required">
                <b-form-group label="Modules">
                  <v-select
                    id="modules"
                    v-model="request.modules"
                    :style="
                      errors[0]
                        ? 'border: 1px solid red !important; border-radius: 5px !important;'
                        : ''
                    "
                    label="name"
                    multiple
                    :options="allModules"
                    :reduce="(option) => option.id"
                    placeholder="Select modules"
                  />
                </b-form-group>
              </validation-provider>
            </b-col>
            <b-col cols="12">
              <b-form-group label="Files">
                <drag-and-drop
                  id="files"
                  ref="request-files"
                  class="w-100 mb-1"
                  v-model="request.files"
                  :filesArray="request.files"
                  @input="changeFiles"
                  :key="key"
                />
              </b-form-group>
            </b-col>
          </b-row>
        </validation-observer>
      </template>
      <template #modal-footer>
        <b-row class="d-flex justify-content-end">
          <b-button
            id="saveRequest"
            variant="primary"
            @click="createNewRequest()"
          >
            SAVE
          </b-button>
        </b-row>
      </template>
    </b-modal>
  </div>
</template>
<script>
import DragAndDrop from "@/views/commons/utilities/DragAndDrop.vue";
import { mapGetters } from "vuex";
import helpdeskService from "@/views/commons/components/helpdesk/services/helpdesk.service";
import RequestService from "@/views/commons/components/paragon-soft/services/PGRequest.services.js";
import { quillEditor } from "vue-quill-editor";
import { F } from "vue-media-recorder";
import { forEach } from "postcss-rtl/lib/affected-props";
export default {
  components: {
    DragAndDrop,
    quillEditor,
  },
  data() {
    return {
      request: {
        title: "",
        description: "",
        status_id: 1,
        modules: [],
        files: [],
        created_by: "",
      },
      allModules: [],
      editorOption: {
        modules: {
          toolbar: true,
        },
        placeholder: "Description...",
      },
      key: 0,
    };
  },
  mounted() {
    this.toggleModal("modal-create-request");
    this.getAllModules();
    this.addPasteListener();
  },
  computed: {
    ...mapGetters({
      currentUser: "auth/currentUser",
    }),
  },
  methods: {
    async getAllModules() {
      try {
        this.addPreloader();
        const { data } = await helpdeskService.getModules();
        this.allModules = data;
      } catch (error) {
        this.showErrorSwal(error);
      } finally {
        this.removePreloader();
      }
    },

    async createNewRequest() {
      try {
        const isValid = await this.$refs.formRequest.validate();
        if (!isValid) return;
        this.addPreloader();
        const params = {
          title: this.request.title,
          description: this.request.description,
          status_id: this.request.status_id,
          modules: this.request.modules,
          files: this.request.files,
          created_by: this.currentUser.user_id,
        };

        const { data } = await RequestService.createNewRequest(params);
        this.showSuccessSwal(data.message);
        this.$emit("refreshPGRequest");
      } catch (error) {
        this.showErrorSwal(error);
      } finally {
        this.removePreloader();
      }
    },

    changeFiles(refresh = false) {
      this.request.files.map((file) => {
        file["file_name"] = file["name"];
        file["file_size"] = file["size"];
        file["created_by"] = this.currentUser.user_id;
      });
      if (refresh) this.key++;
    },

    filterFiles() {
      this.request.files = this.request.files.filter(
        (file, index, self) =>
          index === self.findIndex((t) => t.file_name === file.file_name)
      );
    },

    async handlePaste(event) {
      const items = (event.clipboardData || event.originalEvent.clipboardData)
        .items;
      for (const item of items) {
        if (item.type.indexOf("image") !== -1) {
          const blob = item.getAsFile();
          const newName = `Screenshot-${new Date().getTime()}.${
            blob.type.split("/")[1]
          }`;
          const file = new File([blob], newName, { type: blob.type }); // convert blob to file
          const imageBase64 = await this.blobToBase64(blob); // convert blob to base64
          file.fileapproved = imageBase64;
          this.request.files.push(file);
          this.changeFiles(true);
          break;
        }
      }
    },

    async blobToBase64(blob) {
      return new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.onload = () => {
          const imageBase64 = reader.result;
          resolve(imageBase64);
        };
        reader.onerror = (error) => {
          reject(error);
        };
        reader.readAsDataURL(blob);
      });
    },

    addPasteListener() {
      document.addEventListener("paste", this.handlePaste);
    },

    removeEventListener() {
      document.removeEventListener("paste", this.handlePaste);
    },
  },
  beforeDestroy() {
    this.removeEventListener();
  },
};
</script>
<style lang="scss">
.quill-content-request {
  .ql-container.ql-snow {
    height: 150px;
  }
}
</style>