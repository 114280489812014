import { amgApi } from '@/service/axios'

class PayRoll {

  async insertPayroll(body){
    try {
      const data = await amgApi.post('/management/pay-roll/insert-new-payroll', body)
      return data
    } catch (error) {
      throw error
    }
  }

  async updatePayroll(body){
    try {
      const data = await amgApi.post('/management/pay-roll/update-payroll', body)
      return data
    } catch (error) {
      throw error
    }
  }

  async getPayRolls(body) {
    try {
      const data = await amgApi.post('/management/pay-roll/search-pay-rolls', body)
      return data.data
    } catch (error) {
      throw error
    }
  }

  async updateStatusPayRollById(body){ //Done Payroll
    try {
      const data = await amgApi.post('/management/pay-roll/update-status-payroll-by-id', body)
      return data
    } catch (error) {
      throw error
    }
  }

  async cancelPayroll(body){
    try {
      const data = await amgApi.post('/management/pay-roll/cancel-payroll', body)
      return data
    } catch (error) {
      throw error
    }
  }

  async generateExcel(payroll_id){
    try {
      const {data} = await amgApi.get(`/management/pay-roll/generate-excel-payroll/${payroll_id}`,{ responseType: "blob", })
      return data
    } catch (error) {
      throw error
    }
  }

  async getDepartmentsPayrolls(body){
    try {
      const data = await amgApi.post('/management/pay-roll/get-departments-payroll', body)
      return data
    } catch (error) {
      throw error
    }
  }

  async getEmployeesDepartmentsPayrolls(body){
    try {
      const data = await amgApi.post('/management/pay-roll/get-employees-department-payroll', body)
      return data
    } catch (error) {
      throw error
    }
  }
  async doneEmployee(body){
    try {
      const data = await amgApi.post('/management/pay-roll/done-employee', body)
      return data
    } catch (error) {
      throw error
    }
  }
  async doneDepartment(body){
    try {
      const data = await amgApi.post('/management/pay-roll/done-department', body)
      return data
    } catch (error) {
      throw error
    }
  }

  async getMotivesPayment(body){
    try {
      const data = await amgApi.post('/management/pay-roll/get-motives-payment', body)
      return data
    } catch (error) {
      throw error
    }
  }

  async getPayrollById(body){
    try {
      const data = await amgApi.post('/management/pay-roll/get-payroll-by-id', body)
      return data
    } catch (error) {
      throw error
    }
  }

  async createMotivePayment(body){
    try {
      const data = await amgApi.post('/management/pay-roll/create-motive-payment', body)
      return data
    } catch (error) {
      throw error
    }
  }

  async savePayroll(body){
    try {
      const data = await amgApi.post('/management/pay-roll/save', body)
      return data
    } catch (error) {
      throw error
    }
  }

  async updateDollarExchangePayroll(body){
    try {
      const data = await amgApi.post('/management/pay-roll/update-dolar-exchange-payroll', body)
      return data
    } catch (error) {
      throw error
    }
  }

}

export default new PayRoll()
