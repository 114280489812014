import { amgApi } from "@/service/axios";

class PGRequestService {
  async getAllRequest(params) {
    try {
      const data = await amgApi.post("/paragon/request/index", params);
      return data;
    } catch (error) {
      throw error;
    }
  }

  async createNewRequest(params) {
    try {
      const data = await amgApi.post("/paragon/request/store", params);
      return data;
    } catch (error) {
      throw error;
    }
  }

  async getRequestById(params) {
    try {
      const data = await amgApi.get(`/paragon/request/show/${params}`);
      return data;
    } catch (error) {
      throw error;
    }
  }

  async updateRequest(params) {
    try {
      const data = await amgApi.post("/paragon/request/update", params);
      return data;
    } catch (error) {
      throw error;
    }
  }

  async insertNewFiles(params) {
    try {
      const data = await amgApi.post("paragon/request/files/store", params);
      return data;
    } catch (error) {
      throw error;
    }
  }

  async getFilesByRequest(pg_id) {
    try {
      const data = await amgApi.get(`paragon/request/files/index/${pg_id}`);
      return data;
    } catch (error) {
      throw error;
    }
  }

  async destroyFileById(file_id) {
    try {
      const data = await amgApi.post(
        `paragon/request/files/destroy/${file_id}`
      );
      return data;
    } catch (error) {
      throw error;
    }
  }

  async assignDifficultyRequestById(params) {
    try {
      const data = await amgApi.post(
        "paragon/request/difficulty/store",
        params
      );
      return data;
    } catch (error) {
      throw error;
    }
  }

  async getRequestTrackingById(params) {
    try {
      const data = await amgApi.get(`paragon/request/tracking/${params}`);
      return data;
    } catch (error) {
      throw error;
    }
  }

  async changeStatusRequest(id, params) {
    try {
      const data = await amgApi.post(
        `paragon/request/status/update/${id}`,
        params
      );
      return data;
    } catch (error) {
      throw error;
    }
  }

  async createMiroTemplates(params) {
    try {
      const data = await amgApi.post(
        "paragon/request/miro-integration/store",
        params
      );
      return data;
    } catch (error) {
      throw error;
    }
  }

  async updatePriorityRequest(params) {
    try {
      const data = await amgApi.post("paragon/request/priority/update", params);
      return data;
    } catch (error) {
      throw error;
    }
  }

  async updateProbationaryPeriodRequest(params) {
    try {
      const data = await amgApi.post(
        "paragon/request/probationary-period/update",
        params
      );
      return data;
    } catch (error) {
      throw error;
    }
  }

  async getAllRequestByStatus(params) {
    try {
      const data = await amgApi.post("/paragon/request/status/index", params);
      return data;
    } catch (error) {
      throw error;
    }
  }

  async destroyRequestById(id, params) {
    try {
      const data = await amgApi.post(`/paragon/request/destroy/${id}`, params);
      return data;
    } catch (error) {
      throw error;
    }
  }

  async getDueDates(params) {
    try {
      const data = await amgApi.post("paragon/request/due-date/index", params);
      return data;
    } catch (error) {
      throw error;
    }
  }

  async saveDueDates(params) {
    try {
      const data = await amgApi.post("paragon/request/due-date/update", params);
      return data;
    } catch (error) {
      throw error;
    }
  }

  async getLinks(params) {
    try {
      const { data } = await amgApi.post("paragon/request/link/index", params);
      return data;
    } catch (error) {
      throw error;
    }
  }

  async saveLink(params) {
    try {
      const data = await amgApi.post("paragon/request/link/store", params);
      return data;
    } catch (error) {
      throw error;
    }
  }

  async removeLink(params) {
    try {
      const data = await amgApi.post("paragon/request/link/destroy", params);
      return data;
    } catch (error) {
      throw error;
    }
  }
}
export default new PGRequestService();
