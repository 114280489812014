<template>
  <b-modal
    @hidden="$emit('close')"
    v-model="show"
    :title="titleModal"
    hide-footer
    scrollable
  >
    <div class="pg-teams-container">

      <div v-if="currentTeamAssigned" class="team-container border-primary">
        <b-row>
          <b-col cols="12">
            <div class="w-100 d-flex justify-content-between">
              <div class="name">
                Current Team: {{ currentTeamAssigned.name }}
              </div>
              <div class="number-members">
                {{ currentTeamAssigned.team_details.length }} members
              </div>
            </div>
            <div class="mt-1 d-flex overflow-auto">
              <div
                v-for="(member, index) in currentTeamAssigned.team_details"
                :key="`mem2-${index}`"
              >
                <b-avatar
                  variant="dark"
                  :src="member.image"
                  class="mr-1"
                  size="6rem"
                ></b-avatar>
                <div
                  class="text-center"
                  style="margin-top: 5px"
                  v-b-tooltip.hover
                  :title="member.name"
                >
                  {{ member.name | limitChars(10) }}
                </div>
                <div
                  class="text-center"
                  style="margin-top: 5px"
                  v-b-tooltip.hover
                  :title="member.role"
                >
                  {{ member.role | limitChars(10) }}
                </div>
              </div>
            </div>
          </b-col>
        </b-row>
      </div>

      <div v-if="teams.length > 0">
        <div v-for="team in teams" :key="team.id">
          <div v-if="team.id != teamId" class="team-container">
            <b-row>
              <b-col cols="9">
                <div class="w-100 d-flex justify-content-between">
                  <div class="name">{{ team.name }}</div>
                  <div class="number-members">
                    {{ team.team_details.length }} members
                  </div>
                </div>
                <div class="mt-1 d-flex overflow-auto">
                  <div
                    v-for="(member, index) in team.team_details"
                    :key="`mem-${index}`"
                  >
                    <b-avatar
                      variant="dark"
                      :src="member.image"
                      class="mr-1"
                      size="6rem"
                    ></b-avatar>
                    <div
                      class="text-center"
                      style="margin-top: 5px"
                      v-b-tooltip.hover
                      :title="member.name"
                    >
                      {{ member.name | limitChars(10) }}
                    </div>
                    <div
                      class="text-center"
                      style="margin-top: 5px"
                      v-b-tooltip.hover
                      :title="member.role"
                    >
                      {{ member.role | limitChars(10) }}
                    </div>
                  </div>
                </div>
              </b-col>
              <b-col cols="3">
                <div class="status-team">
                  <div class="w-100" style="min-width: 100px">
                    <div
                      class="text-center"
                      :class="team.projects ? 'text-warning' : 'text-success'"
                    >
                      {{
                        team.projects
                          ? "Team in other development"
                          : "Available team"
                      }}
                    </div>
                    <div class="w-100 text-center container-others-projects">
                      <b-badge
                        v-for="(project, index) in team.projects"
                        :key="`proj-${index}`"
                        variant="light-warning"
                        class="cursor-pointer"
                        style="margin-top: 5px; margin-right: 4px"
                        v-b-tooltip.hover
                        :title="project.status"
                        >{{ project.title }}</b-badge
                      >
                    </div>
                  </div>
                  <b-button
                    :id="`asignTeam${team.id}`"
                    block
                    variant="outline-primary"
                    class="mt-1"
                    @click="assignTeamToRequest(team.id)"
                  >
                    <feather-icon icon="CheckCircleIcon"></feather-icon>
                    Assign</b-button
                  >
                </div>
              </b-col>
            </b-row>
          </div>
        </div>
      </div>
      <div v-else>
        <b-row>
          <b-col cols="12">
            <div class="w-100 text-center py-2">
              <div class="name">No teams available</div>
              <b-button @click="goToSettings" class="mt-1">
                <feather-icon icon="PlusIcon"></feather-icon> Create</b-button
              >
            </div>
          </b-col>
        </b-row>
      </div>
    </div>
  </b-modal>
</template>

<script>
import TeamsService from "@/views/paragon/views/settings/service/teams.service.js";
import { mapGetters } from "vuex";
export default {
  props: {
    pgRequestId: {
      type: [String, Number],
      required: true,
    },
    code: {
      type: String,
      required: true,
    },
    type: {
      type: Number,
      required: true,
    },
    teamId: {
      type: Number,
      required: false,
    },
  },

  computed: {
    titleModal() {
      let title = `ASSIGN TEAM TO ${this.code}`;
      if (this.type == 2) {
        title = `CHANGE TEAM TO ${this.code}`;
      }

      return title;
    },

    ...mapGetters({
      currenUser: "auth/currentUser",
    }),
  },

  data() {
    return {
      show: true,
      teams: [],
      currentTeamAssigned: null,
      statusWhiteMarch: false,
    };
  },

  methods: {
    goToSettings() {
      const routeName = "paragon-assign-teams";
      const route = this.$router.resolve({ name: routeName }).href;
      const newWindows = window.open("", "_blank");
      newWindows.location.href = route;
      newWindows.focus();
    },

    async getAllTeams() {
      const { data } = await TeamsService.getAll(1);
      this.teams = data.data;
      this.currentTeamAssigned = this.teams.find(
        (item) => item.id == this.teamId
      );
    },

    async assignTeamToRequest(teamId) {
      const { isConfirmed } = await this.showConfirmSwal(
        "Are you sure?",
        "Are you sure to assign the project to this team?"
      );
      if (!isConfirmed) {
        return;
      }

      try {
        this.addPreloader();

        const params = {
          pg_request_id: this.pgRequestId,
          team_id: teamId,
          user_id: this.currenUser.user_id,
        };

        const { data, status } = await TeamsService.assignTeamToRequest(params);
        if (status == 200) {
          this.$emit("refresh");
          this.showSuccessSwal();
        }
      } catch (error) {
        this.showErrorSwal(error);
      } finally {
        this.removePreloader();
      }
    },
  },

  created() {
    this.getAllTeams();
  },
};
</script>

<style lang="scss">
.pg-teams-container {
  .container-march {
    border: 1px solid #0090e7;
    border-radius: 10px;
  }

  .team-container {
    background-color: #fafafc;
    min-height: 200px;
    margin-bottom: 10px;
    border-radius: 20px;
    padding: 30px;
    box-shadow: 0 4px 24px 0 rgba(34, 41, 47, 0.1);

    .name {
      font-size: 20px;
      font-weight: 600;
    }

    .status-team {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      align-items: center;
      border-left: 1px dashed #c7c7c7;
      height: 100%;
      padding-left: 20px;

      .container-others-projects {
        overflow-x: auto;
        scrollbar-width: thin;
        padding-bottom: 6px;
      }

      .container-others-projects::-webkit-scrollbar  {
        width: 1px;
        height: 7px;
      }
    }
  }

  .march-option {
    display: flex;
    height: 70px;
    width: 70px;
    justify-content: center;
    align-items: center;
    padding: 10px;
    border-radius: 10px;
    cursor: pointer;
  }

  .border-active-march {
    border: 1px solid #0090e7;
  }
}

.dark-layout {
  .pg-teams-container {
    .container-march {
      border: 1px solid #0090e7;
    }

    .team-container {
      background-color: #26262e;

      .status-team {
        border-left: 1px solid #585858;
      }
    }

    .border-active-march {
      border: 1px solid #0090e7;
    }
  }
}
</style>