var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('header-slot'),_c('b-card',[_c('div',{staticClass:"w-full d-flex justify-content-between align-items-center mb-1"},[_c('b-button',{attrs:{"variant":"primary"},on:{"click":function($event){return _vm.myProvider(true)}}},[_vm._v(" Refresh "),_c('feather-icon',{attrs:{"icon":"RefreshCwIcon"}})],1),(_vm.moduleId == 16 && _vm.isCeo)?[_c('b-button',{staticClass:"text-black",staticStyle:{"margin-right":"5px"},attrs:{"variant":"success"},on:{"click":function($event){_vm.showModalCreateRequest = true}}},[_vm._v(" NEW PROJECT "),_c('feather-icon',{attrs:{"icon":"PlusIcon"}})],1)]:_vm._e()],2),_c('div',{staticClass:"pg-board-assign-priority"},[(!_vm.loading)?_c('div',{staticClass:"board-container d-flex flex-row"},[_vm._l((_vm.requests),function(item,index){return [_c('div',{key:index,staticClass:"board d-flex flex-column"},[_c('div',{staticClass:"board-header d-flex align-items-center justify-content-between",class:("bg-board-header-" + (_vm.isCeo ? index : index + 1))},[_c('div',[_vm._v(_vm._s(item.name)+" ("+_vm._s(item.total_projects)+")")]),(
                  item.total_projects != item.projects.length && item.id == 7
                )?_c('div',{staticClass:"cursor-pointer text-black",on:{"click":function($event){_vm.showModalAllProjects = true}}},[_vm._v(" show more ")]):_vm._e()]),_c('div',{staticClass:"board-content"},[_c('draggable',_vm._b({staticClass:"list-group",attrs:{"tag":"span","scroll-sensitivity":300,"force-fallback":true},on:{"start":function($event){return _vm.handleStartListChange(item, $event)},"add":function($event){return _vm.handleAddListChange($event, item)},"change":function($event){return _vm.handleChangeListChange(item, index, $event)}},model:{value:(item.projects),callback:function ($$v) {_vm.$set(item, "projects", $$v)},expression:"item.projects"}},'draggable',_vm.dragOptions,false),[_c('transition-group',{staticClass:"transition-group",attrs:{"type":"transition","name":"flip-list","tag":"div"}},_vm._l((item.projects),function(project,index){return _c('div',{key:project + index,staticClass:"list-group-item"},[_c('div',{staticClass:"content-header pt-1"},[_c('div',{staticClass:"d-flex justify-content-between align-items-center pb-1"},[_c('span',{staticClass:"text-secondary"},[_c('feather-icon',{attrs:{"icon":"CalendarIcon"}}),_vm._v(" "+_vm._s(_vm._f("formattedDate")(project.created_at))+" ")],1),(_vm.hasPermission(item.id))?_c('div',{staticClass:"custom-padding chat-header-icon"},[_c('b-dropdown',{attrs:{"size":"xs","variant":"link","toggle-class":"text-decoration-none","no-caret":""},scopedSlots:_vm._u([{key:"button-content",fn:function(){return [_c('div',{staticClass:"container-actions"},[_c('feather-icon',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.hover",modifiers:{"hover":true}}],staticClass:"cursor-pointer",attrs:{"title":"More options","icon":"MoreVerticalIcon"}}),(
                                    _vm.getCountPendingAction(project, item.id) >
                                    0
                                  )?_c('div',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.hover",modifiers:{"hover":true}}],staticClass:"count-actions-pending",attrs:{"title":"Actions pending"}},[_vm._v(" "+_vm._s(_vm.getCountPendingAction(project, item.id))+" ")]):_vm._e()],1)]},proxy:true}],null,true)},[(_vm.isCeo && item.id == 1)?_c('b-dropdown-item',{on:{"click":function($event){return _vm.openModalUpdateRequest(project)}}},[_c('feather-icon',{attrs:{"icon":"EditIcon"}}),_c('span',{staticStyle:{"margin-top":"7px","margin-left":"5px"}},[_vm._v("Edit")])],1):_vm._e(),(_vm.isChief && _vm.moduleId == 12 && item.id == 3)?_c('b-dropdown-item',{on:{"click":function($event){return _vm.openModalAssingDifficulty(project)}}},[_c('div',{class:!project.difficulty_id
                                    ? 'action-pending'
                                    : ''},[_c('feather-icon',{attrs:{"icon":"ChevronsUpIcon"}}),_c('span',{staticStyle:{"margin-top":"7px","margin-left":"5px"}},[_vm._v(_vm._s(!project.difficulty ? "Assign" : "Change")+" Difficulty")])],1)]):_vm._e(),(
                                _vm.isChief &&
                                _vm.moduleId == 12 &&
                                [3, 4, 5].includes(item.id)
                              )?_c('b-dropdown-item',{on:{"click":function($event){return _vm.openModalDueDate(project)}}},[_c('div',{class:!project.due_date ? 'action-pending' : ''},[_c('feather-icon',{attrs:{"icon":"ClockIcon"}}),_c('span',{staticStyle:{"margin-top":"7px","margin-left":"5px"}},[_vm._v(_vm._s(!project.due_date ? "Assign" : "Change")+" Due Date")])],1)]):_vm._e(),(_vm.isChief && [2, 3].includes(item.id))?_c('b-dropdown-item',{on:{"click":function($event){return _vm.openModalAssingTeam(project)}}},[_c('div',{class:!project.team_id ? 'action-pending' : ''},[_c('feather-icon',{attrs:{"icon":"CodeIcon"}}),_c('span',{staticStyle:{"margin-top":"7px","margin-left":"5px"}},[_vm._v(_vm._s(project.team_id ? "Change" : "Assign")+" Developer Team")])],1)]):_vm._e(),(
                                _vm.isChief &&
                                [3, 4, 5].includes(item.id) &&
                                project.probationary_period == 0
                              )?_c('b-dropdown-item',{on:{"click":function($event){return _vm.probationaryPeriod(project)}}},[_c('feather-icon',{attrs:{"icon":"FlagIcon"}}),_c('span',{staticStyle:{"margin-top":"7px","margin-left":"5px"}},[_vm._v(_vm._s(project.probationary_period == 0 ? "Activate" : "Deactivate")+" Trial Period")])],1):_vm._e(),(
                                (_vm.isSupervisorDigital || _vm.isChief) &&
                                item.id == 4
                              )?_c('b-dropdown-item',{on:{"click":function($event){return _vm.openLinks(project)}}},[_c('div',[_c('feather-icon',{attrs:{"icon":"LinkIcon"}}),_c('span',{staticStyle:{"margin-top":"7px","margin-left":"5px"}},[_vm._v(" Links ")])],1)]):_vm._e(),(_vm.isCeo && item.id == 1)?_c('b-dropdown-item',{on:{"click":function($event){return _vm.deleteRequest(project)}}},[_c('feather-icon',{staticClass:"text-danger",attrs:{"icon":"TrashIcon"}}),_c('span',{staticClass:"text-danger",staticStyle:{"margin-top":"7px","margin-left":"5px"}},[_vm._v(" Delete ")])],1):_vm._e()],1)],1):_vm._e()]),_c('div',{staticClass:"d-flex align-items-center"},[_c('div',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.hover",modifiers:{"hover":true}}],staticClass:"text-truncate cursor-pointer",attrs:{"title":project.title},on:{"click":function($event){return _vm.openModalShowDetail(project)}}},[_c('strong',{staticClass:"text-uppercase"},[_vm._v(" "+_vm._s(project.title)+" ")]),_c('div',{staticClass:"d-flex align-items-center gap-10"},[_c('p',{staticClass:"text-secondary"},[_vm._v(_vm._s(project.code))]),(project.probationary_period == 1)?_c('b-badge',{staticClass:"lh-0 mb-1",staticStyle:{"padding":"7px"},attrs:{"size":"sm","variant":"light-warning"}},[_vm._v(" Trial Period ")]):_vm._e()],1)])]),_c('div',{staticClass:"d-flex align-items-center justify-content-center"},[_c('b-button',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.hover",modifiers:{"hover":true}}],class:project.pending_messages > 0 ? 'mr-1' : '',staticStyle:{"background-color":"transparent","border":"none","padding":"0"},attrs:{"variant":"outline-light","size":"sm","title":"Open Chat"},on:{"click":function($event){return _vm.openModalBinnacleRequest(project)}}},[_c('feather-icon',{class:project.pending_messages > 0
                                ? 'text-danger'
                                : 'text-primary',attrs:{"badge":project.pending_messages > 0
                                ? project.pending_messages
                                : '',"badgeClasses":project.pending_messages > 0
                                ? 'badge badge-danger badge-pill'
                                : '',"icon":"MessageCircleIcon","size":"25"}})],1),(
                            item.id != 1 &&
                            [1, 2, 35, 17].includes(_vm.currentUser.role_id)
                          )?_c('b-button',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.hover",modifiers:{"hover":true}}],staticClass:"ml-1",staticStyle:{"background-color":"transparent","border":"none","padding":"0"},attrs:{"variant":"outline-light","size":"sm","title":project.miro_code
                              ? 'Show Miro Process Diagram'
                              : 'Generate Miro Process Diagram'},on:{"click":function($event){return _vm.openModalProcessDiagram(project)}}},[_c('miro-icon-svg',{attrs:{"size":25,"miro-code":project.miro_code}})],1):_vm._e(),_c('b-button',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.hover",modifiers:{"hover":true}}],staticClass:"ml-1",staticStyle:{"background-color":"transparent","border":"none","padding":"0"},attrs:{"variant":"outline-light","size":"sm","title":"Links"},on:{"click":function($event){return _vm.openLinks(project)}}},[_c('feather-icon',{attrs:{"icon":"LinkIcon","size":"25"}})],1),_c('b-button',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.hover",modifiers:{"hover":true}}],staticClass:"ml-1",staticStyle:{"background-color":"transparent","border":"none","padding":"0"},attrs:{"variant":"outline-light","size":"sm","title":"Files"},on:{"click":function($event){return _vm.openModalUploadFiles(item.id, project)}}},[_c('feather-icon',{attrs:{"icon":"FileIcon","size":"25"}})],1),_c('b-button',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.hover",modifiers:{"hover":true}}],staticClass:"ml-1",staticStyle:{"background-color":"transparent","border":"none","padding":"0"},attrs:{"variant":"outline-light","size":"sm","title":"Tracking"},on:{"click":function($event){return _vm.openModalTracking(project)}}},[_c('feather-icon',{attrs:{"icon":"AlignJustifyIcon","size":"25"}})],1)],1)]),_c('div',{staticClass:"content-body pt-1"},[_c('b-row',[_c('b-col',[_c('b-row',[_c('b-col',[_c('strong',{staticClass:"mr-2"},[_vm._v("Difficulty")])]),_c('b-col',[(project.difficulty_id)?_c('b-badge',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.hover.auto",value:(
                                  _vm.tooltipDifficulty(project.difficulty_id)
                                ),expression:"\n                                  tooltipDifficulty(project.difficulty_id)\n                                ",modifiers:{"hover":true,"auto":true}}],attrs:{"variant":_vm.getVariantDifficulty(
                                    project.difficulty_id
                                  )[0],"size":"sm"}},[_vm._v(" "+_vm._s(project.difficulty)+" ")]):_c('span',[_vm._v(" - ")])],1)],1),_c('b-row',[_c('b-col',[_c('strong',{staticClass:"mr-2"},[_vm._v("Created By")])]),_c('b-col',[_c('span',[_vm._v(" "+_vm._s(project.created_by)+" ")])])],1),_c('b-row',[_c('b-col',[_c('strong',{staticClass:"mr-2"},[_vm._v("Team")])]),_c('b-col',[(project.team_name)?_c('span',{staticClass:"cursor-pointer",on:{"click":function($event){return _vm.openModalDevelopmentTeam(project)}}},[_vm._v(" "+_vm._s(project.team_name)+" ")]):_c('span',[_vm._v(" - ")])])],1),_c('b-row',[_c('b-col',[_c('strong',{staticClass:"mr-2"},[_vm._v("Due Date")])]),_c('b-col',[(item.id == 1)?_c('span',[_vm._v(" - ")]):(
                                  [3, 4, 5].includes(item.id) &&
                                  !project.due_date
                                )?_c('span',{staticClass:"action-pending"},[_vm._v(" Undefined ")]):_c('span',{staticClass:"cursor-pointer"},[_vm._v(" "+_vm._s(_vm._f("myGlobal")(project.due_date))+" ")])])],1)],1)],1)],1)])}),0)],1)],1)])]})],2):_vm._e(),(_vm.loading)?_c('div',{staticClass:"d-flex align-items-center justify-content-center h-100"},[_vm._v(" Loading... "),_c('b-spinner',{staticClass:"align-middle ml-1 font-weight-bolder",attrs:{"label":"Loading...","variant":"primary"}})],1):_vm._e()])]),(_vm.showModalCreateRequest)?_c('modal-create-request',{on:{"hidden":function($event){_vm.showModalCreateRequest = false},"refreshPGRequest":function($event){_vm.myProvider();
      _vm.showModalCreateRequest = false;}}}):_vm._e(),(_vm.showModalTrackingRequest)?_c('modal-tracking-request',{attrs:{"request-code":_vm.requestCode,"request-id":_vm.requestId},on:{"hidden":function($event){_vm.showModalTrackingRequest = false}}}):_vm._e(),(_vm.showModalUpdateRequest)?_c('modal-update-request',{attrs:{"request-id":_vm.requestId,"request-code":_vm.requestCode},on:{"hidden":function($event){_vm.showModalUpdateRequest = false},"refreshPGRequest":function($event){_vm.myProvider();
      _vm.showModalUpdateRequest = false;}}}):_vm._e(),(_vm.showModalProcessDiagram)?_c('modal-process-diagram',{attrs:{"request-code":_vm.requestCode,"request-id":_vm.requestId,"miro-code":_vm.miroCode},on:{"hidden":function($event){_vm.showModalProcessDiagram = false},"refresh":function($event){return _vm.myProvider()}}}):_vm._e(),(_vm.showModalAssignTeam)?_c('modal-assign-team',{attrs:{"code":_vm.currentRequestName,"pg-request-id":_vm.currentPgRequestId,"type":_vm.typeModalAssignTeam,"teamId":_vm.currentTeamId},on:{"close":function($event){_vm.showModalAssignTeam = false},"refresh":_vm.refreshModalAsignTeam}}):_vm._e(),(_vm.showModalAssignDesign)?_c('modal-assign-design',{attrs:{"code":_vm.currentRequestName,"pg-request-id":_vm.currentPgRequestId,"type":_vm.typeModalAssignDesign,"teamId":_vm.currentDesignId},on:{"close":function($event){_vm.showModalAssignDesign = false},"refresh":_vm.refreshModalAsignTeam}}):_vm._e(),(_vm.showModalUploadFiles)?_c('modal-upload-files',{attrs:{"code":_vm.currentRequestName,"pg-request-id":_vm.currentPgRequestId,"read-only":_vm.readOnlyFiles},on:{"close":function($event){_vm.showModalUploadFiles = false}}}):_vm._e(),(_vm.showModalAssignDifficultyRequest)?_c('modal-assign-difficulty-request',{attrs:{"request-id":_vm.requestId,"request-code":_vm.requestCode},on:{"hidden":function($event){_vm.showModalAssignDifficultyRequest = false},"refresh":function($event){_vm.myProvider();
      _vm.showModalAssignDifficultyRequest = false;}}}):_vm._e(),(_vm.showModalRequestDetails)?_c('modal-request-details',{attrs:{"request-id":_vm.requestId,"request-code":_vm.requestCode},on:{"hidden":function($event){_vm.showModalRequestDetails = false},"refreshPGRequest":function($event){return _vm.myProvider()}}}):_vm._e(),(_vm.showModalBinnacleRequest)?_c('modal-binnacle-request',{on:{"close":function($event){_vm.showModalBinnacleRequest = false},"hidden":function($event){_vm.showModalBinnacleRequest = false}}}):_vm._e(),(_vm.showModalDevelopmentTeam)?_c('modal-development-team',{attrs:{"show-type":2,"request-id":_vm.requestId},on:{"close":function($event){_vm.showModalDevelopmentTeam = false},"hidden":function($event){_vm.showModalDevelopmentTeam = false}}}):_vm._e(),(_vm.showModalAllProjects)?_c('modal-all-projects',{on:{"hidden":function($event){_vm.showModalAllProjects = false}}}):_vm._e(),(_vm.showModalDueDate)?_c('modal-assign-due-date',{attrs:{"request-id":_vm.requestId,"request-code":_vm.requestCode},on:{"hidden":function($event){_vm.showModalDueDate = false},"close":function($event){_vm.showModalDueDate = false},"updateDueDates":function($event){_vm.myProvider();
      _vm.showModalDueDate = false;}}}):_vm._e(),(_vm.showModalAddLinks)?_c('modal-add-links',{attrs:{"request-id":_vm.requestId,"request-code":_vm.requestCode},on:{"hidden":function($event){_vm.showModalAddLinks = false},"close":function($event){_vm.showModalAddLinks = false}}}):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }